import { IS } from "../IS";
import { ExtendedDate } from "../Extensions/ExtendedDate";
import { ArrayUtils } from "../ArrayUtils";
import { E_WeekDay } from "../../models/constants/JiffyContants";

const ensureDate = value => IS.date(value) ? new Date(value) : value;

export class DateUtils {
	/**
	 * Set start of day
	 * ---
	 * Sets 00:00:00.000 to the provided date **(Reference only!)**
	 * @param {Date} date
	 */
	static setStartOfDay(date = new Date()) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date;
	}

	/**
	 * Get start of day
	 * ---
	 * Returns a date with a time set to the very beginning of the day
	 * @param {Date|string} date Date object or date string
	 * @return {ExtendedDate}
	 */
	static getStartOfDay(date) {
		return new ExtendedDate(date).setStartOfDay();
	}

	/**
	 * Set end of day
	 * ---
	 * Sets 23:59:59.999 to the provided date **(Reference only!)**
	 * @param {Date} date
	 */
	static setEndOfDay(date = new Date()) {
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);
		date.setMilliseconds(999);
		return date;
	}

	/**
	 * Get end of day
	 * ---
	 * Returns a date with a time set one millisecond before the end of the day
	 * @param {Date|string} date Date object or date string
	 * @return {ExtendedDate}
	 */
	static getEndOfDay(date) {
		return new ExtendedDate(date).setEndOfDay();
	}

	/**
	 * Set end of month
	 * ---
	 * Sets the last day of the month, and if **includeTime** is true then also the last millisecond of the date
	 * @param {Date} date
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 */
	static setEndOfMonth(date, includeTime = false) {
		date.setMonth(date.getMonth() + 1);
		date.setDate(0);

		includeTime && DateUtils.setEndOfDay(date);
		return date;
	}

	/**
	 * Get end of month
	 * ---
	 * Returns a date with end of month date & time
	 * @param {Date|string} date Date object or date string
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 */
	static getEndOfMonth(date, includeTime = false) {
		return new ExtendedDate(date).setEndOfMonth(includeTime);
	}

	/**
	 * Set start of month
	 * ---
	 * Sets the last day of the month, and if **includeTime** is true then also the last millisecond of the date
	 * @param {Date} date
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 */
	static setStartOfMonth(date, includeTime = false) {
		date.setDate(1);

		includeTime && DateUtils.setStartOfDay(date);
		return date;
	}

	/**
	 * Get start of month
	 * ---
	 * Returns a date with start of month date & time
	 * @param {Date|string} date Date object or date string
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 */
	static getStartOfMonth(date, includeTime = false) {
		return new ExtendedDate(date).setStartOfMonth(includeTime);
	}

	static getMonthLength(date) {
		date = ensureDate(date);

		return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
	}

	static getWeekDays(firstDay = "SUNDAY") {
		if(!E_WeekDay[firstDay]) {
			throw Error("Unknown firstDay: " + firstDay);
		}

		let days = Object.values(E_WeekDay);
		return ArrayUtils.shift(days, 0 - days.indexOf(firstDay));
	}

	static getISO8601String(date) {
		// extract GTM timezone from date.toString()
		let gtm = /(?:GMT)([-+]\d*)/gm.exec(date.toString())[1];
		gtm = [...gtm];
		// insert ':' in time -0500 -> -05:00
		gtm.splice(3, 0, ':');
		gtm = gtm.join('');
		// replace Z (UTC) to the respective TimeZone
		return date.toISOString().replace('Z', gtm);
	}

	/**
	 *
	 * @param date1
	 * @param date2
	 * @param {"year"|"month"|"date"|"hour"|"minute"|"second"|"millisecond"} depth
	 */
	static match(date1, date2, depth = "millisecond") {
		if(!IS.valid(date1) || !IS.valid(date2)) return false;

		date1 = new Date(date1);
		date2 = new Date(date2);

		if(!IS.validDateObject(date1) || !IS.validDateObject(date2)) return false;

		if(depth === "millisecond") {
			return date1.getTime() == date2.getTime();
		}

		let depthOptions = ["year", "month", "date", "hour", "minute", "second"];
		let validDepthOptions = depthOptions.slice(0, depthOptions.indexOf(depth) + 1);

		if(validDepthOptions.includes("year") && date1.getFullYear() != date2.getFullYear()) return false;
		if(validDepthOptions.includes("month") && date1.getMonth() != date2.getMonth()) return false;
		if(validDepthOptions.includes("date") && date1.getDate() != date2.getDate()) return false;
		if(validDepthOptions.includes("hour") && date1.getHours() != date2.getHours()) return false;
		if(validDepthOptions.includes("minute") && date1.getMinutes() != date2.getMinutes()) return false;
		if(validDepthOptions.includes("second") && date1.getSeconds() != date2.getSeconds()) return false;

		return true;
	}
}
