import { Component, Fragment } from "preact";
import PropTypes from "prop-types";
import { combineClasses, get, PointerInputManager } from "@green24/npm-javascript-utils";
import Header from "../../components/shared/Header";
import ButtonsConstructor from "../../components/shared/input/Button/ButtonsConstructor";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import { apiConnector } from "../../services/api-connector";
import withProductGroups from "../../components/other/product/withProductGroups";
import { route, Router } from "preact-router";
import ButtonComponent from "../../components/shared/input/Button/ButtonComponent";
import ProductSelector from "../ProductSelector/ProductSelector";
import { Interpret, WidestContent } from "../../components/components";

class ProductGroupSelector extends Component {
	constructor(props) {
		super();

		this.state = {
			selectedGroup: get(props, "groups.0"),
		};

		this._touchActive = false;
		this._pointerInputManager = new PointerInputManager();
		this._pointerInputManager.onInputModeChanged(({newMode}) => {
			this._touchActive = newMode == PointerInputManager.enum.mode.TOUCH;
		});
	}

	componentWillUnmount() {
		this._pointerInputManager.destroy();
	}

	_renderProductGroup(group, isSelected) {
		return (
			<a
				href={APP_ROUTE.PRODUCT_GROUP(group.id)}
				className={combineClasses(
					"group",
					isSelected && "visible"
				)}
			>
				<div
					className={"background"}
					style={group.links.image &&{
						backgroundImage: `url(${apiConnector.api + group.links.image})`,
					}}
				/>

				<div className={"group-text-container"}>
					<h1 className={combineClasses(group.description && "with-description")}>{group.title}</h1>
					{
						group.description &&
						<pre>{group.description}</pre>
					}

					<div className={"action-buttons"}>
						<ButtonComponent text={"products.show"} variation={"outline"}/>
					</div>
				</div>
			</a>
		);
	}

	_renderGroupSelector() {
		const {className, style, groups} = this.props;
		const {selectedGroup} = this.state;

		return (
			<section className={combineClasses("product-group-selector", className)} style={style}>
				<Header/>

				<div className={"content"}>
					<div className={"max-width"}>
						<div className={"relative-container"}>
							{
								groups.map(group => {
									const isSelected = selectedGroup.id == group.id;

									return (
										<Fragment key={group.id}>
											{this._renderProductGroup(group, isSelected)}
										</Fragment>
									);
								})
							}
						</div>

						<nav className={"lineup"}>
							<ButtonsConstructor
								buttons={groups.map(group => {
									const isActive = group.id == selectedGroup.id;

									return {
										text: (
											<WidestContent>
												<span visible={isActive}><Interpret id={"products.show"}/></span>
												<span visible={!isActive}>{group.title}</span>
											</WidestContent>
										),
										textTranslated: !isActive,
										active: isActive,
										accent: isActive ? "primary" : "secondary",
										buttonProps: {
											onMouseEnter: () => this.setState({selectedGroup: group}),
										},
										action: () => (!this._touchActive || isActive) && route(APP_ROUTE.PRODUCT_GROUP(group.id)),
									}
								})}
								onButton={button => <div className={"group-option"}>{button}</div>}
							/>
						</nav>
					</div>
				</div>
			</section>
		)
	}

	render({groups}, {selectedGroup}) {
		return (
			<Router>
				<ProductSelector groups={groups} path={APP_ROUTE.PRODUCT_GROUP()}/>
				<Fragment default>{this._renderGroupSelector()}</Fragment>
			</Router>
		)
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//withProductGroups
			groups: PropTypes.array,
		}
	}
}

export default withProductGroups(ProductGroupSelector);
