/**
 * Currency enum
 * ---
 * Only the default currency is defined here, the rest should fetched from the backend
 * @enum string
 * @readonly
 */
export const E_Currency = {
	CZK: "CZK",
};

export const E_CostUnit = {
	CURRENCY: "CURRENCY",
	PERCENTAGE: "PERCENTAGE",
};
