import { Component as PComponent } from "preact";
import { ProjectContext } from "../ProjectLoader";

/**
 * A public higher-order component to access the imperative API
 */
export const withProject = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<ProjectContext.Consumer>
					{(projectContext) => (
						<Component
							projectContext={projectContext}
							project={projectContext.project}
							{...props}
						/>
					)}
				</ProjectContext.Consumer>
			);
		}

		static get displayName() {
			return "withProject(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withProject;
