import { AppUtils } from "./AppUtils";
import { E_Project } from "../models/constants/Enums_Project";
import { SortRules } from "@green24/npm-javascript-utils";

export class ProjectUtils {
	static get projectKey() {
		let forceTheme = AppUtils.localStorage.theme;
		if(forceTheme) return forceTheme;

		if(AppUtils.hasWindow) {
			let [subdomain] = window.location.hostname.split('.');

			switch (subdomain) {
				case "nikola":
					return E_Project.NIKOLA;
				default:
					return E_Project.NIKOLA;//TBD: Probably something else like G24
			}
		}
	}

	static getProjectIDFromKey(projectKey) {
		switch (projectKey) {
			case E_Project.NIKOLA:
				return 1;
			default:
				return 1;//TBD: Probably ID of G24 or similar project
		}
	}

	static sortByOrderPosition(arr) {
		arr.sort((a, b) => SortRules.propertyAsc(a, b, "orderPosition"));
		return arr;
	}

	static sortProductOptions(product) {
		if(!product) return;

		this.sortByOrderPosition(product.categories);

		product.categories.forEach(category => {
			this.sortByOrderPosition(category.groups);

			category.groups.forEach(group => {
				this.sortByOrderPosition(group.options);
			});
		});

		return product;
	}
}
