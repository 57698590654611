import { ObjectUtils } from "../ObjectUtils";
import { M_LanguageAllISOCodes } from "../../models/L10N/Models_Language";
import { M_L10N_DefaultCustomization } from "../../models/L10N/Models_L10N";
import { ArrayUtils } from "../ArrayUtils";
import { LocalizedRegion } from "./LocalizedRegion";
import { LocalizedTerm } from "./LocalizedTerm";
import { IS } from "../IS";

/**
 * @typedef TermsData
 * @type {Object}
 */
/**
 * @typedef LocaleData
 * @type {{
 *  regions: Object,
 *  alternativeLanguages: [E_Language|M_LanguageISOCodesWithRegion]
 * }}
 */
/**
 * Region code
 * ---
 * An ISO 639-1 language code with regional suffix e.g. en-US
 * @typedef RegionCode
 * @type {string}
 */

export class Localizer {
	/**
	 * @param {LocaleData} localeData
	 * @param {Internationalizer} internationalizer Upwards reference to the parent Internationalizer (if there is any)
	 * @param {M_L10N_DefaultCustomization} customization
	 */
	constructor(localeData, internationalizer = undefined, customization) {
		let {regions, alternativeLanguages, enumerable} = localeData;

		this.setIsEnumerable(IS.boolean(enumerable) ? enumerable : true);
		this.customization = customization;

		this._regions = {};
		this._internationalizer = internationalizer;
		this._alternativeLanguages = alternativeLanguages;

		let possibleRootRegionCode = ObjectUtils.findKey(regions, key => !key.includes('-'));
		this._lastRegionCode = possibleRootRegionCode ? possibleRootRegionCode.toLowerCase() : undefined;

		this.addRegions(regions);
	}

	static get model() {
		return {
			defaultCustomization: M_L10N_DefaultCustomization,
		}
	}

	static get enum() {
		return {
			ISOCode: M_LanguageAllISOCodes,
		}
	}

	get customization() {
		return this._customization;
	}

	set customization(value) {
		this._customization = {
			...Localizer.model.defaultCustomization,
			localizerClass: Localizer,
			termClass: LocalizedTerm,
			...value,
		};
	}

	get internationalizer() {
		return this._internationalizer;
	}

	get regions() {
		return this._regions || {};
	}

	get alternativeLanguages() {
		return this._alternativeLanguages || [];
	}

	get enumerable() {
		return this._isEnumerable;
	}

	setIsEnumerable(isEnumerable) {
		this._isEnumerable = isEnumerable;
	}

	/**
	 * Add regions
	 * ---
	 * @param {Object<RegionCode, TermsData>} regionsData
	 * @see Localizer.addRegion
	 */
	addRegions(regionsData) {
		ObjectUtils.forEach(regionsData, (regionKey, data) => {
			this.addRegion(regionKey, data);
		});
	}

	/**
	 * Add region
	 * ---
	 * @param {RegionCode} regionCode
	 * @param {TermsData} data
	 */
	addRegion(regionCode, data) {
		regionCode = `${regionCode}`.toLowerCase();

		this._regions[regionCode] = new LocalizedRegion({
			regionCode,
			terms: data,
		});
	}

	/**
	 * Set internationalizer
	 * ---
	 * @param {Internationalizer} internationalizer
	 */
	setInternationalizer(internationalizer) {
		this._internationalizer = internationalizer;
	}

	/**
	 * Find term
	 * ---
	 * @param {RegionCode} startRegionCode
	 * @param {string} termKey
	 * @param {*} fallback
	 * @param {boolean} searchInAlternatives
	 * @param {boolean} searchInFallback
	 * @return {LocalizedTerm}
	 */
	findTerm(startRegionCode, termKey, fallback = undefined, searchInAlternatives = true, searchInFallback = true) {
		this._lastRegionCode = startRegionCode;
		let region = this._findRegionForTerm(startRegionCode, termKey);
		let term = this.getTermFromRegion(termKey, region, fallback);

		if(term.valid) {
			return term;
		}

		if(this.internationalizer) {
			if(searchInAlternatives && this.alternativeLanguages.length) {
				/** @type {LocalizedTerm} */
				let termFromAlt = ArrayUtils.findAndRetrieve(this.alternativeLanguages, language => {
					let languageTerm = this.internationalizer.findTermInLanguage(language, termKey, fallback, searchInAlternatives, searchInFallback);

					if(languageTerm.valid) {
						return languageTerm;
					}
				});

				if(termFromAlt && termFromAlt.valid) {
					return termFromAlt;
				}
			}

			if(searchInFallback) {
				let termFromFallback = this.internationalizer.findTermInFallbackLanguage(termKey, fallback, searchInAlternatives);

				if(termFromFallback.valid) {
					return termFromFallback;
				}
			}
		}

		return term;
	}

	/**
	 * Find term within localizer
	 * ---
	 * Searches for a term only within specific Localizer instance and nowhere else.
	 * **This method should not be used from anywhere else but LocalizedTerm while searching for an additional translation**
	 * @param {RegionCode} [startRegionCode=Localizer._lastRegionCode]
	 * @param {string} termKey
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	findTermWithinLocalizer(startRegionCode = this._lastRegionCode, termKey, fallback) {
		return this.findTerm(startRegionCode, termKey, fallback, false, false);
	}

	/**
	 * Find term in region by code
	 * ---
	 * @param {string} termKey
	 * @param {string} regionISOCode
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	findTermInRegionByCode(termKey, regionISOCode, fallback) {
		let region = this.regions[regionISOCode];
		return this.getTermFromRegion(termKey, region, fallback);
	}

	/**
	 * Get term from region
	 * ---
	 * @param {string} termKey
	 * @param {LocalizedRegion} region
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	getTermFromRegion(termKey, region, fallback) {
		if(region) {
			return new this._customization.termClass({
				term: region.getTerm(termKey),
				termKey,
				fallback,
			}, this, this._customization);
		}

		return this._getEmptyTerm(termKey, fallback);
	}

	/**
	 * Has relevant region
	 * ---
	 * Returns if there is any region relevant to the path.
	 * This differs from the hasRegion() by the fact that it's checking even related regions through the regional ISO code.
	 * E.g. when the ISO code is "en-US" and there is no "en-US" only "en", it will return **true** since "en" region is relevant to the "en-US"
	 * @param {string} regionISOCode
	 * @return {boolean}
	 * @see Localizer.hasRegion
	 */
	hasRelevantRegion(regionISOCode) {
		let codeParts = regionISOCode.split('-');

		return codeParts.some((part, i) => {
			let code = codeParts.slice(0, codeParts.length - i).join('-');

			return this.hasRegion(code);
		});
	}

	/**
	 * Has region
	 * ---
	 * Return if the specific region is defined
	 * @param {string} regionISOCode
	 * @return {boolean}
	 */
	hasRegion(regionISOCode) {
		return !!this.getRegion(regionISOCode);
	}

	/**
	 * Get region
	 * ---
	 * @param regionISOCode
	 * @return {*}
	 */
	getRegion(regionISOCode) {
		return this.regions[regionISOCode.toLowerCase()];
	}

	/**
	 * Find term from internationalizer
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	findTermFromInternationalizer(termKey, fallback) {
		if(this.internationalizer) {
			return this.internationalizer.findTerm(termKey, fallback);
		}

		return this._getEmptyTerm(termKey, fallback);
	}

	handleFindTermFromChildren(termKey, fallback) {
		if(termKey.includes(':')) {
			let [regionCode, newTermKey] = termKey.split(':');

			if(this.hasRelevantRegion(regionCode)) {
				let term = this.findTermWithinLocalizer(regionCode, newTermKey, fallback);
				if(term.valid) return term;
			}
		}
		else {
			let term = this.findTermWithinLocalizer(undefined, termKey, fallback);
			if(term.valid) return term;
		}

		return this.findTermFromInternationalizer(termKey, fallback);
	}

	/**
	 * @private
	 * Find region for term
	 * ---
	 * @param {RegionCode} startRegionCode
	 * @param {string} termKey
	 * @return {LocalizedRegion}
	 */
	_findRegionForTerm(startRegionCode, termKey) {
		let regionParts = startRegionCode.split('-');

		//TBD: Maybe use the order property to prioritize other regions even when not specified by the startRegionCode?
		// e.g. ["en-US-test", undefined] where en-US-test will take a priority and undefined is a native search (e.g. ["en-US", "en"])

		return ArrayUtils.findAndRetrieve(regionParts, (_, i) => {
			let targetRegionCode = regionParts.slice(0, regionParts.length - i).join('-');

			if(this.hasRegion(targetRegionCode)) {
				let region = this.getRegion(targetRegionCode);
				if(region.hasTerm(termKey)) {
					return region;
				}
			}
		});
	}

	/**
	 * @private
	 * Get empty term
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	_getEmptyTerm(termKey, fallback) {
		return new this.customization.termClass({
			termKey,
			fallback,
		}, this, this.customization);
	}
}
