import { ArrayUtils } from "./ArrayUtils";
import { uID } from "../functions/generic";

/**
 * @typedef RemoveListener
 * @type {Function}
 */

/**
 * @typedef EventComboType
 * @type {"key"|"touch"|"mouse"}
 */

export class EventListeners {
	constructor() {
		this._listeners = [];
	}

	/**
	 * Add
	 * ---
	 * @param {string} eventName
	 * @param {function(Event)} callback
	 * @param {HTMLElement} target
	 * @param {boolean|AddEventListenerOptions} options
	 * @return {data.remove}
	 */
	add(target, eventName, callback, options) {
		if(target) {
			const id = uID();
			const removeListener = EventListeners.createListener(target, eventName, callback, options);
			const remove = () => {
				removeListener();
				ArrayUtils.removeID(this._listeners, id, false);
			};

			this._listeners.push({
				id,
				eventName,
				options,
				remove,
			});

			return remove;
		}

		console.error("Target is undefined for the event:", eventName, options);
		return () => null;
	}

	/**
	 * Add toggle
	 * ---
	 * Registers a toggle combo of start event and end event (e.g. keydown and keyup)
	 * @param {HTMLElement} target
	 * @param {EventComboType} eventType
	 * @param {function(Event, isStart)} callback
	 * @param {boolean|AddEventListenerOptions} startOptions
	 * @param {boolean|AddEventListenerOptions} endOptions
	 * @return {RemoveListener}
	 */
	addToggle(target, eventType, callback, startOptions, endOptions) {
		const [startEventName, endEventName] = EventListeners.getEventCombinationFromType(eventType);

		const startEventRemover = this.add(target, startEventName, e => callback(e, true), startOptions);
		const endEventRemover = this.add(target, endEventName, e => callback(e, false), endOptions);

		return () => {
			startEventRemover();
			endEventRemover();
		}
	}

	/**
	 * Remove for target
	 * ---
	 * @param {HTMLElement} target
	 */
	removeForTarget(target) {
		[...this._listeners].forEach(listener => {
			if(listener.target == target) {
				listener.remove();
			}
		});
	}

	/**
	 * Remove for event
	 * ---
	 * @param {string} eventName
	 */
	removeForEvent(eventName) {
		[...this._listeners].forEach(listener => {
			if(listener.eventName == eventName) {
				listener.remove();
			}
		});
	}

	/**
	 * Clear
	 * ---
	 * Removes all event listeners
	 */
	clear() {
		[...this._listeners].forEach(listener => listener.remove());
	}

	/**
	 * Create listener
	 * ---
	 * Creates a single independent listener
	 * @param {Element} target Event target
	 * @param {string} eventName Event name
	 * @param {function(Event)} callback Event callback
	 * @param {EventListenerOptions} options Event listener options
	 * @return {RemoveListener} Function to remove the listener
	 */
	static createListener(target, eventName, callback, options) {
		const listener = e => callback(e);

		target.addEventListener(eventName, listener, options);

		return () => target.removeEventListener(eventName, listener, options);
	}

	/**
	 * Get event combination from type
	 * ---
	 * @param {EventComboType} type
	 * @return {[startEvent: string, endEvent: string]}
	 */
	static getEventCombinationFromType(type) {
		switch (type) {
			case "key":
				return ["keyup", "keydown"];
			case "touch":
				return ["touchstart", "touchend"];
			case "mouse":
				return ["mousedown", "mouseup"];
		}
	}
}
