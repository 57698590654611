import { ProductConfiguratorGenericServices } from "./api-connector";
import { projectServices } from "./project-services";
import { ProductGroupUtils } from "../utils/ProductUtils";

class ProductGroupServices extends ProductConfiguratorGenericServices {
	getList(queryData, signal, processor = data => data, additionalConfiguration) {
		return super.getList(queryData, signal, data => {
			ProductGroupUtils.sortProductGroups(data);
			return processor(data);
		}, additionalConfiguration);
	}
}

export const productGroupServices = (projectID) => new ProductGroupServices(`${projectServices.url}/${projectID}/group`);
