import { createContext } from "preact";
import "./styles/importer.scss";
import ThemeProvider from "./components/shared/Theme/ThemeProvider";
import LocalizationProvider from "./components/shared/L10N/LocalizationProvider";
import App from "./components/App";
import { ProjectUtils } from "./utils/ProjectUtils";
import ProjectLoader from "./components/other/project/ProjectLoader";
import ProductGroupsLoader from "./components/other/project/ProductGroupsLoader";
import Loading from "./components/shared/Loading";
import SiteSettings from "./components/other/settings/SiteSettings";
import SiteSettingsContextProvider from "./components/other/settings/SiteSettingsContextProvider";
import { reinitializeContexts } from "@green24/npm-preact-utils/src/contextSetters";
import { M_Icons } from "./models/Models_Icons";
import { IconsProvider } from "./components/components";

//Fix contexts
reinitializeContexts(() => createContext());

export default () => {
	return (
		<IconsProvider icons={M_Icons}>
			<LocalizationProvider>
				<SiteSettingsContextProvider>
					<div id={"app"}>
						<SiteSettings/>

						<ProjectLoader showLoading={false} projectID={ProjectUtils.getProjectIDFromKey(ProjectUtils.projectKey)}>
							{({states, project}) => {
								return (
									<ThemeProvider theme={ProjectUtils.projectKey}>
										{
											states.loading &&
											<Loading className={"with-background full-page"} title={"loading"}/>
										}
										{
											project &&
											<ProductGroupsLoader>
												<App/>
											</ProductGroupsLoader>
										}
									</ThemeProvider>
								);
							}}
						</ProjectLoader>

						<div id={"modalRoot"}/>
						<div id={"dropdownRoot"}/>
					</div>
				</SiteSettingsContextProvider>
			</LocalizationProvider>
		</IconsProvider>
	)
};
