import { ProductConfiguratorBasicServices } from "./api-connector";

class CartServices extends ProductConfiguratorBasicServices {
	sendOrder(data) {
		return this.sendRequest({
			url: `${this.url}/`,
			method: "POST",
			body: JSON.stringify(data),
		});
	}
}

export const cartServices = projectID => new CartServices(`/api/project/${projectID}/cart`);
