/**
 * Advanced Promise state
 * ---
 * @enum string
 * @readonly
 */
export const E_AdvancedPromise_State = {
	PENDING: "PENDING",
	FULFILLED: "FULFILLED",
	REJECTED: "REJECTED",
};
