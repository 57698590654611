export class ErrorUtils {
	static getErrorFromResponse(response, errorOverride = {}, dataOverride) {
		if(!response) return null;

		const __getData = (res) => {
			switch (res.statusCode) {
				case 400: //Bad Request
					return {
						icon: "construction",
						title: res.message,
						titleTranslated: true,
						retry: false,
					};
				case 404: //Not found
					return {
						icon: "telescope",
						title: "error.notFound",
						retry: false,
					};
				case 408: //Timed out
					return {
						icon: "stopwatch",
						title: "error.network.timeOut",
						retry: true,
					};
				case 500: //Server error
				case 501:
				case 502:
				case 503:
					return {
						icon: "server",
						title: "error.network.server",
						retry: false,
					};
				default: //Other
					return {
						icon: "exclamation-triangle",
						title: "error.unexpected",
						retry: true,
					};
			}
		}

		return {
			...__getData(response),
			...errorOverride[response.statusCode],
			...dataOverride,
		}
	}
}
