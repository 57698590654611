import { Component as PComponent } from "preact";
import { FormContext } from "../Form";

/**
 * A public higher-order component to access the imperative API
 */
export const withFormContext = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<FormContext.Consumer>
					{(formContext) => (
						<Component
							formContext={formContext}
							{...props}
						/>
					)}
				</FormContext.Consumer>
			);
		}

		static get displayName() {
			return "withFormContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
			}
		}

		static get defaultProps() {
			return {
			}
		}
	}

	return HOC;
};

export default withFormContext;
