import { Component } from 'preact';
import PropTypes from "prop-types";
import { createContext } from "preact/compat";

export let IconsContext = createContext();
export const setIconsContext = context => IconsContext = context;

class IconsProvider extends Component {
	render({children, icons, onFindIcon}, {}) {
		return (
			<IconsContext.Provider value={{
				icons,
				findIcon: icon => this._findIcon(icon),
			}}>
				{children}
			</IconsContext.Provider>
		);
	}

	_findIcon(icon) {
		const {icons, onFindIcon} = this.props;

		return onFindIcon(icon, icons);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			icons: PropTypes.object,
			onFindIcon: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			onFindIcon: (icon, icons) => icons[icon],
		}
	}
}

export default IconsProvider;
