import { StoreHelpers } from "./StoreHelpers";
import { E_GreenSocket_MessageTypes } from "../models/constants/GreenSocketConstants";
import { M_GreenSocketMessage, M_GreenSocketOptions } from "../models/Models_GreenSocket";
import { ArrayUtils } from "./ArrayUtils";
import { Logger } from "./Logger";

const logger = new Logger("green-socket");

export class GreenSocket {
	constructor(url, options) {
		this.socket = null;

		this._events = {
			open: [],
			close: [],
			message: [],
		};
		this._messageQueue = [];
		this._subscribedTopics = [];

		this._isConnected = false;
		this._disconnectedTimeout = null;

		this._disconnectReason = undefined;
		this._manualDisconnect = false;

		this._onOpenListener = null;
		this._onCloseListener = null;
		this._onMessageListener = null;

		this.url = url;
		this.options = StoreHelpers.deepMerge(
			StoreHelpers.deepClone(M_GreenSocketOptions),
			options,
		);
	}

	/**
	 * Connect
	 * ---
	 * Connect to the websocket and register listeners
	 * @param {String} loginToken
	 * @param {function(socket: WebSocket)} onConnect
	 * @param {function(closeEvent: Event)} onDisconnect
	 * @returns {null}
	 */
	connect(loginToken = null, onConnect = () => null, onDisconnect = () => null) {
		//Define onConnect handle
		const __onConnect = () => {
			onConnect(this.socket);

			this.resubscribeTopics();
			this.sendMessageQueue();
		};

		//Clear previous listeners
		this._onOpenListener && this._onOpenListener();
		this._onCloseListener && this._onCloseListener();
		this._onMessageListener && this._onMessageListener();

		this._disconnectReason = null;

		logger.isTypeAllowed("info") && console.log("Connecting...");

		//Create socket
		this.socket = new WebSocket(this.url);

		//Register socket native events
		this.socket.addEventListener("open", e => {
			this._events.open.forEach(eventData => eventData.callback(e));
		});
		this.socket.addEventListener("close", e => {
			this._events.close.forEach(eventData => eventData.callback(e));
		});
		this.socket.addEventListener("message", e => {
			this._events.message.forEach(eventData => eventData.callback(e));
		});

		//Socket open
		this._onOpenListener = this.on("open", e => {
			logger.isTypeAllowed("info") && console.log("Connected");

			this._isConnected = true;
			clearTimeout(this._disconnectedTimeout);

			//If loginToken is valid, try to log in.
			if(loginToken) {
				logger.isTypeAllowed("info") && console.log("Logging in...");
				this.sendMessage(E_GreenSocket_MessageTypes.LOGIN, {
					"x-auth-token": loginToken,
				});
			}
			else {
				__onConnect();
			}
		}, "on socket open");

		//Socket close
		this._onCloseListener = this.on("close", e => {
			logger.isTypeAllowed("info") && console.log("Disconnected");

			//Call on disconnect if there was a connection before
			this._isConnected && onDisconnect((this._manualDisconnect && this._disconnectReason) || e.reason, e);
			this._isConnected = false;

			//Attempt to reconnect
			if(this.options.disconnectedRefreshTime > 0 && !this._manualDisconnect) {
				logger.isTypeAllowed("info") && console.log(`Attempting to reconnect in ${this.options.disconnectedRefreshTime}ms`);

				this._disconnectedTimeout = setTimeout(() => {
					this.connect(loginToken, onConnect, onDisconnect);
				}, this.options.disconnectedRefreshTime);
			}
			this._disconnectReason = undefined;
			this._manualDisconnect = false;
		}, "on socket close");

		//Socket message
		this._onMessageListener = this.on("message", res => {
			logger.isTypeAllowed("info") && console.log('Message from server ', res);

			//If the message is of type LOGIN
			if(loginToken && res.type === E_GreenSocket_MessageTypes.LOGIN) {
				logger.isTypeAllowed("info") && console.log('Logged in');
				__onConnect();
			}

			if(res.type === E_GreenSocket_MessageTypes.UNSUBSCRIBE) {
				this._unsubscribeTopic(res.destination, res);
			}
		}, "on socket message");

		return this.socket;
	}

	/**
	 * Disconnect
	 * ---
	 * @param {*} reason Any reason of disconnection. **Warning!** Do **NOT** use any values that are considered as **false** values when compared with **||(or)**
	 */
	disconnect(reason = null) {
		if(this.socket) {
			this._manualDisconnect = true;
			this._disconnectReason = reason;
			this._subscribedTopics = [];
			this.clearMessageQueue();

			this.socket.close();
			delete this.socket;
		}
	}

	/**
	 * On
	 * ---
	 * On event listener. Adds an event listener for specified eventName and returns event remove function
	 * @param {"open"|"close"|"message"} eventName
	 * @param {function(Event|Object)} callback
	 * @param {String} name Listener specific name. Any name that can be distinguished for debugging
	 * @returns {Function} Remove listener
	 */
	on(eventName, callback, name = null) {
		let fnc = callback;
		if(eventName === "message") {
			fnc = (e) => callback(JSON.parse(e.data));
		}

		let data = {
			eventName,
			callback: fnc,
		};
		let index = this._events[eventName].push(data) - 1;
		data.remove = () => ArrayUtils.removeIndex(this._events[eventName], index);
		name && (data.name = name);

		return data.remove;
	}

	/**
	 * On destination
	 * ---
	 * @param {String} destination Message destination
	 * @param {function(Event|Object)} callback
	 * @param {String} name Listener specific name. Any name that can be distinguished for debugging
	 * @returns {*}
	 */
	onDestination(destination, callback, name) {
		return this.on("message", (message) => {
			if(message.destination == destination) {
				callback(message);
			}
		}, name);
	}

	/**
	 * Subscribe topic
	 * ---
	 * @param {String} topic Topic to subscribe to
	 * @param {function(reason)} unsubscribeCallback Callback which is called
	 * @param {Boolean} resubscribe Will resubscribe topic after the connection loss
	 */
	subscribeTopic(topic, unsubscribeCallback = () => null, resubscribe = true) {
		if(topic) {
			if(!ArrayUtils.contains(this._subscribedTopics, topic, "topic") || resubscribe) {
				if(this._isConnected) {
					this._subscribeTopic(topic);
				}

				if(resubscribe) {
					this._subscribedTopics.push({
						topic,
						unsubscribeCallback,
					});
				}
			}
		}
		else {
			console.error("Invalid topic: " + topic);
		}
	}

	/**
	 * Unsubscribe topic
	 * ---
	 * @param {String} topic Topic to unsubscribe from
	 * @param reason Reason of unsubscription.
	 */
	unsubscribeTopic(topic, reason = null) {
		if(this._subscribedTopics.some(topicData => topicData.topic === topic)) {
			logger.isTypeAllowed("info") && console.log(`Unsubscribing topic "${topic}"...`);
			this.sendMessage(E_GreenSocket_MessageTypes.UNSUBSCRIBE, undefined, topic, undefined);
			this._unsubscribeTopic(topic, reason);
		}
		else {
			logger.isTypeAllowed("info") && console.log(`No active subscription found for "${topic}" topic`);
		}
	}

	_unsubscribeTopic(topic, reason = null) {
		let index = this._subscribedTopics.findIndex(topicData => topicData.topic === topic);
		if(index > -1) {
			logger.isTypeAllowed("info") && console.log(`Removing topic "${topic}" from stored topics`);
			this._subscribedTopics[index].unsubscribeCallback(reason);
			ArrayUtils.removeIndex(this._subscribedTopics, index);
		}
	}

	/**
	 * Resubscribe topics
	 * ---
	 * Resubscribes all topics that were called with **resubscribe = true** argument.
	 *
	 * Mostly for internal use to be called after the connection loss.
	 */
	resubscribeTopics() {
		logger.isTypeAllowed("info") && console.log("Resubscribing all topics");
		this._subscribedTopics.forEach(topicData => {
			this._subscribeTopic(topicData.topic);
		});
	}

	/**
	 * Unsubscribe all topics
	 * ---
	 */
	unsubscribeAllTopics() {
		logger.isTypeAllowed("info") && console.log("Unsubscribing all topics...");
		this._subscribedTopics.forEach(topic => {
			this.unsubscribeTopic(topic);
		});
		this._subscribedTopics = [];
	}

	/**
	 * Send message
	 * ---
	 * Sends socket message
	 * @param {E_GreenSocket_MessageTypes} type Message type
	 * @param {Object} data Message data
	 * @param {String} destination Topic destination
	 * @param {*} id Message ID
	 * @param {Boolean} pushToQueueOnFail
	 */
	sendMessage(type, data, destination = undefined, id = undefined, pushToQueueOnFail = true) {
		let message = {
			...M_GreenSocketMessage,
			type: type,
			destination: destination,
			id: id,
			data: data,
		};

		if(this.socket && this._isConnected) {
			logger.isTypeAllowed("info") && console.log("Sending...", message);
			this.socket.send(JSON.stringify(message));
		}
		else {
			if(pushToQueueOnFail) {
				this._messageQueue.push(message);
				Logger.isScopeAllowed(LOGGER_SCOPE, "warn") && console.warn("Socket not connected, message pushed to queue");
			}
			else {
				console.error("Message not sent. Socket is not connected and pushToQueueOnFail = FALSE");
			}
		}
	}

	/**
	 * Send message queue
	 * ---
	 */
	sendMessageQueue() {
		logger.isTypeAllowed("info") && console.log("Sending message queue");
		this._messageQueue.forEach(message => {
			this.sendMessage(message.type, message.data, message.destination, message.id, false);
		});
		this.clearMessageQueue();
	}

	/**
	 * Clear message queue
	 * ---
	 */
	clearMessageQueue() {
		logger.isTypeAllowed("info") && console.log("Clearing message queue");
		this._messageQueue = [];
	}

	/**
	 * Is connected
	 * ---
	 * @returns {boolean} Is connected
	 */
	get isConnected() {
		return this._isConnected;
	}

	_subscribeTopic(topic) {
		logger.isTypeAllowed("info") && console.log("Subscribing topic: " + topic);

		this.sendMessage(E_GreenSocket_MessageTypes.SUBSCRIBE, undefined, topic, undefined);
	}
}
