import { E_Language, E_LanguageWithRegion } from "../../models/constants/LanguageConstants";
import { M_LanguageAllISOCodes, M_LanguageISOCodes, M_LanguageISOCodesWithRegion } from "../../models/L10N/Models_Language";
import { ArrayUtils } from "../ArrayUtils";
import { ObjectUtils } from "../ObjectUtils";
import { resolvePolymorphVar } from "../../functions/generic";

export class RegionalLanguage {
	constructor(languageOrCode) {
		if(!!E_LanguageWithRegion[languageOrCode]) {
			//languageOrCode is an enum key

			this._code = RegionalLanguage.getISOCodeWithRegion(languageOrCode);
			this._key = languageOrCode;
			this._language = languageOrCode;
			this._hasRegion = !E_Language[languageOrCode];
		}
		else {
			//languageOrCode is an ISO code

			let ISOCode = this._code = languageOrCode;

			this._key = RegionalLanguage.findRegionalLanguageFromISOCode(ISOCode);
			this._language = RegionalLanguage.findLanguageFromISOCode(ISOCode);
			this._hasRegion = ISOCode.split('-').length > 1;
		}
	}

	get ISOCode() {
		return this._code;
	}

	get languageKey() {
		return this._language;
	}

	get hasRegion() {
		return this._hasRegion;
	}

	get key() {
		return this._key || this.languageKey;
	}

	get codes() {
		let codes = [...M_LanguageISOCodes[this.languageKey]];

		if(M_LanguageAllISOCodes[this.key] && !M_LanguageISOCodes[this.key]) {
			codes[4] = M_LanguageAllISOCodes[this.key];
		}

		return codes;
	}

	get ISOCodes() {
		let [one, T, B, _, region] = this.codes;

		T = T || one;
		B = B || T;
		region = region || one;

		return {
			"639-1": one,
			1: one,
			"639-2/T": T,
			T,
			"639-2/B": B,
			B,
			region,
		}
	}

	get ISO639_1() {
		return this.ISOCodes[1];
	}

	get ISO639_2T() {
		return this.ISOCodes.T;
	}

	get ISO639_2B() {
		return this.ISOCodes.B;
	}

	get ISOCodeWithRegion() {
		return this.ISOCodes.region;
	}

	/**
	 * Is related code
	 * ---
	 * @param {string} regionalCode
	 * @param {boolean} checkOnlyRoot
	 * @return {boolean}
	 */
	isRelatedCode(regionalCode, checkOnlyRoot = false) {
		const codeParts = regionalCode.split('-');
		if(codeParts.length > 1) {
			if(checkOnlyRoot) {
				return RegionalLanguage.isEqualCode(codeParts[0], this.ISO639_1);
			}
			return RegionalLanguage.isEqualCode(regionalCode, this.ISOCodeWithRegion);
		}

		return this.codes.includes(regionalCode);
	}

	/**
	 * Has same root code
	 * ---
	 * @param {string} regionalCode
	 * @return {boolean}
	 */
	hasSameRootCode(regionalCode) {
		return RegionalLanguage.isEqualCode(RegionalLanguage.getRootLanguageCodeFromRegionalCode(regionalCode), this.ISO639_1);
	}

	static getRootLanguageCodeFromRegionalCode(regionalCode) {
		return regionalCode.split('-')[0];
	}

	static getISOCodeWithRegion(languageWithRegionKey, lowercase = false) {
		let ISOCodes = M_LanguageAllISOCodes[languageWithRegionKey];
		let code = resolvePolymorphVar(
			ISOCodes,
			{
				string: s => s,
				array: arr => arr[0],
			},
		);

		if(code) {
			return lowercase ? code.toLowerCase() : code;
		}
	}

	static findLanguageFromISOCode(code) {
		let codeParts = code.split('-');
		if(codeParts.length > 1) {
			code = codeParts[0];
		}

		return Object.keys(M_LanguageISOCodes).find(key => M_LanguageISOCodes[key].includes(code));
	}

	static findRegionalLanguageFromISOCode(code) {
		let codeParts = code.split('-');
		if(codeParts.length > 1) {
			let regionalLanguage = ArrayUtils.findAndRetrieve(codeParts, (_, i) => {
				let targetRegionCode = codeParts.slice(0, codeParts.length - i).join('-');

				return ObjectUtils.findKey(M_LanguageISOCodesWithRegion, (languageRegionKey, regionISOCode) => {
					return this.isEqualCode(regionISOCode, targetRegionCode);
				});
			});

			if(regionalLanguage) return regionalLanguage;
		}

		return this.findLanguageFromISOCode(code);
	}

	static isEqualCode(code1, code2) {
		return code1.toLowerCase() == code2.toLowerCase();
	}

	/**
	 * Has same root language
	 * ---
	 * Returns if both values have the same root language
	 * e.g. en-US and en-GB have English as a root language
	 * @param {string} value1 RegionalCode, a language key or anything that can be processed by the RegionalLanguage.constructor
	 * @param {string} value2 RegionalCode, a language key or anything that can be processed by the RegionalLanguage.constructor
	 * @return {boolean}
	 * @see RegionalLanguage
	 */
	static hasSameRootLanguage(value1, value2) {
		return new RegionalLanguage(value1).languageKey.toLowerCase() == new RegionalLanguage(value2).languageKey.toLowerCase();
	}
}
