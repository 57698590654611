import { Component, createContext } from "preact";
import PropTypes from "prop-types";

export const InputRootContext = createContext();

class InputRootContextProvider extends Component {
	render({children, root}, {}) {
		const contextData = {
			root,
		};

		return (
			<InputRootContext.Provider value={contextData}>
				{children}
			</InputRootContext.Provider>
		);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			root: PropTypes.object,
		}
	}
}

export default InputRootContextProvider;
