import { Component, createContext } from "preact";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";
import { LocalStorageEditor } from "@green24/npm-javascript-utils";
import { AppUtils } from "../../../utils/AppUtils";

export const SiteSettingsContext = createContext();

class SiteSettingsContextProvider extends Component {
	constructor() {
		super();

		const systemDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
		const defaultTheme = systemDarkMode ? "dark" : "light";
		const {currency = "CZK", theme = defaultTheme, saveData = AppUtils.saveData} = LocalStorageEditor.get("siteSettings") || {};

		this.state = {
			currency,
			theme,
			saveData,
		};

		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.getValueWithUpdateListener("state.theme", theme => {
			document.documentElement.setAttribute("theme", theme);
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	render({children}, {currency, theme, saveData}) {
		const contextData = {
			currency,
			setCurrency: currency => this._setCurrency(currency),
			theme,
			setTheme: theme => this._setTheme(theme),
			saveData,
			setSaveData: saveData => this._setSaveData(saveData),
		};

		return (
			<SiteSettingsContext.Provider value={contextData}>
				{children}
			</SiteSettingsContext.Provider>
		);
	}

	_setCurrency(currency) {
		LocalStorageEditor.set("siteSettings.currency", currency);
		this.setState({currency});
	}

	_setTheme(theme) {
		LocalStorageEditor.set("siteSettings.theme", theme);
		this.setState({theme});
	}

	_setSaveData(saveData) {
		LocalStorageEditor.set("siteSettings.saveData", saveData);
		this.setState({saveData});
	}

	static get propTypes() {
		return {

		}
	}
}

export default SiteSettingsContextProvider;
