export class BatchGroup {
	/**
	 * Constructor
	 * ---
	 * @param {*} groupIdentifier
	 * @param {AdvancedPromise} promises
	 */
	constructor(groupIdentifier, ...promises) {
		this.identifier = groupIdentifier;
		this._onResolved = () => null;

		this._promises = [];
		this.add(...promises);
	}

	/**
	 *
	 * @return {AdvancedPromise[]}
	 */
	get promises() {
		return this._promises;
	}

	/**
	 * Add
	 * ---
	 * Adds a promise(s) to the group
	 * @param {AdvancedPromise} promises
	 * @return {*[]}
	 */
	add(...promises) {
		this._promises.push(...promises);

		promises.forEach(promise => {
			promise.then(
				data => this.fulfill(data),
				data => this.reject(data),
				undefined,
				false
			);
		});

		return promises;
	}

	/**
	 * Fulfill
	 * ---
	 * Fulfills the whole group
	 * @param {*} data
	 */
	fulfill(data) {
		this._promises.forEach(promise => promise.fulfill(data));
		this._onResolved(this.identifier);
	}

	/**
	 * Reject
	 * ---
	 * Rejects the whole group
	 * @param {*} data
	 */
	reject(data) {
		this._promises.forEach(promise => promise.reject(data));
		this._onResolved(this.identifier);
	}

	/**
	 * On resolved
	 * ---
	 * Listener for the resolve state for the group
	 * @param {function(idenfier)} callback
	 */
	onResolved(callback) {
		this._onResolved = callback;
	}
}
