import { Component } from "preact";
import PropTypes from "prop-types";
import { ArrayUtils, combineClasses, get } from "@green24/npm-javascript-utils";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import ButtonsConstructor from "../../components/shared/input/Button/ButtonsConstructor";
import ProductModelViewer from "./ProductModelViewer";
import { FeatureCustomizerContext } from "./ProductFeatureCustomizer";
import ProductFeaturesController from "./ProductFeaturesController";
import Tabs from "../../components/shared/Tabs/Tabs";
import { route } from "preact-router";
import withFeatureCustomizerContext from "../../components/other/product/withFeatureCustomizerContext";
import ProductConfiguratorFooter from "./ProductConfiguratorFooter";
import { Redirect } from "../../components/components";

class ProductConfigurator extends Component {
	render({className, style, product, categoryID, productGroup, featureCustomizerContext}, {}) {
		const {categories} = featureCustomizerContext;

		const selectedCategory = ArrayUtils.findByID(categories, categoryID) || categories[0] || {id: -1};
		const currentCategoryIndex = categories.indexOf(selectedCategory);
		const previousCategory = categories[currentCategoryIndex - 1];
		const nextCategory = categories[currentCategoryIndex + 1];

		return (
			<section className={combineClasses("product-configurator", className)} style={style}>
				{
					(
						//Redirect if categoryID is not present in the URL and there is at least one category available
						(
							!categoryID &&
							selectedCategory.id
						)
						||
						//Redirect if the category is not currently available (e.g. hidden by the restrictions)
						!ArrayUtils.findByID(categories, categoryID)
					) &&
					<Redirect to={APP_ROUTE.CONFIGURATOR_CATEGORY(productGroup.id, product.id, selectedCategory.id)}/>
				}

				<div className={"content"}>
					<Tabs
						className={"category-selector"}
						tabs={categories.map(category => {
							return {
								data: category,

								text: category.title,
								textTranslated: true,
								active: category.id == selectedCategory.id,
								disabled: category.disabled,
							}
						})}
						onTabSelected={tab => route(APP_ROUTE.CONFIGURATOR_CATEGORY(productGroup.id, product.id, tab.data.id) + window.location.search)}
						sharedProps={{className: "no-style"}}
					/>

					<FeatureCustomizerContext.Consumer>
						{({enabledFeatures, features, featuresStates, onModifyFeatureState}) => {
							return (
								<div className={"surface"}>
									<ProductModelViewer modelUrl={product.links.model} features={features} categories={categories} enabledFeatures={enabledFeatures}/>

									<div className={"categories"}>
										{
											categories.map(category => {
												return (
													<div className={combineClasses(
															"category",
															selectedCategory.id == category.id && "visible",
														)}>
														<h2>{category.title}</h2>
														<pre>{category.description}</pre>

														<ProductFeaturesController
															optionGroups={category.groups}
															featuresStates={featuresStates}
															onModify={onModifyFeatureState}
														/>
													</div>
												);
											})
										}
									</div>
								</div>
							);
						}}
					</FeatureCustomizerContext.Consumer>
				</div>

				<ProductConfiguratorFooter
					product={product}
					showPrice={true}
					navigationButtons={
						<ButtonsConstructor buttons={[
							{
								linkProps: {
									className: combineClasses("navigation-previous", !!previousCategory && "visible"),
								},
								text: "navigation.previous",
								href: APP_ROUTE.CONFIGURATOR_CATEGORY(productGroup.id, product.id, get(previousCategory, "id")),
							},
							{
								linkProps: {
									className: combineClasses("navigation-back", !previousCategory && "visible"),
								},
								text: "navigation.back",
								href: APP_ROUTE.PRODUCT_GROUP(productGroup.id),
							},
							{
								linkProps: {
									className: combineClasses("navigation-next", !!nextCategory && "visible"),
								},
								text: "navigation.next",
								href: APP_ROUTE.CONFIGURATOR_CATEGORY(productGroup.id, product.id, get(nextCategory, "id")),
							},
							{
								linkProps: {
									className: combineClasses("navigation-summary", !nextCategory && "visible"),
								},
								text: "summary",
								href: APP_ROUTE.CONFIGURATOR_SUMMARY(productGroup.id, product.id),
							},
						]}/>
					}
				/>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			product: PropTypes.object.isRequired,
			productGroup: PropTypes.object.isRequired,
			categoryID: PropTypes.number,

			//withFeatureCustomizerContext
			featureCustomizerContext: PropTypes.object,
		}
	}
}

export default withFeatureCustomizerContext(ProductConfigurator);
