import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import { apiConnector } from "../../services/api-connector";

class ImageFrame extends Component {
	render({className, style, src}, {}) {
		const prependApiURL = src && src.charAt(0) === '/';

		return (
			<section className={combineClasses("image-frame", className)} style={style}>
				{
					src &&
					<img src={prependApiURL ? apiConnector.api + src : src} alt={''}/>
				}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default ImageFrame;
