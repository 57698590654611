import { IS } from "./IS";

function compareEditDistance(s1, s2) {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();

	let costs = [];
	for(let i = 0; i <= s1.length; i++) {
		let lastValue = i;
		for(let j = 0; j <= s2.length; j++) {
			if(i == 0) {
				costs[j] = j;
			}
			else {
				if(j > 0) {
					let newValue = costs[j - 1];
					if(s1.charAt(i - 1) != s2.charAt(j - 1)) {
						newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
					}

					costs[j - 1] = lastValue;
					lastValue = newValue;
				}
			}
		}
		if(i > 0) {
			costs[s2.length] = lastValue;
		}
	}
	return costs[s2.length];
}

export class StringUtils {
	/**
	 * Compare similarity
	 * ---
	 * @param {string} s1
	 * @param {string} s2
	 * @return {number}
	 * @see https://stackoverflow.com/a/36566052
	 */
	static compareSimilarity(s1, s2) {
		let longer = s1;
		let shorter = s2;
		if(s1.length < s2.length) {
			longer = s2;
			shorter = s1;
		}
		let longerLength = longer.length;
		if(longerLength == 0) {
			return 1;
		}
		return (longerLength - compareEditDistance(longer, shorter)) / parseFloat(longerLength);
	}

	/**
	 * Split to parts
	 * ---
	 * Splits provided string into parts based on **regexPattern**
	 * @param {string} str
	 * @param {RegExp} regexPattern
	 * @return {string[]}
	 */
	static splitToParts(str, regexPattern) {
		let parts = [];
		let remainingStr = str;

		let match;
		while (match = remainingStr.match(regexPattern)) {
			let variableStart = match.index;
			let variableEnd = match.index + match[0].length;

			if(match.index > 0) {
				parts.push(remainingStr.slice(0, variableStart));
			}

			parts.push(remainingStr.slice(variableStart, variableEnd));
			remainingStr = remainingStr.slice(variableEnd);
		}

		if(remainingStr.length) {
			parts.push(remainingStr);
		}

		return parts;
	}

	/**
	 * Append if valid
	 * ---
	 * Appends content if the provided string is valid and not empty.
	 * ```
	 *  StringUtils.appendIfValid('6', '6') => "6x"
	 *  StringUtils.appendIfValid('', 'x') || '-' => '-'
	 * ```
	 * @param {string} str
	 * @param {...string} appends
	 * @return {string}
	 */
	static appendIfValid(str, ...appends) {
		return IS.valid(str) && !IS.empty(str) ? str + appends.join('') : str;
	}
}
