export class DomUtils {
	static get parent() {
		return ParentDomUtils;
	}

	/*
	FFS: Needs to be tested first
	/**
	 * Can Scroll
	 * ---
	 * @param {HTMLElement} element
	 * @return {boolean}
	 *
	static canScroll(element) {
		return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
	}
	*/
}

export class ParentDomUtils {
	/**
	 * Find all parents
	 * ---
	 * Returns a collection of all parents of the provided element
	 * @param {HTMLElement} element
	 * @return {HTMLElement[]}
	 */
	static findAllParents(element) {
		let parents = [];
		let el = element;

		while (el && el.parentElement) {
			parents.push(el.parentElement);

			el = el.parentElement;
		}

		return parents;
	}

	/**
	 * Find all offset parents
	 * ---
	 * Returns a collection of all offset parents of the provided element
	 * @param {HTMLElement} element
	 * @return {HTMLElement[]}
	 */
	static findAllOffsetParents(element) {
		let parents = [];
		let el = element;

		while (el && el.offsetParent) {
			parents.push(el.offsetParent);

			el = el.offsetParent;
		}

		return parents;
	}

	/**
	 * Find scroll parent
	 * ---
	 * Finds the first parent that is scrollable
	 * @param {HTMLElement} element
	 * @return {HTMLElement}
	 */
	static findScrollParent(element) {
		const position = element.style.position;

		//There is no parent except body/document
		if(position == "fixed") return element.ownerDocument || document;

		const excludeStaticParent = position == "absolute";
		const scrollParent = this.findAllOffsetParents(element).find((parent) => {
			if(excludeStaticParent && parent.style.position == "static") return false;

			return (/(auto|scroll)/).test(parent.style.overflow + parent.style.overflowY + parent.style.overflowX);
		});

		return scrollParent || element.ownerDocument || document;
	}
}
