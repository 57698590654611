import { Component, Fragment } from "preact";
import PropTypes from "prop-types";
import { ArrayUtils, combineClasses, IS } from "@green24/npm-javascript-utils";
import withFeatureCustomizerContext from "../../components/other/product/withFeatureCustomizerContext";
import { Interpret, Price, WidestContent, withLocalizationContext } from "../../components/components";
import { ProductCategoriesUtils, ProductFeaturesUtils, ProductUtils } from "../../utils/ProductUtils";
import ContactForm from "../../components/other/ContactForm";
import { M_Contact } from "../../models/Models_Contact";
import { ComponentStateUtils } from "@green24/npm-preact-utils";
import TextField from "../../components/shared/input/TextField";
import ButtonsConstructor from "../../components/shared/input/Button/ButtonsConstructor";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import { cartServices } from "../../services/cart-services";
import Loading from "../../components/shared/Loading";
import { route } from "preact-router";
import NumberSpinner from "../../components/shared/input/NumberSpinner";
import { AppUtils } from "../../utils/AppUtils";
import MessageModal from "../../components/shared/Modal/MessageModal";
import Form, { FormContext } from "../../components/shared/input/Form";
import withProject from "../../components/other/project/hoc/withProject";
import { ErrorUtils } from "../../utils/ErrorUtils";
import ProductConfiguratorFooter from "./ProductConfiguratorFooter";
import FeatureCost from "../../components/shared/FeatureCost";
import { E_CostUnit } from "../../models/constants/Enums_Tariff";

class ProductConfigurationSummary extends Component {
	constructor(props) {
		super();

		const submitData = {
			contact: M_Contact,
			items: [
				{
					product: {id: props.product.id},
					selectedOptions: props.featureCustomizerContext.enabledFeatures.map(feature => ({id: feature.id})),
					quantity: 1,
					note: undefined,
				}
			],
			locale: props.localizationContext.activeLanguage.ISO639_1,
		};

		this.state = {
			data: submitData,
			sending: false,
		}
	}

	_renderProductFeatureItem(item, product) {
		const group = product ? ProductCategoriesUtils.findGroupFromOption(product.categories, item) : null;

		return (
			<>
				<label>
					<small>{group && group.title && `${group.title.trim()}: `}</small>
					{item.title}
				</label>
				<span className={combineClasses(!item.cost && "no-cost")}>
					{
						IS.defined(item.cost) && item.cost != 0 ? <FeatureCost feature={item}/> : '-'
					}
				</span>
			</>
		)
	}

	render({className, style, product, featureCustomizerContext, productGroup}, {data, sending}) {
		const {enabledFeatures, categories} = featureCustomizerContext;
		const onModify = (v, p, t) => this._handleDataModify(v, p, t);
		const totalItemCost = ProductUtils.getTotalCost(product, enabledFeatures);
		const installationLocationRequired = ProductFeaturesUtils.isInstallationLocationRequired(enabledFeatures);

		return (
			<section className={combineClasses("product-configuration-summary", className)} style={style}>
				{
					sending &&
					<Loading title={"sending"} className={"with-background"} style={{zIndex: 5, fontSize: "2em"}}/>
				}

				<Form
					onSubmit={(allFieldsValid) => this._openSubmitConfirm(allFieldsValid)}
				>
					<div className={"max-width"}>
						<div className={"surface"}>
							<h1><Interpret id={"configuration"}/></h1>

							<div className={"items-summary"}>
								<label className={"header"}>{product.title}</label>
								<span className={"header"}>
									{
										totalItemCost > 0 &&
										<Price value={totalItemCost}/>
									}
								</span>

								{
									product.cost > 0 &&
									this._renderProductFeatureItem({
										...product,
										title: <Interpret id={"cost.base"}/>,
										costUnit: E_CostUnit.CURRENCY,
									}, null)
								}
								{
									enabledFeatures.map(feature => {
										return (
											<Fragment key={feature.id}>
												{this._renderProductFeatureItem(feature, product)}
											</Fragment>
										)
									})
								}

								<span className={"total-price"}>
									<NumberSpinner
										min={1}
										value={data.items[0].quantity}
										suffix={"x"}
										path={"items.0.quantity"}

										onImmediateModify={onModify}
									/>

									{
										data.items[0].quantity * totalItemCost > 0 &&
										<>
											&nbsp;=&nbsp;

											<b>
												<Interpret id={"price.withoutVAT"} params={{
													price: (
														<WidestContent>
															<div visible><Price value={data.items[0].quantity * totalItemCost}/></div>
															<div><Price value={10 * ProductUtils.getTotalCost(product, enabledFeatures)}/></div>
														</WidestContent>
													)
												}}/>
											</b>
										</>
									}

								</span>
							</div>

							<TextField
								label={installationLocationRequired ? "form.installationLocationAndOtherNotes" : "note"}
								root={data}
								path={"items.0.note"}
								maxLength={1024}
								counter={true}
								textarea={true}
								required={installationLocationRequired}

								onModify={onModify}
							/>
						</div>

						<div className={"surface"}>
							<h1><Interpret id={"contact"}/></h1>

							<ContactForm
								contact={data.contact}
								onModify={(v, p, t) => this._modifyContact(v, p, t)}
								required={true}
							/>
						</div>
					</div>

					<ProductConfiguratorFooter
						product={product}
						showPrice={false}
						navigationButtons={
							<FormContext.Consumer>
								{formContext => {
									const lastCategory = ArrayUtils.lastItem(categories);

									return (
										<ButtonsConstructor buttons={[
											{
												text: "navigation.back",
												href: lastCategory ? APP_ROUTE.CONFIGURATOR_CATEGORY(productGroup.id, product.id, lastCategory.id) : APP_ROUTE.CONFIGURATOR(productGroup.id, product.id),
											},
											{
												text: "form.submit",
												action: () => formContext.validate(),
												buttonProps: {
													type: "submit",
												}
											}
										]}/>
									);
								}}
							</FormContext.Consumer>
						}
					/>
				</Form>
			</section>
		);
	}

	_modifyContact(value, path, modificationType) {
		this._handleDataModify(value, "contact." + path, modificationType);
	}

	_handleDataModify(value, path, modificationType) {
		ComponentStateUtils.modify(this, "data", value, path, modificationType);
	}

	_openSubmitConfirm(allValid) {
		if(!allValid) return;

		AppUtils.globalModal.open(
			<MessageModal
				className={"auto-size"}
				title={"cart.submit.confirm.title"}
				message={"cart.submit.confirm.message"}
				//FFS: Prepared if there will be a side message
				// sideMessage={{
				// 	icon: "envelope",
				// 	title: "cart.submit.confirm.sideMessageTitle",
				// 	text: "cart.submit.confirm.sideMessage"
				// }}
				actionButtons={(modalContext) => [
					{
						text: "form.submit",
						action: () => {
							modalContext.close();
							this._handleSubmit();
						},
					}
				]}
			/>
		);
	}

	_handleSubmit() {
		const {project} = this.props;

		this.setState({sending: true});
		cartServices(project.id).sendOrder(this.state.data).then(() => {
			this._openOrderSentModal();

			route(APP_ROUTE.BASE);
		}, (res) => {
			this.setState({sending: false});
			this._openOrderNotSentError(res);
		});
	}

	_openOrderSentModal() {
		AppUtils.globalModal.open(
			<MessageModal
				className={"auto-size"}
				title={"cart.submit.success.title"}
				message={"cart.submit.success.message"}
			/>
		);
	}

	_openOrderNotSentError(res) {
		let {icon, title, titleTranslated} = ErrorUtils.getErrorFromResponse(res);

		AppUtils.globalModal.open(
			<MessageModal
				className={"auto-size"}
				title={"cart.submit.error.title"}
				message={`cart.submit.error.message`}
				sideMessage={{
					title: res.statusCode,
					titleTranslated: true,
					text: title,
					textTranslated: titleTranslated,
					icon,
				}}
			/>,
			{
				accent: "error",
			}
		)
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			product: PropTypes.object.isRequired,

			//withFeatureCustomizerContext
			featureCustomizerContext: PropTypes.object.isRequired,

			//withLocalizationContext
			localizationContext: PropTypes.object.isRequired,
		}
	}

	static get stateTypes() {
		return {
			data: PropTypes.object,
		}
	}
}

export default withFeatureCustomizerContext(withLocalizationContext(withProject(ProductConfigurationSummary)));
