import { IS } from "./IS";

/**
 * @typedef {{
 *  compactDisplay: "short"|"long",
 *  currency: string,
 *  currencyDisplay: "symbol"|"narrowSymbol"|"code"|"name",
 *  currencySign: "standard"|"accounting",
 *  localeMatcher: "lookup"|"best fit",
 *  notation: "standard"|"scientific"|"engineering"|"compact",
 *  numberingSystem: "arab"|"arabext"|"bali"|"beng"|"deva"|"fullwide"|"gujr"|"guru"|"hanidec"|"khmr"|"knda"|"laoo"|"latn"|"limb"|"mlym"|"mong"|"mymr"|"orya"|"tamldec"|"telu"|"thai"|"tibt",
 *  signDisplay: "auto"|"never"|"always"|"exceptZero",
 *  styles: "decimal"|"currency"|"percent"|"unit",
 *  unit: Unit,
 *  unitDisplay: "long"|"short"|"narrow",
 *  useGrouping: boolean,
 *  minimumIntegerDigits: number,
 *  minimumFractionDigits: number,
 *  maximumFractionDigits: number,
 *  minimumSignificantDigits: number,
 *  maximumSignificantDigits: number,
 * }} NumberFormatOptions
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */

/**
 * @typedef {E_Format_Unit} Unit The unit to use in unit formatting. Pairs of simple units can be concatenated with "-per-" to make a compound unit.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
 */

export class FormatUtils {
	/**
	 * Format as price
	 * ---
	 * @param {E_LanguageWithRegion|function(NumberFormatOptions): Intl.NumberFormat} localeOrNumberFormatConstructor
	 * @param {number} value
	 * @param {string} currency
	 * @param {{
	 *  fractionDigits: number|boolean,
	 *  removeFractionDigitsIfZero: boolean,
	 * } & NumberFormatOptions} additionalOptions
	 * @return {{parts: Intl.NumberFormatPart[], text: string}}
	 */
	static formatAsPrice(localeOrNumberFormatConstructor = "cs", value, currency = "CZK", additionalOptions = {}) {
		const {fractionDigits, removeFractionDigitsIfZero, ...additionalFormatOptions} = additionalOptions;

		const formatOptions = FormatOptionsUtils.constructPriceOptions(currency, {
			...(
				(fractionDigits >= 0 || fractionDigits === false) &&
				{
					maximumFractionDigits: fractionDigits === false ? 0 : fractionDigits,
					minimumFractionDigits: fractionDigits === false ? 0 : fractionDigits,
				}
			),
			...additionalFormatOptions,
		});

		let format;
		if(IS.fnc(localeOrNumberFormatConstructor)) {
			format = localeOrNumberFormatConstructor(formatOptions);
		}
		else {
			format = new Intl.NumberFormat(localeOrNumberFormatConstructor, formatOptions);
		}

		const parts = format.formatToParts(value);

		if(removeFractionDigitsIfZero) {
			if(parts.length == 1 && parts[0].type == "literal") {
				const {value} = parts[0];
				const match = `${value}`.match(/-?[  ,.\d]+/);

				let trimmedValue = match[0].replace(/([,.]00) ?$/, '');
				if(match[0].charAt(match[0].length - 1) == ' ') {
					trimmedValue+= ' ';
				}

				return {
					text: value.replace(match, trimmedValue),
					parts,
				}
			}

			let nonFractionParts = parts.filter(
				part => !(part.type == "decimal" || (part.type == "fraction" && part.value == "00"))
			);

			if(nonFractionParts.length + 2 == parts.length) {
				return {
					text: nonFractionParts.map(part => part.value).join(''),
					parts: nonFractionParts,
				}
			}
		}

		return {
			text: format.format(value),
			parts,
		}
	}

	static formatWithUnit(localeOrNumberFormatConstructor = "cs", value, unit, additionalOptions = {}) {
		const {...additionalFormatOptions} = additionalOptions;

		const formatOptions = FormatOptionsUtils.constructUnitOptions(unit, {
			...additionalFormatOptions,
		});

		let format;
		if(IS.fnc(localeOrNumberFormatConstructor)) {
			format = localeOrNumberFormatConstructor(formatOptions);
		}
		else {
			format = new Intl.NumberFormat(localeOrNumberFormatConstructor, formatOptions);
		}

		return {
			text: format.format(value),
			parts: format.formatToParts(value),
		}
	}
}

export class FormatOptionsUtils {
	static constructPriceOptions(currency = "CZK", additionalOptions) {
		return {
			style: "currency",
			currency,
			...additionalOptions,
		}
	}

	static constructUnitOptions(unit, additionalOptions) {
		return {
			style: "unit",
			unit,
			...additionalOptions,
		}
	}
}
