import { Component as PComponent } from "preact";
import { LocalizationContext } from "../LocalizationProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withLocalizationContext = Component => {
	class HOC extends PComponent {
		render(props) {
			return (
				<LocalizationContext.Consumer>
					{(localizationContext) => {
						return (
							<Component
								localizationContext={localizationContext}
								{...this.props}
							/>
						)
					}}
				</LocalizationContext.Consumer>
			);
		}

		static get displayName() {
			return "withLocalizationContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withLocalizationContext;
