/**
 * Pointer input mode enum
 * ---
 * @enum string
 * @readonly
 */
export const E_PointerInputMode = {
	MOUSE: "MOUSE",
	TOUCH: "TOUCH",
};
