import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { StackNotifier } from "@green24/npm-javascript-utils";
import { CustomOrbitControls } from "./CustomOrbitControls";

export class ProductEngine {
	constructor(width, height) {
		this._mainModel = null;
		this._models = [];
		this._stackNotifier = new StackNotifier();

		const renderer = this._renderer = new THREE.WebGLRenderer({
			antialias: true,
		});
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.toneMapping = THREE.ACESFilmicToneMapping;
		renderer.toneMappingExposure = 1;
		renderer.outputEncoding = THREE.sRGBEncoding;

		this.setupScene();
		this.resize(width, height);
		this.animate();
	}

	get renderer() {
		return this._renderer;
	}

	get camera() {
		return this._cam;
	}

	get scene() {
		return this._scene;
	}

	get controls() {
		return this._controls;
	}

	get mainModel() {
		return this._mainModel;
	}

	onInteractionStart(callback, override = false) {
		this._stackNotifier.on("interaction-start", callback, override);
	}

	hasMainModel() {
		return !!this.mainModel;
	}

	resize(width, height) {
		this.renderer.setSize(width, height);
		this.updateCameraAspectRatio(width / height);
		this.render();
	}

	resizeToElement(element) {
		let rect = element.getBoundingClientRect();
		this.resize(rect.width, rect.height);
	}

	render(scene = this.scene, camera = this.camera) {
		this.renderer && this.renderer.render(scene, camera);
	}

	updateCameraAspectRatio(aspect, camera = this.camera) {
		if(camera) {
			camera.aspect = aspect;
			camera.updateProjectionMatrix();
		}
	}

	fitCameraToObjects(objects, {offset = 1.25, revertRotation = false} = {}) {
		const camera = this.camera;
		const controls = this._controls;

		const boundingBox = new THREE.Box3();

		// get bounding box of object - this will be used to setup controls and camera
		objects.forEach(object => {
			boundingBox.expandByObject(object);

			//Debug bounding box visualization
			// const box = new THREE.BoxHelper(object, 0xff0000);
			// this.scene.add( box );
		});

		let center = new THREE.Vector3();
		boundingBox.getCenter(center);

		let size = new THREE.Vector3();
		boundingBox.getSize(size);

		// get the max side of the bounding box (fits to width OR height as needed)
		const maxDim = Math.max(size.x, size.y);

		let vFov = camera.getEffectiveFOV();
		let hFov = camera.fov * camera.aspect;
		let fov = Math.max(vFov, hFov);

		let cameraZ = Math.abs(maxDim / 2 * Math.tan(fov * 2));
		cameraZ += offset; // zoom out a little so that objects don't fill the screen

		revertRotation && controls.saveRuntimeState();

		camera.position.x = center.x;
		camera.position.y = center.y;
		camera.position.z = cameraZ;
		camera.updateProjectionMatrix();

		revertRotation && controls.resetRuntimeState();

		// set camera to rotate around center of loaded object
		controls.target = center;

		controls.update();
	}

	setupScene() {
		const camera = this._cam = new THREE.PerspectiveCamera(45, 1, 0.001, 500);
		// camera.position.set(0, 2, 5);
		// camera.position.set(0, 0, 0);
		// camera.lookAt(0, 0, 0);

		const scene = this._scene = new THREE.Scene();
		// scene.background = new THREE.Color(0xa0a0a0);
		scene.background = new THREE.Color(0xffffff);
		// scene.fog = new THREE.Fog(0xa0a0a0, 10, 50);

		const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444);
		hemisphereLight.position.set(0, 20, 0);
		scene.add(hemisphereLight);

		const dirLight = new THREE.DirectionalLight(0xffffff);
		dirLight.position.set(-3, 10, 10);
		dirLight.castShadow = true;
		dirLight.shadow.camera.top = 2;
		dirLight.shadow.camera.bottom = -2;
		dirLight.shadow.camera.left = -2;
		dirLight.shadow.camera.right = 2;
		dirLight.shadow.camera.near = 0.1;
		dirLight.shadow.camera.far = 40;
		scene.add(dirLight);

		// const mesh = new THREE.Mesh(new THREE.PlaneGeometry(100, 100), new THREE.MeshPhongMaterial({color: 0x999999, depthWrite: false}));
		// mesh.rotation.x = -Math.PI / 2;
		// mesh.receiveShadow = true;
		// scene.add(mesh);

		const controls = this._controls = new CustomOrbitControls(camera, this.renderer.domElement);
		controls.addEventListener('change', () => this.render(scene, camera)); // use if there is no animation loop
		controls.addEventListener('start', (e) => this._stackNotifier.notify("interaction-start", e));
		controls.minDistance = 2;
		controls.maxDistance = 10;
		controls.autoRotate = {
			enabled: true,
			speed: 1,
			limits: [-50, 50],
			movement: "pendulum",
		};
		// controls.target.set(0, 1.5, 0);
		// controls.update();
	}

	resetScene() {
		this._controls.reset();
	}

	loadModel(url, onProgress = () => null) {
		return new Promise((resolve, reject) => {
			const loader = new GLTFLoader();
			loader.load(url, ({scene: model}) => {
				model.traverse((object) => {
					if(object.isMesh) object.castShadow = true;
				});

				resolve(model);
			}, onProgress, (err) => {
				reject(err);
			});
		});
	}

	addModelToScene(model) {
		this._models.push(model);

		this.scene.add(model);
	}

	addMainModelToScene(model) {
		let objectWrapper = new THREE.Mesh();
		objectWrapper.add(model);

		this._mainModel = objectWrapper;

		this.addModelToScene(objectWrapper);
		this.fitCameraToObjects([objectWrapper]);
		this.controls.saveState();
	}

	animate() {
		if(this._renderer) {
			requestAnimationFrame(() => this.animate());
			this._controls.update();
		}
	}

	setAutoRotate(enabled) {
		this._controls.autoRotate.enabled = enabled;
		this._controls.update();
	}

	destroy() {
		this._controls.dispose();
		this._renderer.dispose();
		this._renderer = null;
	}

	updateSceneBackground(r, g = undefined, b = undefined) {
		this.scene.background = new THREE.Color(r, g, b);
	}
}
