import { JSON_REACT_REPLACER_KEYS } from "../models/constants/SharedContants";
import { get, resolvePolymorphVar } from "../functions/generic";
import { ArrayUtils } from "./ArrayUtils";

const resolveRestrictionTest = (test, v) => {
	return resolvePolymorphVar(
		test,
		{
			function: f => {
				if(test instanceof RegExp) {
					return test.test(v);
				}
				return f(v);
			},
		},
		test == v
	);
};

export class IS {
	// === === === === === ===
	//          TYPES
	// === === === === === ===

	static object(value) {
		return typeof value === "object" && Object(value) === value;
	}

	static array(value) {
		return Array.isArray(value);
	}

	static arguments(value) {
		 return toString.call(value) === '[object Arguments]' || (value != null && typeof value === 'object' && 'callee' in value);
	}

	static string(value) {
		return typeof value === "string";
	}

	static number(value) {
		return !IS.nan(value) && typeof value === 'number'; // NaN is a number but not a valid one.
	}

	static nan(value) {
		return typeof value === "number" && value !== value; // NaN is a number :) Also it is the only value which does not equal itself
	}

	static date(value) {
		return value instanceof Date;
	}

	static validDateObject(value) {
		// noinspection JSIncompatibleTypesComparison
		return IS.date(value) && new Date(value) != "Invalid Date";
	}

	static fnc(value) {
		return toString.call(value) === '[object Function]' || typeof value === 'function'; // fallback check is for IE
	}

	static boolean(value) {
		return value === true || value === false || toString.call(value) === '[object Boolean]';
	}

	/**
	 * @deprecated Just use the native instanceOf
	 */
	static instanceOf(value, instance) {
		return value instanceof instance;
	}

	// === === === === === ===
	//      COMPARATORS
	// === === === === === ===

	/**
	 * Empty
	 * ---
	 * Returns if provided value is empty (e.g. [], {}, '')
	 * @param value
	 * @return {boolean}
	 */
	static empty(value) {
		if(IS.defined(value)) {
			//Check for object but not for Date object because that is valid even if it's return value is "Invalid Date"
			if(IS.object(value) && !IS.date(value)) {
				//Get all property names
				let length = Object.getOwnPropertyNames(value).length;
				//0 = no properties (most possibly empty); IS.array([]) = ["length"];
				return !!(length === 0 || (length === 1 && IS.array(value)) || (length === 2 && IS.arguments(value)));
			}

			return value === '';
		}
		return true;
	}

	/**
	 * Defined
	 * ---
	 * Returns if the value is defined.
	 *
	 * **Notice!** Both **null** and **undefined** is considered to be an undefined state!
	 * @param {*} value
	 * @return {Boolean}
	 */
	static defined(value) {
		return typeof value !== "undefined" && value !== null;
	}

	/**
	 * Valid
	 * ---
	 * Returns if provided value is valid (defined).
	 * Don't misinterpret with validity in JS terms (e.g. 0 or '' are valid).
	 * For all values but undefined, null, NaN and "Invalid Date"; should return true
	 * @param {*} value
	 * @return {Boolean}
	 */
	static valid(value) {
		//{value} != null will be false for null and undefined but will pass for '', 0, [], {}
		return IS.defined(value) && (IS.date(value) ? IS.validDateObject(value) : true) && !IS.nan(value);
	}

	/**
	 * Valid content
	 * ---
	 * Returns if content of provided value is valid (either some or all based on 'every' param value).
	 *
	 * Accepts anything but mostly can be used to check if an array, string or an object is defined but empty
	 * @param {*} value
	 * @param {Boolean} every
	 * @return {Boolean}
	 */
	static validContent(value, every = false) {
		if(IS.valid(value) && !IS.empty(value)) {
			if (Array.isArray(value)) {
				return (value || [])[every ? "every" : "some"](item => IS.valid(item));
			}

			if (IS.object(value)) {
				if(IS.validDateObject(value)) {
					return true;
				}

				return Object.values(value)[every ? "every" : "some"](item => IS.valid(item));
			}
			return true;
		}
		return false;
	}

	/**
	 * Equal
	 * ---
	 * Returns if value and others are equal in terms of both type and value
	 * @param {*} value
	 * @param {*} other
	 * @return {Boolean}
	 */
	static equal(value, ...other) {
		return other.every(item => {
			//Watch out for NaN because NaN !== NaN;
			if(!value && !item) {
				return value === item;
			}

			if(IS.fnc(value) && IS.fnc(item)) {
				//TODO: Find better solver for this problem
				return value === item && JSON.stringify(value) == JSON.stringify(item);
			}

			//Symbol is a unique per instance object and cannot be parsed by the JSON (.stringify() will omit the entry)
			if(typeof value === "symbol" && typeof item === "symbol") {
				return value === item;
			}

			const replacer = (k, v) => JSON_REACT_REPLACER_KEYS.includes(k) ? undefined : v;
			//Nested object **string** comparison; This WILL FAIL if the objects are not 100% the same (structure, order, types, ...)
			return JSON.stringify(value, replacer) == JSON.stringify(item, replacer);
		});
	}

	// -----------------------
	//          Date
	// -----------------------

	/**
	 * Today
	 * ---
	 * Returns if provided date is equal to NOW date in terms of day, month and year
	 * @param date Date to test
	 * @return {boolean}
	 * @see IS.sameDay
	 */
	static today(date) {
		let now = new Date();
		return IS.sameDay(date, now);
	}

	/**
	 * Past
	 * ---
	 * Returns if possiblePastDate is set to past date compared to compareWith date
	 * @param {Date} possiblePastDate Possible past date
	 * @param {Date} compareWith Comparison date (Defaults to NOW)
	 * @return {boolean}
	 */
	static past(possiblePastDate, compareWith = new Date()) {
		return new Date(compareWith) - new Date(possiblePastDate) > 0;
	}

	/**
	 * Same day
	 * ---
	 * Returns if date1 and date2 are of the same day (day = date in JS Date terminology), month and year
	 * @param {Date} date1
	 * @param {Date} date2
	 * @return {Boolean}
	 */
	static sameDay(date1, date2 = new Date()) {
		return IS.sameMonth(date1, date2) && new Date(date1).getDate() === new Date(date2).getDate();
	}

	/**
	 * Same month
	 * ---
	 * Returns if date1 and date2 are of the same month and year
	 * @param {Date} date1
	 * @param {Date} date2
	 * @return {Boolean}
	 */
	static sameMonth(date1, date2 = new Date()) {
		return IS.sameYear(date1, date2) && new Date(date1).getMonth() === new Date(date2).getMonth();
	}

	/**
	 * Same year
	 * ---
	 * Returns if date1 and date2 are of the same year
	 * @param {Date} date1
	 * @param {Date} date2
	 * @return {Boolean}
	 */
	static sameYear(date1, date2) {
		return new Date(date1).getFullYear() === new Date(date2).getFullYear();
	}

	// === === === === === ===
	//          OTHER
	// === === === === === ===

	/**
	 * Property
	 * ---
	 * Returns if **EVERY** property is defined inside the target obj **(own or otherwise!)**
	 * @param {Object} obj Target object
	 * @param {String} properties Sequence of object keys (string)
	 * @return {Boolean}
	 * @see IS.ownProperty
	 * @see Object.prototype.hasOwnProperty
	 * @see Object.keys
	 */
	static property(obj, ...properties) {
		return IS.valid(obj) && properties.every(prop => (IS.ownProperty(obj, prop) || Object.keys(obj).includes(prop) || obj[prop]));
	}

	/**
	 * Nested property
	 * ---
	 * Returns if **EVERY** property is defined inside the target obj along the path **(own or otherwise!)**
	 * @param {Object} obj Target object
	 * @param {String} properties Sequence of object keys (string)
	 * @return {Boolean}
	 * @see IS.ownProperty
	 * @see Object.prototype.hasOwnProperty
	 * @see Object.keys
	 */
	static nestedProperty(obj, ...properties) {
		return IS.valid(obj) && properties.every(prop => {
			if(`${prop}`.includes('.') || IS.array(prop)) {
				let path = IS.array(prop) ? prop : prop.split(".");
				let partialPath = ArrayUtils.select(path, 0, "len-1");
				let root = get(obj, partialPath, null);
				let lastItem = ArrayUtils.lastItem(path);

				return IS.property(root, ArrayUtils.lastItem(path));
			}
			else {
				return IS.property(obj, prop);
			}
		});
	}

	/**
	 * Own property
	 * ---
	 * Returns if **EVERY** property is defined inside the target obj **(own ONLY)**
	 * @param {Object} obj Target object
	 * @param {String} properties Sequence of object keys (string)
	 * @return {Boolean}
	 * @see Object.prototype.hasOwnProperty
	 */
	static ownProperty(obj, ...properties) {
		return IS.valid(obj) && properties.every(prop => ({}.hasOwnProperty.call(obj, prop)));
	}

	/**
	 * Blacklisted
	 * ---
	 * Returns if the **value** is blacklisted.
	 * @param {*} value Provided value
	 * @param {number|array|string|function(value, resolveRestrictionTest)} blacklist Blocked value(s) or resolver
	 * @returns {boolean} Blacklisted
	 */
	static blacklisted(value, blacklist) {
		if(IS.valid(blacklist)) {
			let blacklistTest = resolvePolymorphVar(
				blacklist,
				{
					array: a => a.some(test => resolveRestrictionTest(test, value)),
					function: f => f(value, resolveRestrictionTest),
				},
				resolveRestrictionTest(blacklist, value)
			);

			if(blacklistTest) return true;
		}

		return false;
	}

	/**
	 * Blacklisted
	 * ---
	 * Returns if the **value** is whitelisted.
	 * @param {*} value Provided value
	 * @param {number|array|string|function(value, resolveRestrictionTest)} whitelist Allowed value(s) or resolver
	 * @returns {boolean} Whitelisted
	 */
	static whitelisted(value, whitelist) {
		if(IS.valid(whitelist)) {
			let whitelistTest = resolvePolymorphVar(
				whitelist,
				{
					array: a => a.some(test => resolveRestrictionTest(test, value)),
					function: f => f(value, resolveRestrictionTest),
				},
				resolveRestrictionTest(whitelist, value)
			);

			if(!whitelistTest) return false;
		}

		return true;
	}

	/**
	 * Graylisted
	 * ---
	 * Returns if the **value** is not blocked by the **blacklist** and is allowed by the **whitelist**.
	 * @param {*} value Provided value
	 * @param {number|array|string|function(value, blacklist)} blacklist Blocked value(s) or resolver
	 * @param {number|array|string|function(value, blacklist)} whitelist Allowed value(s) or resolver
	 * @returns {boolean} True = allowed, False = blocked by the blacklist or the whitelist
	 */
	static graylisted(value, blacklist, whitelist) {
		if(!IS.valid(blacklist) && !IS.valid(whitelist)) return true;

		if(IS.blacklisted(value, blacklist)) return false;
		return IS.whitelisted(value, whitelist);
	}
}
