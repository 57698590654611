import { E_Language, E_LanguageWithRegion } from "../constants/LanguageConstants";

export const M_LanguageISOCodes = {
	[E_Language.ABKHAZIAN]: ["ab", "abk", "abk", "abk"],
	[E_Language.AFAR]: ["aa", "aar", "aar", "aar"],
	[E_Language.AFRIKAANS]: ["af", "afr", "afr", "afr"],
	[E_Language.AKAN]: ["ak", "aka"],
	[E_Language.ALBANIAN]: ["sq", "sqi", "alb"],
	[E_Language.AMHARIC]: ["am", "amh", "amh", "amh"],
	[E_Language.ARABIC]: ["ar", "ara"],
	[E_Language.ARAGONESE]: ["an", "arg", "arg", "arg"],
	[E_Language.ARMENIAN]: ["hy", "hye", "arm", "hye"],
	[E_Language.ASSAMESE]: ["as", "asm", "asm", "asm"],
	[E_Language.AVARIC]: ["av", "ava", "ava", "ava"],
	[E_Language.AVESTAN]: ["ae", "ave", "ave", "ave"],
	[E_Language.AYMARA]: ["ay", "aym"],
	[E_Language.AZERBAIJANI]: ["az", "aze"],
	[E_Language.BAMBARA]: ["bm", "bam", "bam", "bam"],
	[E_Language.BASHKIR]: ["ba", "bak", "bak", "bak"],
	[E_Language.BASQUE]: ["eu", "eus", "baq", "eus"],
	[E_Language.BELARUSIAN]: ["be", "bel", "bel", "bel"],
	[E_Language.BENGALI]: ["bn", "ben", "ben", "ben"],
	[E_Language.BIHARI_LANGUAGES]: ["bh", "bih", "bih"],
	[E_Language.BISLAMA]: ["bi", "bis", "bis", "bis"],
	[E_Language.BOSNIAN]: ["bs", "bos", "bos", "bos"],
	[E_Language.BRETON]: ["br", "bre", "bre", "bre"],
	[E_Language.BULGARIAN]: ["bg", "bul", "bul", "bul"],
	[E_Language.BURMESE]: ["my", "mya", "bur", "mya"],
	[E_Language.CATALAN]: ["ca", "cat", "cat", "cat"],
	[E_Language.CHAMORRO]: ["ch", "cha", "cha", "cha"],
	[E_Language.CHECHEN]: ["ce", "che", "che", "che"],
	[E_Language.CHICHEWA]: ["ny", "nya", "nya", "nya"],
	[E_Language.CHINESE]: ["zh", "zho", "chi"],
	[E_Language.CHUVASH]: ["cv", "chv", "chv", "chv"],
	[E_Language.CORNISH]: ["kw", "cor", "cor", "cor"],
	[E_Language.CORSICAN]: ["co", "cos", "cos", "cos"],
	[E_Language.CREE]: ["cr", "cre", "cre"],
	[E_Language.CROATIAN]: ["hr", "hrv", "hrv", "hrv"],
	[E_Language.CZECH]: ["cs", "ces", "cze", "ces"],
	[E_Language.DANISH]: ["da", "dan", "dan", "dan"],
	[E_Language.DIVEHI]: ["dv", "div", "div", "div"],
	[E_Language.DUTCH]: ["nl", "nld", "dut", "nld"],
	[E_Language.DZONGKHA]: ["dz", "dzo", "dzo", "dzo"],
	[E_Language.ENGLISH]: ["en", "eng", "eng", "eng"],
	[E_Language.ESPERANTO]: ["eo", "epo", "epo", "epo"],
	[E_Language.ESTONIAN]: ["et", "est", "est"],
	[E_Language.EWE]: ["ee", "ewe", "ewe", "ewe"],
	[E_Language.FAROESE]: ["fo", "fao", "fao", "fao"],
	[E_Language.FIJIAN]: ["fj", "fij", "fij", "fij"],
	[E_Language.FINNISH]: ["fi", "fin", "fin", "fin"],
	[E_Language.FRENCH]: ["fr", "fra", "fre", "fra"],
	[E_Language.FULAH]: ["ff", "ful"],
	[E_Language.GALICIAN]: ["gl", "glg", "glg", "glg"],
	[E_Language.GEORGIAN]: ["ka", "kat", "geo", "kat"],
	[E_Language.GERMAN]: ["de", "deu", "ger", "deu"],
	[E_Language.GREEK]: ["el", "ell", "gre", "ell"],
	[E_Language.GUARANI]: ["gn", "grn"],
	[E_Language.GUJARATI]: ["gu", "guj", "guj", "guj"],
	[E_Language.HAITIAN]: ["ht", "hat", "hat", "hat"],
	[E_Language.HAUSA]: ["ha", "hau", "hau", "hau"],
	[E_Language.HEBREW]: ["he", "heb", "heb", "heb"],
	[E_Language.HERERO]: ["hz", "her", "her", "her"],
	[E_Language.HINDI]: ["hi", "hin", "hin", "hin"],
	[E_Language.HIRI_MOTU]: ["ho", "hmo", "hmo", "hmo"],
	[E_Language.HUNGARIAN]: ["hu", "hun", "hun", "hun"],
	[E_Language.INTERLINGUA]: ["ia", "ina", "ina", "ina"],
	[E_Language.INDONESIAN]: ["id", "ind", "ind", "ind"],
	[E_Language.INTERLINGUE]: ["ie", "ile", "ile", "ile"],
	[E_Language.IRISH]: ["ga", "gle", "gle", "gle"],
	[E_Language.IGBO]: ["ig", "ibo", "ibo", "ibo"],
	[E_Language.INUPIAQ]: ["ik", "ipk"],
	[E_Language.IDO]: ["io", "ido", "ido", "ido"],
	[E_Language.ICELANDIC]: ["is", "isl", "ice", "isl"],
	[E_Language.ITALIAN]: ["it", "ita", "ita", "ita"],
	[E_Language.INUKTITUT]: ["iu", "iku"],
	[E_Language.JAPANESE]: ["ja", "jpn", "jpn", "jpn"],
	[E_Language.JAVANESE]: ["jv", "jav", "jav", "jav"],
	[E_Language.KALAALLISUT]: ["kl", "kal", "kal", "kal"],
	[E_Language.KANNADA]: ["kn", "kan", "kan", "kan"],
	[E_Language.KANURI]: ["kr", "kau"],
	[E_Language.KASHMIRI]: ["ks", "kas", "kas", "kas"],
	[E_Language.KAZAKH]: ["kk", "kaz", "kaz", "kaz"],
	[E_Language.CENTRAL_KHMER]: ["km", "khm", "khm", "khm"],
	[E_Language.KIKUYU]: ["ki", "kik", "kik", "kik"],
	[E_Language.KINYARWANDA]: ["rw", "kin", "kin", "kin"],
	[E_Language.KIRGHIZ]: ["ky", "kir", "kir", "kir"],
	[E_Language.KOMI]: ["kv", "kom"],
	[E_Language.KONGO]: ["kg", "kon"],
	[E_Language.KOREAN]: ["ko", "kor"],
	[E_Language.KURDISH]: ["ku", "kur"],
	[E_Language.KUANYAMA]: ["kj", "kua", "kua", "kua"],
	[E_Language.LATIN]: ["la", "lat", "lat", "lat"],
	[E_Language.LUXEMBOURGISH]: ["lb", "ltz", "ltz", "ltz"],
	[E_Language.GANDA]: ["lg", "lug", "lug", "lug"],
	[E_Language.LIMBURGAN]: ["li", "lim", "lim", "lim"],
	[E_Language.LINGALA]: ["ln", "lin", "lin", "lin"],
	[E_Language.LAO]: ["lo", "lao", "lao", "lao"],
	[E_Language.LITHUANIAN]: ["lt", "lit", "lit", "lit"],
	[E_Language.LUBA_KATANGA]: ["lu", "lub", "lub", "lub"],
	[E_Language.LATVIAN]: ["lv", "lav"],
	[E_Language.MANX]: ["gv", "glv", "glv", "glv"],
	[E_Language.MACEDONIAN]: ["mk", "mkd", "mac", "mkd"],
	[E_Language.MALAGASY]: ["mg", "mlg"],
	[E_Language.MALAY]: ["ms", "msa", "may"],
	[E_Language.MALAYALAM]: ["ml", "mal", "mal", "mal"],
	[E_Language.MALTESE]: ["mt", "mlt", "mlt", "mlt"],
	[E_Language.MAORI]: ["mi", "mri", "mao", "mri"],
	[E_Language.MARATHI]: ["mr", "mar", "mar", "mar"],
	[E_Language.MARSHALLESE]: ["mh", "mah", "mah", "mah"],
	[E_Language.MONGOLIAN]: ["mn", "mon", "mon"],
	[E_Language.NAURU]: ["na", "nau", "nau", "nau"],
	[E_Language.NAVAJO]: ["nv", "nav", "nav", "nav"],
	[E_Language.NORTH_NDEBELE]: ["nd", "nde", "nde", "nde"],
	[E_Language.NEPALI]: ["ne", "nep", "nep"],
	[E_Language.NDONGA]: ["ng", "ndo", "ndo", "ndo"],
	[E_Language.NORWEGIAN_BOKMAL]: ["nb", "nob", "nob", "nob"],
	[E_Language.NORWEGIAN_NYNORSK]: ["nn", "nno", "nno", "nno"],
	[E_Language.NORWEGIAN]: ["no", "nor", "nor"],
	[E_Language.SICHUAN_YI]: ["ii", "iii", "iii", "iii"],
	[E_Language.SOUTH_NDEBELE]: ["nr", "nbl", "nbl", "nbl"],
	[E_Language.OCCITAN]: ["oc", "oci", "oci", "oci"],
	[E_Language.OJIBWA]: ["oj", "oji", "oji"],
	[E_Language.OROMO]: ["om", "orm", "orm"],
	[E_Language.ORIYA]: ["or", "ori", "ori"],
	[E_Language.OSSETIAN]: ["os", "oss", "oss", "oss"],
	[E_Language.PUNJABI]: ["pa", "pan", "pan", "pan"],
	[E_Language.PALI]: ["pi", "pli", "pli", "pli"],
	[E_Language.PERSIAN]: ["fa", "fas", "per"],
	[E_Language.POLISH]: ["pl", "pol", "pol", "pol"],
	[E_Language.PASHTO]: ["ps", "pus", "pus"],
	[E_Language.PORTUGUESE]: ["pt", "por", "por", "por"],
	[E_Language.QUECHUA]: ["qu", "que", "que"],
	[E_Language.ROMANSH]: ["rm", "roh", "roh", "roh"],
	[E_Language.RUNDI]: ["rn", "run", "run", "run"],
	[E_Language.ROMANIAN]: ["ro", "ron", "rum", "ron"],
	[E_Language.RUSSIAN]: ["ru", "rus", "rus", "rus"],
	[E_Language.SANSKRIT]: ["sa", "san", "san", "san"],
	[E_Language.SARDINIAN]: ["sc", "srd", "srd"],
	[E_Language.SINDHI]: ["sd", "snd", "snd", "snd"],
	[E_Language.NORTHERN_SAMI]: ["se", "sme", "sme", "sme"],
	[E_Language.SAMOAN]: ["sm", "smo", "smo", "smo"],
	[E_Language.SANGO]: ["sg", "sag", "sag", "sag"],
	[E_Language.SERBIAN]: ["sr", "srp", "srp", "srp"],
	[E_Language.GAELIC]: ["gd", "gla", "gla", "gla"],
	[E_Language.SHONA]: ["sn", "sna", "sna", "sna"],
	[E_Language.SINHALA]: ["si", "sin", "sin", "sin"],
	[E_Language.SLOVAK]: ["sk", "slk", "slo", "slk"],
	[E_Language.SLOVENIAN]: ["sl", "slv", "slv", "slv"],
	[E_Language.SOMALI]: ["so", "som", "som", "som"],
	[E_Language.SOUTHERN_SOTHO]: ["st", "sot", "sot", "sot"],
	[E_Language.SPANISH]: ["es", "spa", "spa", "spa"],
	[E_Language.SUNDANESE]: ["su", "sun", "sun", "sun"],
	[E_Language.SWAHILI]: ["sw", "swa", "swa"],
	[E_Language.SWATI]: ["ss", "ssw", "ssw", "ssw"],
	[E_Language.SWEDISH]: ["sv", "swe", "swe", "swe"],
	[E_Language.TAMIL]: ["ta", "tam", "tam", "tam"],
	[E_Language.TELUGU]: ["te", "tel", "tel", "tel"],
	[E_Language.TAJIK]: ["tg", "tgk", "tgk", "tgk"],
	[E_Language.THAI]: ["th", "tha", "tha", "tha"],
	[E_Language.TIGRINYA]: ["ti", "tir", "tir", "tir"],
	[E_Language.TIBETAN]: ["bo", "bod", "tib", "bod"],
	[E_Language.TURKMEN]: ["tk", "tuk", "tuk", "tuk"],
	[E_Language.TAGALOG]: ["tl", "tgl", "tgl", "tgl"],
	[E_Language.TSWANA]: ["tn", "tsn", "tsn", "tsn"],
	[E_Language.TONGA]: ["to", "ton", "ton", "ton"],
	[E_Language.TURKISH]: ["tr", "tur", "tur", "tur"],
	[E_Language.TSONGA]: ["ts", "tso", "tso", "tso"],
	[E_Language.TATAR]: ["tt", "tat", "tat", "tat"],
	[E_Language.TWI]: ["tw", "twi", "twi", "twi"],
	[E_Language.TAHITIAN]: ["ty", "tah", "tah", "tah"],
	[E_Language.UIGHUR]: ["ug", "uig", "uig", "uig"],
	[E_Language.UKRAINIAN]: ["uk", "ukr", "ukr", "ukr"],
	[E_Language.URDU]: ["ur", "urd", "urd", "urd"],
	[E_Language.UZBEK]: ["uz", "uzb", "uzb"],
	[E_Language.VENDA]: ["ve", "ven", "ven", "ven"],
	[E_Language.VIETNAMESE]: ["vi", "vie", "vie", "vie"],
	[E_Language.VOLAPUK]: ["vo", "vol", "vol", "vol"],
	[E_Language.WALLOON]: ["wa", "wln", "wln", "wln"],
	[E_Language.WELSH]: ["cy", "cym", "wel", "cym"],
	[E_Language.WOLOF]: ["wo", "wol", "wol", "wol"],
	[E_Language.WESTERN_FRISIAN]: ["fy", "fry", "fry", "fry"],
	[E_Language.XHOSA]: ["xh", "xho", "xho", "xho"],
	[E_Language.YIDDISH]: ["yi", "yid", "yid"],
	[E_Language.YORUBA]: ["yo", "yor", "yor", "yor"],
	[E_Language.ZHUANG]: ["za", "zha", "zha"],
	[E_Language.ZULU]: ["zu", "zul", "zul", "zul"],
};

/**
 * Language ISO codes with regions model
 * ---
 * @type {Object<E_LanguageWithRegion, string>}
 * @see https://www.andiamo.co.uk/resources/iso-language-codes/
 */
export const M_LanguageISOCodesWithRegion = {
	[E_LanguageWithRegion.ARABIC_ALGERIA]: "ar-DZ",
	[E_LanguageWithRegion.ARABIC_BAHRAIN]: "ar-BH",
	[E_LanguageWithRegion.ARABIC_EGYPT]: "ar-EG",
	[E_LanguageWithRegion.ARABIC_IRAQ]: "ar-IQ",
	[E_LanguageWithRegion.ARABIC_JORDAN]: "ar-JO",
	[E_LanguageWithRegion.ARABIC_KUWAIT]: "ar-KW",
	[E_LanguageWithRegion.ARABIC_LEBANON]: "ar-LB",
	[E_LanguageWithRegion.ARABIC_LIBYA]: "ar-LY",
	[E_LanguageWithRegion.ARABIC_MOROCCO]: "ar-MA",
	[E_LanguageWithRegion.ARABIC_OMAN]: "ar-OM",
	[E_LanguageWithRegion.ARABIC_QATAR]: "ar-QA",
	[E_LanguageWithRegion.ARABIC_SAUDI_ARABIA]: "ar-SA",
	[E_LanguageWithRegion.ARABIC_SYRIA]: "ar-SY",
	[E_LanguageWithRegion.ARABIC_TUNISIA]: "ar-TN",
	[E_LanguageWithRegion.ARABIC_U_A_E]: "ar-AE",
	[E_LanguageWithRegion.ARABIC_YEMEN]: "ar-YE",
	[E_LanguageWithRegion.CHINESE_HONG_KONG]: "zh-HK",
	[E_LanguageWithRegion.CHINESE_PRC]: "zh-CN",
	[E_LanguageWithRegion.CHINESE_SINGAPORE]: "zh-SG",
	[E_LanguageWithRegion.CHINESE_TAIWAN]: "zh-TW",
	[E_LanguageWithRegion.DUTCH_BELGIUM]: "nl-BE",
	[E_LanguageWithRegion.ENGLISH_AUSTRALIA]: "en-AU",
	[E_LanguageWithRegion.ENGLISH_BELIZE]: "en-BZ",
	[E_LanguageWithRegion.ENGLISH_CANADA]: "en-CA",
	[E_LanguageWithRegion.ENGLISH_IRELAND]: "en-IE",
	[E_LanguageWithRegion.ENGLISH_JAMAICA]: "en-JM",
	[E_LanguageWithRegion.ENGLISH_NEW_ZEALAND]: "en-NZ",
	[E_LanguageWithRegion.ENGLISH_SOUTH_AFRICA]: "en-ZA",
	[E_LanguageWithRegion.ENGLISH_TRINIDAD]: "en-TT",
	[E_LanguageWithRegion.ENGLISH_UNITED_KINGDOM]: "en-GB",
	[E_LanguageWithRegion.ENGLISH_UNITED_STATES]: "en-US",
	[E_LanguageWithRegion.FRENCH_BELGIUM]: "fr-BE",
	[E_LanguageWithRegion.FRENCH_CANADA]: "fr-CA",
	[E_LanguageWithRegion.FRENCH_LUXEMBOURG]: "fr-LU",
	[E_LanguageWithRegion.FRENCH_SWITZERLAND]: "fr-CH",
	[E_LanguageWithRegion.GERMAN_AUSTRIA]: "de-AT",
	[E_LanguageWithRegion.GERMAN_LIECHTENSTEIN]: "de-LI",
	[E_LanguageWithRegion.GERMAN_LUXEMBOURG]: "de-LU",
	[E_LanguageWithRegion.GERMAN_SWITZERLAND]: "de-CH",
	[E_LanguageWithRegion.ITALIAN_SWITZERLAND]: "it-CH",
	[E_LanguageWithRegion.PORTUGUESE_BRAZIL]: "pt-BR",
	[E_LanguageWithRegion.ROMANIAN_REPUBLIC_OF_MOLDOVA]: "ro-MD",
	[E_LanguageWithRegion.RUSSIAN_REPUBLIC_OF_MOLDOVA]: "ru-MD",
	[E_LanguageWithRegion.SPANISH_ARGENTINA]: "es-AR",
	[E_LanguageWithRegion.SPANISH_BOLIVIA]: "es-BO",
	[E_LanguageWithRegion.SPANISH_CHILE]: "es-CL",
	[E_LanguageWithRegion.SPANISH_COLOMBIA]: "es-CO",
	[E_LanguageWithRegion.SPANISH_COSTA_RICA]: "es-CR",
	[E_LanguageWithRegion.SPANISH_DOMINICAN_REPUBLIC]: "es-DO",
	[E_LanguageWithRegion.SPANISH_ECUADOR]: "es-EC",
	[E_LanguageWithRegion.SPANISH_EL_SALVADOR]: "es-SV",
	[E_LanguageWithRegion.SPANISH_GUATEMALA]: "es-GT",
	[E_LanguageWithRegion.SPANISH_HONDURAS]: "es-HN",
	[E_LanguageWithRegion.SPANISH_MEXICO]: "es-MX",
	[E_LanguageWithRegion.SPANISH_NICARAGUA]: "es-NI",
	[E_LanguageWithRegion.SPANISH_PANAMA]: "es-PA",
	[E_LanguageWithRegion.SPANISH_PARAGUAY]: "es-PY",
	[E_LanguageWithRegion.SPANISH_PERU]: "es-PE",
	[E_LanguageWithRegion.SPANISH_PUERTO_RICO]: "es-PR",
	[E_LanguageWithRegion.SPANISH_URUGUAY]: "es-UY",
	[E_LanguageWithRegion.SPANISH_VENEZUELA]: "es-VE",
	[E_LanguageWithRegion.SWEDISH_FINLAND]: "sv-FI",
};

export const M_LanguageAllISOCodes = {
	...M_LanguageISOCodes,
	...M_LanguageISOCodesWithRegion,
};
