/**
 * Contact model
 * ---
 * @type {{
 *  address: string,
 *  phone: string,
 *  taxID: string,
 *  companyName: string,
 *  name: string,
 *  registrationID: string,
 *  email: string
 * }}
 */
export const M_Contact = {
	name: undefined,
	address: undefined,
	email: undefined,
	phone: undefined,
	companyName: undefined,
	taxID: undefined,
	registrationID: undefined,
};
