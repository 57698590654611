import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import Fa from "./Fa";
import Tippy from "./Tippy";

class TooltipIcon extends Component {
	render({className, style, children, tippyProps, iconProps}, {}) {
		return (
			<Tippy
				content={children}
				className={combineClasses("tooltip-icon", className)}
				{...tippyProps}
			>
				<Fa
					icon={"info-circle"}
					style={style}
					{...iconProps}
				/>
			</Tippy>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any.isRequired,

			tippyProps: PropTypes.object,
			iconProps: PropTypes.object,
		}
	}
}

export default TooltipIcon;
