import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import Loader from "../../shared/Loader";
import { projectServices } from "../../../services/project-services";
import { resolvePolymorphVar } from "@green24/npm-javascript-utils";
import { AppUtils } from "../../../utils/AppUtils";
import withSiteSettingsContext from "../settings/hoc/withSiteSettingsContext";
import { getApi } from "../../../services/api-connector";
import { withLocalizationContext } from "../../components";

export const ProjectContext = createContext();

class ProjectLoader extends Component {
	render({children, projectID, localizationContext, showLoading, showError, siteSettingsContext}, {}) {
		return (
			<Loader
				showLoading={showLoading}
				showError={showError}
				loadingProps={{
					className: "full-page",
					accent: "primary",
					title: "loading",
				}}
				params={{
					projectID,
					currency: siteSettingsContext.currency,
					locale: localizationContext.activeLanguage.ISO639_1
				}}
				onService={({projectID, locale, currency}) => projectServices.getItem(projectID, locale, currency)}
			>
				{({data, loading, error}) => {
					if(data && AppUtils.hasWindow) {
						document.title = localizationContext.findTerm("app.title").resolveVariables({
							project: data.name,
						}).toString();

						if(data.links.icon) {
							let link = document.querySelector("link[rel~='icon']");
							if(!link) {
								link = document.createElement('link');

								link.rel = 'icon';

								document.head.appendChild(link);
							}
							link.href = getApi() + data.links.icon;
						}
					}

					const projectContextData = {
					states: {
						loading,
						error,
					},
					project: data,
					refresh: (locale = localizationContext.activeLanguage.ISO639_1) => this.refresh(locale),
				};

				return (
					<ProjectContext.Provider value={projectContextData}>
						{resolvePolymorphVar(
							children,
							{
								function: f => f(projectContextData),
							},
							() => children,
							true
						)}
					</ProjectContext.Provider>
				)
				}}
			</Loader>
		)
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			projectID: PropTypes.number,

			//withLocalizationContext
			localizationContext: PropTypes.object,
			//withSiteSettingsContext
			siteSettingsContext: PropTypes.object,
		}
	}
}

export default withLocalizationContext(withSiteSettingsContext(ProjectLoader));
