import { StackNotifier } from "../StackNotifier";
import { E_Language } from "../../models/constants/LanguageConstants";
import { ObjectUtils } from "../ObjectUtils";
import { Localizer } from "./Localizer";
import { IS } from "../IS";
import { RegionalLanguage } from "./RegionalLanguage";
import { M_L10N_DefaultCustomization } from "../../models/L10N/Models_L10N";
import { LocalizedTerm } from "./LocalizedTerm";
import { ArrayUtils } from "../ArrayUtils";

export class Internationalizer {
	/**
	 * @constructor
	 * @param {string|E_Language} activeLanguage
	 * @param {string|E_Language} fallbackLanguage
	 * @param {M_L10N_DefaultCustomization} customization
	 */
	constructor(activeLanguage, fallbackLanguage = "en", customization) {
		this._stackNofier = new StackNotifier();
		this._locales = {};

		this.customization = customization;

		this.setActiveLanguage(activeLanguage);
		this.setFallbackLanguage(fallbackLanguage);
	}

	get customization() {
		return this._customization;
	}

	set customization(value) {
		this._customization = {
			...Internationalizer.model.defaultCustomization,
			localizerClass: Localizer,
			termClass: LocalizedTerm,
			...value
		};
	}

	/**
	 * Model
	 * ---
	 * @return {{
	 *  defaultCustomization: M_L10N_DefaultCustomization,
	 * }}
	 */
	static get model() {
		return {
			defaultCustomization: M_L10N_DefaultCustomization,
		}
	}

	/**
	 * Enum
	 * ---
	 * @return {{
	 *  language: E_Language,
	 * }}
	 */
	static get enum() {
		return {
			language: E_Language,
		}
	}

	/**
	 * Active language
	 * ---
	 * @return {RegionalLanguage}
	 */
	get activeLanguage() {
		return this._activeLanguage;
	}

	/**
	 * Fallback language
	 * ---
	 * @return {RegionalLanguage}
	 */
	get fallbackLanguage() {
		return this._fallbackLanguage;
	}

	/**
	 * Intl
	 * ---
	 * @return {{
	 *  PluralRules: (function(PluralRulesOptions, boolean): Intl.PluralRules),
	 *  RelativeTimeFormat: (function(*=, boolean): Intl.RelativeTimeFormat),
	 *  NumberFormat: (function(NumberFormatOptions, boolean): Intl.NumberFormat),
	 *  DateTimeFormat: (function(DateTimeFormatOptions, boolean): Intl.DateTimeFormat),
	 *  Collator: (function(CollatorOptions, boolean): Intl.Collator)
	 * }}
	 */
	get Intl() {
		return {
			NumberFormat: (options, allowRegionalCode = true) => this.getNumberFormat(options, allowRegionalCode),
			DateTimeFormat: (options, allowRegionalCode = true) => this.getDateTimeFormat(options, allowRegionalCode),
			Collator: (options, allowRegionalCode = true) => this.getCollator(options, allowRegionalCode),
			PluralRules: (options, allowRegionalCode = true) => this.getPluralRules(options, allowRegionalCode),
			RelativeTimeFormat: (options, allowRegionalCode = true) => this.getRelativeTimeFormat(options, allowRegionalCode),
		}
	}

	/**
	 * Add locale
	 * ---
	 * @param {E_Language} language
	 * @param {LocaleData} localeData
	 * @param {boolean} suppressNotify
	 */
	addLocale(language, localeData, suppressNotify = false) {
		let localizer = this._processLocale(localeData);
		localizer.setInternationalizer(this);

		this._locales[language] = localizer;

		!suppressNotify && this._stackNofier.notify("locales-changed", this._locales);
	}

	/**
	 * Add locales
	 * ---
	 * Adds multiple locales (calling the "locales-changed" event only once as well)
	 * @param {Object<E_Language, Localizer>} locales
	 * @see Internationalizer.addLocale
	 */
	addLocales(locales) {
		ObjectUtils.forEach(locales, (language, localeData) => {
			this.addLocale(language, localeData, true);
		});

		this._stackNofier.notify("locales-changed", this._locales);
	}

	addSharedLocale(data) {
		this.addLocale(E_Language.__SHARED__, {
			regions: {
				[E_Language.__SHARED__]: data,
			},
			enumerable: false,
		});
	}

	/**
	 * Remove locale
	 * ---
	 * @param {E_Language} language
	 */
	removeLocale(language) {
		delete this._locales[language];

		this._stackNofier.notify("locales-changed", this._locales);
	}

	/**
	 * Get locales key
	 * ---
	 * @return {E_Language[]}
	 */
	getLocalesKeys() {
		return Object.keys(this._locales);
	}

	/**
	 * Get locale
	 * ---
	 * @param {E_Language} language
	 * @return {Localizer}
	 */
	getLocale(language) {
		return this._locales[language];
	}

	/**
	 * On locales changed
	 * ---
	 * @param {Function} callback
	 * @param {boolean} override
	 * @return {StackNotifier}
	 */
	onLocalesChanged(callback, override = true) {
		return this._stackNofier.on("locales-changed", callback, override);
	}

	/**
	 * Set active language
	 * ---
	 * @param {E_Language|string} language
	 * @param {boolean} preventNotify
	 */
	setActiveLanguage(language, preventNotify = false) {
		this._activeLanguage = language ? new RegionalLanguage(language) : undefined;

		!preventNotify && this._stackNofier.notify("active-language-changed", this.activeLanguage);
	}

	/**
	 * Change active language
	 * ---
	 * @borrows setActiveLanguage
	 */
	changeActiveLanguage(language, preventNotify = false) {
		return this.setActiveLanguage(language, preventNotify);
	}

	/**
	 * Set fallback language
	 * ---
	 * @param {E_Language} languageKey
	 */
	setFallbackLanguage(languageKey) {
		this._fallbackLanguage = languageKey ? new RegionalLanguage(languageKey) : undefined;
	}

	/**
	 * On active language change
	 * ---
	 * @param {Function} callback
	 * @param {boolean} override
	 * @return {StackNotifier}
	 */
	onActiveLanguageChange(callback, override = false) {
		return this._stackNofier.on("active-language-changed", callback, override);
	}

	/**
	 * Get locale for region
	 * @param {string} regionISOCode
	 * @return {Localizer}
	 */
	getLocaleForRegion(regionISOCode) {
		return ObjectUtils.find(this._locales, (languageCode, localizer) => localizer.hasRelevantRegion(regionISOCode));
	}

	/**
	 * Get all regions
	 * ---
	 * Returns a list of all regional codes (except non-enumerable ones)
	 * @return {string[]}
	 */
	getAllRegions(ignoreEnumerableValue = false) {
		return ObjectUtils.map(
			this._locales,
			/**
			 *  @param {string} _
			 *  @param {Localizer} localizer
			 *  */
			(_, localizer) => {
				if(ignoreEnumerableValue) {
					return Object.keys(localizer.regions);
				}

				return localizer.enumerable ? Object.keys(localizer.regions) : [];
			}
		).flat(1);
	}

	/**
	 * Find term
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @param {boolean} searchInAlternatives
	 * @param {boolean} searchInFallback
	 * @return {LocalizedTerm}
	 */
	findTerm(termKey, fallback = undefined, searchInAlternatives = true, searchInFallback = true) {
		let regionISOCode = this.activeLanguage.ISOCodeWithRegion.toLowerCase();

		//Has specified region
		if(termKey.includes(':')) {
			[regionISOCode, termKey] = termKey.split(':');
			regionISOCode = regionISOCode.toLowerCase();

			let localizer = this.getLocaleForRegion(regionISOCode);
			if(localizer) {
				return localizer.findTermInRegionByCode(termKey, regionISOCode, fallback);
			}

			return this._getEmptyTerm(termKey, fallback);
		}

		let localizer = this.getLocaleForRegion(regionISOCode);
		if(localizer) {
			let term = localizer.findTerm(regionISOCode, termKey, fallback, searchInAlternatives, searchInFallback);
			if(term.valid) {
				return term;
			}
		}

		let sharedLocalizer = this.getLocaleForRegion(E_Language.__SHARED__);
		if(sharedLocalizer) {
			let term = sharedLocalizer.findTerm(E_Language.__SHARED__, termKey, fallback, false, false);
			if(term.valid) {
				return term;
			}
		}

		return this._getEmptyTerm(termKey, fallback);
	}

	/**
	 * Find term in language
	 * ---
	 * @param {E_Language} languageKey
	 * @param {string} termKey
	 * @param {*} fallback
	 * @param {boolean} searchInAlternatives
	 * @param {boolean} searchInFallback
	 * @return {LocalizedTerm}
	 */
	findTermInLanguage(languageKey, termKey, fallback = undefined, searchInAlternatives = true, searchInFallback = true) {
		if(this._locales && this.getLocale(languageKey)) {
			return this.getLocale(languageKey).findTerm(new RegionalLanguage(languageKey).ISO639_1, termKey, fallback, searchInAlternatives, searchInFallback);
		}

		return this._getEmptyTerm(termKey, fallback);
	}

	/**
	 * Find term with plural rule
	 * ---
	 * @param {string} termKey
	 * @param {number} amount
	 * @param {PluralRulesOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {LocalizedTerm}
	 */
	findTermWithPluralRule(termKey, amount, options, allowRegionalCode = true) {
		/**
		 * @type {Intl.LDMLPluralRule}
		 */
		let pluralRule = this.getPluralRules(options, allowRegionalCode).select(amount);
		return this.findTerm( (pluralRule ? pluralRule + this.customization.pluralRuleJoin : '') + termKey);
	}

	/**
	 * Find term in fallback language
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @param {boolean} searchInAlternatives
	 * @return {LocalizedTerm}
	 */
	findTermInFallbackLanguage(termKey, fallback = undefined, searchInAlternatives = true) {
		if(this.fallbackLanguage) {
			return this.findTermInLanguage(this.fallbackLanguage.languageKey, termKey, fallback, searchInAlternatives, false);
		}

		return this._getEmptyTerm(termKey, fallback);
	}

	/**
	 * Get number format
	 * ---
	 * Returns Intl.NumberFormat instance with already resolved locales
	 * @param {NumberFormatOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {Intl.NumberFormat}
	 */
	getNumberFormat(options, allowRegionalCode = true) {
		return new Intl.NumberFormat(this._getLocalesForFormatters(allowRegionalCode), options);
	}

	/**
	 * Get date time format
	 * ---
	 * Returns Intl.DateTimeFormat instance with already resolved locales
	 * @param {DateTimeFormatOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {Intl.DateTimeFormat}
	 */
	getDateTimeFormat(options, allowRegionalCode = true) {
		return new Intl.DateTimeFormat(this._getLocalesForFormatters(allowRegionalCode), options);
	}

	/**
	 * Get Collator
	 * ---
	 * Returns Intl.Collator instance with already resolved locales
	 * @param {CollatorOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {Intl.Collator}
	 */
	getCollator(options, allowRegionalCode = true) {
		return new Intl.Collator(this._getLocalesForFormatters(allowRegionalCode), options);
	}

	/**
	 * Get plural rules
	 * ---
	 * Returns Intl.PluralRules instance with already resolved locales
	 * @param {PluralRulesOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {Intl.PluralRules}
	 */
	getPluralRules(options, allowRegionalCode = true) {
		return new Intl.PluralRules(this._getLocalesForFormatters(allowRegionalCode), options);
	}

	/**
	 * Get relative time format
	 * ---
	 * Returns Intl.RelativeTimeFormat instance with already resolved locales
	 * @param {RelativeTimeFormatOptions} options
	 * @param {boolean} allowRegionalCode
	 * @return {Intl.RelativeTimeFormat}
	 */
	getRelativeTimeFormat(options, allowRegionalCode = true) {
		return new Intl.RelativeTimeFormat(this._getLocalesForFormatters(allowRegionalCode), options);
	}

	/**
	 * @private
	 * Get locales for formatters
	 * ---
	 * @param {boolean} allowRegionalCode
	 * @return {string[]}
	 */
	_getLocalesForFormatters(allowRegionalCode) {
		let code = (allowRegionalCode ? this.activeLanguage.ISOCodeWithRegion : this.activeLanguage.ISO639_1);
		return code ? [code, "en"] : ["en"];
	}

	/**
	 * @private
	 * Process locale
	 * ---
	 * @param {Object|Localizer} localeData
	 * @return {Localizer}
	 */
	_processLocale(localeData) {
		if(localeData instanceof Localizer) return localeData;

		if(IS.object(localeData)) {
			//Initialize the Localizer with {regions: localeData} as a default, that can be overridden when the localeData.regions is valid
			return new this.customization.localizerClass({
				regions: localeData,
				enumerable: true,
				...localeData,
			}, this, this.customization);
		}
		else {
			console.error("Unknown localeData format for the Internationalizer\n", localeData, "\nexpected an Object or a Localizer instance");
			throw TypeError("Provided localData are not compatible");
		}
	}

	/**
	 * @private
	 * Get empty term
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	_getEmptyTerm(termKey, fallback) {
		return new this.customization.termClass({
			termKey,
			fallback,
		}, undefined, this.customization);
	}

	/**
	 * Find allowed language
	 * ---
	 * Returns the first regional language that is allowed by allowedRegions
	 * @param {string[]} languages List of possible regional languages
	 * @param {string[]} allowedRegions List of allowed regional languages
	 * @param {string} fallback Fallback regional language
	 * @param {boolean} matchRoot If true, only the root language can match to be allowed.
	 * @return {string}
	 */
	static findAllowedLanguage(languages, allowedRegions, fallback = "en", matchRoot = true) {
		allowedRegions = allowedRegions.map(r => r.toLowerCase());

		return ArrayUtils.findAndRetrieve(languages, (language) => {
			const languageIsInAllowed = (
				allowedRegions.includes(language.toLowerCase())
				||
				//Test if there is a language will all regions allowed e.g. "en-*" which can mean "en", "en-US", "en-GB", ...
				allowedRegions.includes(language.toLowerCase() + "-*")
			);

			let isAllowed = matchRoot ? (
				languageIsInAllowed
				||
				allowedRegions.some(region => {
					return RegionalLanguage.hasSameRootLanguage(region, language);
				})
			) : languageIsInAllowed;

			if(isAllowed) {
				if(languageIsInAllowed) return language;

				return matchRoot ? RegionalLanguage.getRootLanguageCodeFromRegionalCode(language).toLowerCase() : undefined;
			}

			// === === === === === === === === === === ===
			//   Special handlers for similar languages
			// === === === === === === === === === === ===

			if(language == "sk" && allowedRegions.includes("cs")) return "cs";
		}) || fallback;
	}

	/**
	 * Resolve language from navigator
	 * ---
	 * Returns an allowed language (with region if the region is allowed)
	 * @param {string[]} allowedRegions
	 * @param {string} fallback
	 * @param {boolean} matchRoot
	 * @return {string}
	 */
	static resolveLanguageFromNavigator(allowedRegions, fallback = "en", matchRoot = true) {
		let languages = window.navigator.languages || [window.navigator.language];

		return this.findAllowedLanguage(languages, allowedRegions, fallback, matchRoot);
	}
}
