import czechFlag from "../media/icons/czech-flag.svg";
import englishFlag from "../media/icons/english-flag.svg";
import nikolaIcon from "../media/icons/nikola-icon.svg";
import construction from "../media/icons/fontawesome/light/construction.svg";
import telescope from "../media/icons/fontawesome/light/telescope.svg";
import stopwatch from "../media/icons/fontawesome/light/stopwatch.svg";
import server from "../media/icons/fontawesome/light/server.svg";
import shoppingCart from "../media/icons/fontawesome/light/shopping-cart.svg";
import infoCircle from "../media/icons/fontawesome/solid/info-circle.svg";
import compress from "../media/icons/fontawesome/light/compress.svg";
import expand from "../media/icons/fontawesome/light/expand.svg";
import exclamationTriangle from "../media/icons/fontawesome/light/exclamation-triangle.svg";
import play from "../media/icons/fontawesome/light/play.svg";
import pause from "../media/icons/fontawesome/light/pause.svg";
import imagePolaroid from "../media/icons/fontawesome/light/image-polaroid.svg";
import plus from "../media/icons/fontawesome/light/plus.svg";
import minus from "../media/icons/fontawesome/light/minus.svg";
import envelope from "../media/icons/fontawesome/light/envelope.svg";
import syncAlt from "../media/icons/fontawesome/light/sync-alt.svg";
import fileDownload from "../media/icons/fontawesome/light/file-download.svg";
import caretDown from "../media/icons/fontawesome/light/caret-down.svg";
import undoAlt from "../media/icons/fontawesome/light/undo-alt.svg";
import chevronLeft from "../media/icons/fontawesome/light/chevron-left.svg";
import chevronRight from "../media/icons/fontawesome/light/chevron-right.svg";
import arrowAltLeft from "../media/icons/fontawesome/light/arrow-alt-left.svg";
import cog from "../media/icons/fontawesome/light/cog.svg";
import times from "../media/icons/fontawesome/light/times.svg";

export const M_Icons = {
	"czech-flag": czechFlag,
	"english-flag": englishFlag,
	"nikola-icon": nikolaIcon,
	construction,
	telescope,
	stopwatch,
	server,
	"shopping-cart": shoppingCart,
	"info-circle": infoCircle,
	"compress": compress,
	"expand": expand,
	"exclamation-triangle": exclamationTriangle,
	play,
	pause,
	"image-polaroid": imagePolaroid,
	plus,
	minus,
	envelope,
	"sync-alt": syncAlt,
	"file-download": fileDownload,
	"caret-down": caretDown,
	"undo-alt": undoAlt,
	"chevron-left": chevronLeft,
	"chevron-right": chevronRight,
	"arrow-alt-left": arrowAltLeft,
	cog,
	times,
};
