import { Component } from "preact";
import PropTypes from "prop-types";
import { E_CostUnit } from "../../models/constants/Enums_Tariff";
import { Interpret, Price } from "../components";

class FeatureCost extends Component {
	render({feature, showSignum}, {}) {
		if(feature.costIndividual) {
			if(feature.cost) {
				return <Interpret id={"cost.from"} params={{
					cost: (
						<Price value={feature.cost}/>
					),
				}}/>
			}
			return <Interpret id={"cost.individual"}/>;
		}

		const signum = showSignum && (feature.cost > 0 ? '+' : '');
		if(feature.costUnit == E_CostUnit.CURRENCY) {
			return (
				<>
					{signum}
					<Price value={feature.cost}/>
				</>
			);
		}

		//FFS: Replace with <ValueWithUnit/>
		return `${signum}${feature.cost}%`;
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			feature: PropTypes.object.isRequired,

			showSignum: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			showSignum: true,
		}
	}
}

export default FeatureCost;
