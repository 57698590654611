import { E_APIMethod } from "./constants/APIConnectorConstants";

export const M_APIConnector_RequestOptions = {
	/**
	 * Server API URL
	 */
	apiURL: null,
	/**
	 * Method API URL
	 */
	url: null,
	/**
	 * Method type
	 * @type E_APIMethod
	 * @see E_APIMethod
	 */
	method: E_APIMethod.GET,
	/**
	 * Request headers
	 * @see RequestHeaders
	 */
	headers: undefined,
	/**
	 * Request body
	 */
	body: null,
	/**
	 * Request file (present only in XHR requests)
	 */
	file: null,
	/**
	 * Use token
	 */
	useToken: true,
	/**
	 * Token
	 */
	token: null,
	/**
	 * Response handler
	 * @see ResponseHandlers
	 */
	responseHandler: null,

	/**
	 * Content disposition form data name
	 */
	formDataName: "file",

	/**
	 * Timeout
	 * @type {number}
	 */
	timeout: -1,

	/**
	 * Response type
	 * @type ''|"json"|"blob"|"arrayBuffer"|"document"|"text"
	 */
	responseType: null,

	/**
	 * Batch
	 * ---
	 * If true, will use an integrated bathing system that will bundle multiple requests of the same type into a single request,
	 * reducing data traffic and prevents possible spam.
	 * @type {boolean}
	 */
	batch: false,
};
