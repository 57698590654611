import React from "react";
import PropTypes from "prop-types";
import { combineClasses, IS } from "@green24/npm-javascript-utils";
import Spinner from "./Spinner";
import { ComponentUtils } from "@green24/npm-preact-utils";
import { Interpret } from "../components";
import Progress from "./Progress";

class Loading extends React.Component {
	render({className, style, title, block, progress, accent}) {
		const hasProgress = IS.number(progress);

		return (
			<section
				className={combineClasses(
					"loading",
					block && "block",
					accent,
					className
				)}
				style={style}
			>
				<Spinner block={true} className={hasProgress ? "no-animation" : ''}/>

				<p>
					{ComponentUtils.resolvePolymorphProp(
						title,
						{
							component: c => c,
							function: f => f(),
							string: s => <Interpret id={s}/>
						}
					)}
				</p>

				{
					hasProgress &&
					<Progress progress={progress}/>
				}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			title: PropTypes.any,
			block: PropTypes.bool,

			accent: PropTypes.oneOf(["primary", "error", "success", "warning"]),

			progress: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			block: false,
		}
	}
}

export default Loading;
