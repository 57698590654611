import { Component } from "preact";
import PropTypes from "prop-types";
import { Calc, combineClasses, EventListeners } from "@green24/npm-javascript-utils";
import withInput from "./hoc/withInput";
import ButtonsConstructor from "./Button/ButtonsConstructor";

class NumberSpinner extends Component {
	constructor() {
		super();

		this._waitTimeout = null;
		this._repeatInterval = null;

		this._eventListeners = new EventListeners();
		this._eventListeners.add(window, "mouseup", () => this._stopLoop());
	}

	componentWillUnmount() {
		this._stopLoop();
		this._eventListeners.clear();
	}

	render({className, style, value, prefix, suffix, min, max}, {}) {
		return (
			<section className={combineClasses("number-spinner", className)} style={style}>
				<ButtonsConstructor buttons={[
					{
						className: "sub",
						icon: "minus",
						disabled: value <= min,
						buttonProps: {
							onMouseDown: () => this._startLoop(-1),
							onTouchStart: (e) => {
								e.preventDefault();
								this._startLoop(-1)
							},
							onTouchEnd: () => this._stopLoop(),
						}
					},
					(
						<span className={"value-container"}>
							{prefix}

							<span className={"value"}>
								{value}
							</span>

							{suffix}
						</span>
					),
					{
						className: "add",
						icon: "plus",
						disabled: value >= max,
						buttonProps: {
							onMouseDown: () => this._startLoop(1),
							onTouchStart: (e) => {
								e.preventDefault();
								this._startLoop(1)
							},
							onTouchEnd: () => this._stopLoop(),
						}
					}
				]} sharedProps={{className: "round"}}/>
			</section>
		);
	}

	_modifyValue(direction) {
		const {value, onModify, min, max} = this.props;

		let newValue = Calc.clamp(value + direction, min, max);

		onModify(newValue, undefined, true);
	}

	_startLoop(direction) {
		this._modifyValue(direction);

		this._stopLoop();
		this._waitTimeout = setTimeout(() => {
			clearInterval(this._repeatInterval);
			this._repeatInterval = setInterval(() => {
				this._modifyValue(direction);
			}, 100);
		}, 500);
	}

	_stopLoop() {
		clearTimeout(this._waitTimeout);
		clearInterval(this._repeatInterval);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			min: PropTypes.number,
			max: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			min: undefined,
			max: undefined,
		}
	}
}

export default withInput(NumberSpinner);
