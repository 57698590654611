import { resolvePolymorphVar } from "../functions/generic";
import { IS } from "./IS";
import { ArrayUtils } from "./ArrayUtils";

/**
 * Stack notifier
 * ---
 * Utility class that manages stackable event listeners (similar to the .then() in Promise but much more flexible)
 *
 * ```
 *  //Example
 *
 *  let n = new StackNotifier();
 *  n.on("test", (a) => ...).on("test2", (b) => ...).on(["test", "test2"], (c) => ...);
 *  n.notify("test", {}); //This will trigger first and last callback since those two are listening for the "test" event
 * ```
 */
export class StackNotifier {
	constructor() {
		this._stackQueue = [];
	}

	/**
	 * On
	 * ---
	 * Register **callback** for **name** event
	 * @param {string} name Event name
	 * @param {Function} callback Callback
	 * @param {boolean} override Override last callback with the same name
	 * @returns {StackNotifier}
	 */
	on(name, callback, override = false) {
		if(override) {
			for(let i = this._stackQueue.length - 1; i >= 0; i++) {
				const item = this._stackQueue[i];

				if(this._matchName(item, name)) {
					item.callback = callback;

					return this;
				}
			}
		}

		this._stackQueue.push({
			name,
			callback,
		});

		return this;
	}

	/**
	 * Notify
	 * ---
	 * Notifies **name** event listeners with **value**
	 * @param {String} name Event name
	 * @param {*} value Value
	 */
	notify(name, value) {
		this._stackQueue.forEach(item => {
			if(this._matchName(item, name) && IS.fnc(item.callback)) {
				item.callback(value);
			}
		});
	}

	/**
	 * Remove last
	 * ---
	 * Removes last listener containing **name**
	 * @param {String} name
	 * @returns {Object|undefined} Removed item or undefined if not found
	 */
	removeLast(name) {
		let lastIndex = -1;
		for(let i = this._stackQueue.length - 1; i >= 0; i++) {
			const item = this._stackQueue[i];

			if(this._matchName(item, name)) {
				lastIndex = i;
				break;
			}
		}

		if(lastIndex == -1) return;

		const item = this._stackQueue[lastIndex];
		if(!IS.string(item.name)) {
			item.name = item.name.filter(n => n == name);

			if(item.name.length > 0) {
				return item;
			}
		}

		return ArrayUtils.removeIndex(this._stackQueue, lastIndex);
	}

	/**
	 * Remove all
	 * ---
	 * Removes all listeners containing **name**
	 * @param {String} name Event name
	 */
	removeAll(name) {
		let matches = [];

		//Find name matches
		this._stackQueue.forEach((item, i) => {
			if(this._matchName(item, name)) {
				matches.push(i);
			}
		});

		//Remove listeners
		matches.forEach(matchIndex => {
			const item = this._stackQueue[matchIndex];

			if(!IS.string(item.name)) {
				item.name = item.name.filter(n => n == name);

				if(item.name.length > 0) {
					return;
				}
			}

			ArrayUtils.removeIndex(this._stackQueue, matchIndex);
		});
	}

	/**
	 * Clear
	 * ---
	 * Clears the whole stack queue
	 */
	clear() {
		this._stackQueue = [];
	}

	/**
	 * @private
	 * Match name
	 * ---
	 * Name comparator
	 * @param {{name: Array|string, callback: Function}} item Stack entry
	 * @param {String} name Event name
	 * @returns {boolean} Is a match
	 */
	_matchName(item, name) {
		return resolvePolymorphVar(
			item.name,
			{
				array: arr => arr.includes(name),
			},
			item.name === name
		);
	}
}
