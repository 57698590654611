import { IS } from "@green24/js-utils";

export class RIS extends IS {
	/**
	 * RIS component
	 * ---
	 * Verifies if provided value is a React component
	 * @param {React.Component} value
	 * @return {Boolean}
	 */
	static component(value) {
		return IS.property(value, "props", "type");
	}

	/**
	 * RIS customComponent
	 * ---
	 * Verifies if the provided value is a custom React component
	 * (for the native ones like a <div/>, <a/>, ... should return false)
	 * @param {React.Component} value
	 * @return {Boolean}
	 */
	static customComponent(value) {
		return RIS.component(value) && !IS.string(value.type);
	}
}
