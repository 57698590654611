import { Component } from "preact";
import PropTypes from "prop-types";
import { Calc, combineClasses, EventListeners } from "@green24/npm-javascript-utils";
import { ComponentStateUtils, ValueUpdateListeners } from "@green24/npm-preact-utils";
import { ProductEngine } from "../../utils/THREE/ProductEngine";
import { getApi } from "../../services/api-connector";
import Loading from "../../components/shared/Loading";
import ButtonsConstructor from "../../components/shared/input/Button/ButtonsConstructor";
import root from "window-or-global";
import { Interpret } from "../../components/components";
import ButtonComponent from "../../components/shared/input/Button/ButtonComponent";
import { AppUtils } from "../../utils/AppUtils";
import { ProductCategoriesUtils, ProductFeaturesUtils } from "../../utils/ProductUtils";
import Checkbox from "../../components/shared/input/Checkbox";
import { APP_CONSTANTS } from "../../models/constants/AppConstants";
import ErrorBlock from "../../components/shared/ErrorBlock";
import withSiteSettingsContext from "../../components/other/settings/hoc/withSiteSettingsContext";

class ProductModelViewer extends Component {
	constructor() {
		super();

		this.state = {
			loading: !AppUtils.saveData,
			error: null,
			progress: 0,
			fullscreen: false,
			clickToLoad: AppUtils.saveData,
			autoRotate: true,
		};

		this._ref = null;
		this._mounted = false;

		this._valueUpdateListeners = new ValueUpdateListeners(this);
		this._eventListeners = new EventListeners();
		this._instance = new ProductEngine(0, 0);
	}

	componentDidMount() {
		this._mounted = true;
		this._ref.appendChild(this._instance.renderer.domElement);
		this._resizeOnRef();

		this._instance.onInteractionStart(() => {
			this.setState({autoRotate: false});
		});

		this._valueUpdateListeners.getValueWithUpdateListener("props.modelUrl", url => {
			if(AppUtils.saveData) {
				this.setState({clickToLoad: true});
			}
			else {
				this._loadModel(url);
			}
		});

		this._valueUpdateListeners.getValueWithUpdateListener("props.siteSettingsContext.theme", () => {
			const background = window.getComputedStyle(this._ref).backgroundColor;
			this._instance.updateSceneBackground(background);
		});

		this._valueUpdateListeners.add("state.fullscreen", (isFullscreen) => {
			if(isFullscreen) {
				this._ref.requestFullscreen().then(() => {
					this._resizeOnRef();
				});
			}
			else if(!!document.fullscreenElement) {
				this._instance.resize(0, 0);

				document.exitFullscreen().then(() => {
					this._resizeOnRef();
				});
			}
		});

		this._valueUpdateListeners.getValueWithUpdateListener("state.autoRotate", (autoRotate) => {
			this._instance && this._instance.setAutoRotate(autoRotate);
		});

		this._eventListeners.add(root, "resize", () => {
			this._resizeOnRef();
			this._instance.render();
		});

		this._eventListeners.add(document, "fullscreenchange", e => {
			if(!document.fullscreenElement) {
				this._mounted && this.setState({fullscreen: false});
			}
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState, this.props, this.state);
	}

	componentWillUnmount() {
		this._mounted = false;
		this._valueUpdateListeners.clear();
		this._eventListeners.clear();
		this._instance.destroy();
	}

	render({className, style, modelUrl}, {loading, progress, fullscreen, clickToLoad, autoRotate, error}) {
		return (
			<section
				className={combineClasses(
					"product-model-viewer",
					!modelUrl && "no-model",
					className,
				)}
				style={style}
				ref={ref => {
					this._ref = ref;
					this._resizeOnRef();
				}}
			>
				{
					loading &&
					progress < 100 &&
					<Loading className={"with-background"} title={"loading"} progress={progress}/>
					||
					(
						(clickToLoad || error) &&
						<div className={"overlay"}>
							<div className={"surface"}>
								{
									error &&
									<ErrorBlock error={error} onRetry={() => this._loadModel()}/>
									||
									<>
										<p className={"message"}><Interpret id={"productViewer.loadModelOnClick.title"}/></p>

										<div>
											<Checkbox
												text={<Interpret id={"productViewer.loadModelOnClick.alwaysLoadCheckbox.text"}/>}
												value={AppUtils.localStorage.ignoreSaveData}
												onModify={value => localStorage.setItem(APP_CONSTANTS.LOCAL_STORAGE_KEY_IGNORE_SAVE_DATA, value ? 1 : 0)}
											/>

											<ButtonComponent text={"load"} onClick={() => this._loadModel()}/>
										</div>
									</>
								}
							</div>
						</div>
					)
				}
				{

				}

				<nav className={"action-buttons"}>
					<ButtonsConstructor buttons={[
						{
							className: "reset-scene",
							icon: "undo-alt",
							action: () => this._resetScene(),
						},
						{
							className: "toggle-auto-rotate",
							icon: autoRotate ? "pause" : "play",
							action: () => ComponentStateUtils.toggleBoolean(this, "autoRotate"),
						},
						//FFS: Change scene/skybox of the model viewer
						// {
						// 	className: "change-environment",
						// 	icon: "image-polaroid",
						// 	action: () => this._openChangeEnvironmentModal(),
						// },
						{
							className: "toggle-fullscreen",
							icon: fullscreen ? "compress" : "expand",
							action: () => ComponentStateUtils.toggleBoolean(this, "fullscreen")
						}
					]} sharedProps={{className: "round"}}/>
				</nav>
			</section>
		);
	}

	_loadModel(url = this.props.modelUrl) {
		if(!url) return;

		this._mounted && this.setState({loading: true, error: null});

		this._instance.loadModel(getApi() + url, progressEvent => {
			const progress = Calc.mapRangeClamped(progressEvent.loaded, 0, progressEvent.total, 0, 100);

			this._mounted && this.setState({progress});
		}).then(model => {
			if(!this._mounted) return;

			this._instance.addMainModelToScene(model);

			this._valueUpdateListeners.add("props.categories", () => {
				this._updateModelPartsVisibility();
			});

			this._valueUpdateListeners.getValueWithUpdateListener("props.enabledFeatures", enabledFeatures => {
				this._updateModelPartsVisibility(enabledFeatures);
			});
		}, error => {
			console.error("Error while downloading a model", error);

			this._mounted && this.setState({error});
		}).finally(() => {
			this._mounted && this.setState({loading: false});
		});
	}

	_resizeOnRef() {
		if(this._ref) {
			this._instance.resizeToElement(this._ref);
		}
	}

	_updateModelPartsVisibility(enabledFeatures = this.props.enabledFeatures) {
		const {categories, features} = this.props;
		const allModelKeys = ProductFeaturesUtils.getAllModelKeysFromFeatures(features);

		if(this._instance.hasMainModel()) {
			this._instance.mainModel.traverse((object) => {
				if(allModelKeys.some(key => new RegExp('^' + key).test(object.name))) {
					object.visible = false;
				}

				if(ProductCategoriesUtils.isObjectVisible(categories, object.name, enabledFeatures)) {
					object.visible = true;
				}
			});

			this._instance.mainModel.traverse(object => {
				if(!object.visible) {
					object.traverse(childrenO => childrenO.visible = false);
				}
			});

			const visibleModels = [];

			this._instance.mainModel.traverse(object => {
				if(object.visible) {
					visibleModels.push(object);
				}
			});

			this._instance.fitCameraToObjects(visibleModels.slice(2), {revertRotation: true});
			this._instance.controls.saveState();
		}

		this._instance.render();
	}

	_openChangeEnvironmentModal() {
		//FFS: Can select an environment (scene skybox) for the model preview
	}

	_resetScene() {
		this._instance.resetScene();
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			modelUrl: PropTypes.string,
			features: PropTypes.array,
			categories: PropTypes.array,
			enabledFeatures: PropTypes.array,

			//withSiteSettingsContext
			siteSettingsContext: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			progress: PropTypes.number,
			fullscreen: PropTypes.bool,
			clickToLoad: PropTypes.bool,
			autoRotate: PropTypes.bool,
		}
	}
}

export default withSiteSettingsContext(ProductModelViewer);
