import { DateUtils } from "../Jiffy/DateUtils";
import { objectWithoutPropertiesLoose } from "../../functions/generic";
import { E_WeekDay } from "../../models/constants/JiffyContants";

/**
 * @typedef DateTimeFormatOptions
 * @type {{
 *  weekday: "narrow"|"short"|"long",
 *  era: "narrow"|"short"|"long",
 *  year: "2-digit"|"numeric",
 *  month: "2-digit"|"numeric"|"narrow"|"short"|"long",
 *  day: "2-digit"|"numeric",
 *  dayPeriod: "narrow"|"short"|"long",
 *  hour: "2-digit"|"numeric",
 *  minute: "2-digit"|"numeric",
 *  second: "2-digit"|"numeric",
 *  fractionalSecondDigits: 1|2|3,
 *  timeZoneName: "short"|"long",
 * }}
 * @see https://tc39.es/ecma402/#datetimeformat-objects
 */

/**
 * @typedef ExtendedDateTimeFormatOptions
 * @extends DateTimeFormatOptions
 * @type {{
 *  weekday: "narrow"|"short"|"long",
 *  era: "narrow"|"short"|"long",
 *  year: "2-digit"|"numeric",
 *  month: "2-digit"|"numeric"|"narrow"|"short"|"long",
 *  day: "2-digit"|"numeric",
 *  dayPeriod: "narrow"|"short"|"long",
 *  hour: "2-digit"|"numeric",
 *  minute: "2-digit"|"numeric",
 *  second: "2-digit"|"numeric",
 *  fractionalSecondDigits: 1|2|3,
 *  timeZoneName: "short"|"long",
 *  locale: string,
 * }}
 */

export class ExtendedDate extends Date {
	constructor(v) {
		//new Date(undefined) results in an Invalid Date for some reason
		// so it cannot be used to get the same value as new Date(). -_-
		// As such using no argument will do the trick...
		v === undefined ? super() : super(v);

		this.formatOptions = {};

		Object.defineProperty(this, "invalid", {
			value: this.toString() === "Invalid Date",
		});
	}

	static get enum() {
		return {weekday: E_WeekDay};
	}

	/**
	 * Set format options
	 * ---
	 * @param {ExtendedDateTimeFormatOptions} formatOptions
	 */
	setFormatOptions(formatOptions) {
		this.formatOptions = formatOptions || {};
	}

	/**
	 * To format
	 * ---
	 * @param {string} [locale]
	 * @param {ExtendedDateTimeFormatOptions} formatOptions
	 * @return {string}
	 */
	toFormat(locale, formatOptions) {
		return new Intl.DateTimeFormat(
			locale || this.formatOptions.locale || "en",
			{
				...objectWithoutPropertiesLoose(this.formatOptions, "locale"),
				...formatOptions,
			}
		).format(this);
	}

	/**
	 * To ISO 8601 string
	 * ---
	 * Returns the date in the ISO 8601 string format
	 * @return {string} ISO 8601 string
	 * @see DateUtils.getISO8601String
	 */
	toISO8601String() {
		return DateUtils.getISO8601String(this);
	}

	/**
	 * Set start of day
	 * ---
	 * Sets 00:00:00.000 to the provided date **(Reference only!)**
	 * @see DateUtils.setStartOfDay
	 * @returns ExtendedDate
	 */
	setStartOfDay() {
		return DateUtils.setStartOfDay(this);
	}

	/**
	 * Set end of day
	 * ---
	 * Sets 23:59:59.999 to the provided date **(Reference only!)**
	 * @see DateUtils.setEndOfDay
	 * @returns ExtendedDate
	 */
	setEndOfDay() {
		return DateUtils.setEndOfDay(this);
	}

	/**
	 * Set start of month
	 * ---
	 * Sets the last day of the month and if **includeTime** is true then also the last millisecond of the date
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 * @returns ExtendedDate
	 * @see DateUtils.setStartOfMonth
	 */
	setStartOfMonth(includeTime = false) {
		return DateUtils.setStartOfMonth(this, includeTime);
	}

	/**
	 * Set end of month
	 * ---
	 * Sets the last day of the month and if **includeTime** is true then also the last millisecond of the date
	 * @param {boolean} includeTime If should set time to the last millisecond or keep as it is
	 * @returns ExtendedDate
	 * @see DateUtils.setEndOfMonth
	 */
	setEndOfMonth(includeTime = false) {
		return DateUtils.setEndOfMonth(this, includeTime);
	}

	getWeekDay() {
		return DateUtils.getWeekDays()[this.getDay()];
	}
}

export const ED = (value) => new ExtendedDate(value);
