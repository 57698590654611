/**
 * @enum {Number}
 * @readonly
 */
export const E_HttpErrorCode = {
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	REQUEST_TIMED_OUT: 408,
	SIMULATED_CUSTOM_ERROR: 412,
	INTERNAL_SERVER_ERROR: 500,
	SERVICE_UNAVAILABLE: 503,
	SERVER_UNREACHABLE: 523,
};

/**
 * @enum {String}
 * @readonly
 */
export const E_APIMethod = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
};

/**
 * XHR Upload states
 * ---
 * @enum string
 * @readonly
 */
export const E_XHR_UploadState = {
	CONNECTING: "CONNECTING",
	STALLED: "STALLED",
	UPLOADING: "UPLOADING",
	FAILED: "FAILED",
	PROCESSING: "PROCESSING",
	COMPLETED: "COMPLETED",
};
