import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import { ArrayUtils, uID } from "@green24/js-utils";

export let StoreContext = createContext();
export const setStoreContext = context => StoreContext = context;

class StoreProvider extends Component {
	constructor() {
		super();

		this.state = {};

		this._listeners = [];
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		if(previousProps.store !== this.props.store) {
			previousProps.store.destroy();
		}
	}

	render({children, store, context}) {
		store.onUpdate((newState, oldState) => {
			this._listeners.forEach(listenerData => {
				listenerData.callback(newState, oldState);
			});
		});

		const contextData = {
			store,
			subscribe: (callback) => this._addListener(callback),
			getState: () => store.getState(),
		};

		const Context = context || StoreContext;

		return (
			<Context.Provider value={contextData}>
				{children}
			</Context.Provider>
		);
	}

	_addListener(callback) {
		const id = uID();
		const data = {
			id,
			callback,
			remove: () => ArrayUtils.removeID(this._listeners, id, false, false),
		};

		this._listeners.push(data);

		callback(this.props.store.getState(), undefined);

		return data.remove;
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			store: PropTypes.object,

			//context override
			context: PropTypes.object,
		}
	}
}

export default StoreProvider;
