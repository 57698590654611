import { IS } from "./IS";

/**
 * Idle Manager
 * ---
 * Triggers an event after being idle for {idleTime} in ms
 * @param {Number} idleTime Wait time - For how long the manager will wait before triggering the callback
 * @param {Function} callback Callback function
 */
export class IdleManager {
	constructor(callback, idleTime = 1000) {
		this.timeout = null;
		this.idleTime = idleTime;
		this.callback = callback;
	}

	/**
	 * Trigger
	 * ---
	 * Triggers idle timeout refresh
	 */
	trigger() {
		if (this.timeout) {
			this._clearTimeout();
		}

		this._setupTimeout();
	}

	/**
	 * Force Trigger
	 * ---
	 * Ignores and invalidates the idle timeout and calls the callback immediately
	 *
	 * **NOTE!** The timeout will be CLEARED and will NOT start again after the callback.
	 */
	forceTrigger() {
		this._clearTimeout();
		this._trigger();
	}

	/**
	 * Is Active
	 * ---
	 * Returns if the timeout is active
	 * @returns {boolean} Is active
	 */
	isActive() {
		return !!this.timeout;
	}

	/**
	 * @private
	 * Clear Timeout
	 * ---
	 */
	_clearTimeout() {
		clearTimeout(this.timeout);
		this.timeout = null;
	}

	/**
	 * @private
	 * Setup Timeout
	 * ---
	 */
	_setupTimeout() {
		this.timeout = setTimeout(this._trigger.bind(this), this.idleTime);
	}

	/**
	 * @private
	 * Trigger
	 * ---
	 * Triggers the callback function if is defined
	 */
	_trigger() {
		if(IS.fnc(this.callback)) {
			this.callback();
		}
	}
}
