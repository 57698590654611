import { EO } from "../../ExtendedObject";
import { get, parseBool } from "../../../functions/generic";
import { ArrayUtils } from "../../ArrayUtils";
import { EventListeners } from "../../EventListeners";
import { IS } from "../../IS";

export const EVENT_NAME_LOCAL_STORAGE_UPDATED = "storage-local";

export class LocalStorageEditor {
	static getRaw(key) {
		return localStorage.getItem(key);
	}

	static get(path) {
		let pathParts = path.split('.');
		let key = pathParts[0];

		let item = localStorage.getItem(key);
		if(item === null) {
			return null;
		}

		try {
			item = JSON.parse(item);
		}
		catch (e) {}

		if(pathParts.length > 1) {
			let getPath = pathParts.slice(1);

			return get(item, getPath);
		}

		return item;
	}

	static getWithValueUpdater(path, callback, comparator) {
		this.onChange(path, callback, comparator);

		callback(this.get(path), undefined);
	}

	static has(key) {
		return !!LocalStorageEditor.getRaw(key);
	}

	/**
	 * Is true
	 * ---
	 * Returns if the value inside the localStorage is any form of boolean.
	 * @param {string} key
	 * @return {Boolean}
	 * @see parseBool
	 */
	static isTrue(key) {
		const value = LocalStorageEditor.getRaw(key);

		return parseBool(value);
	}

	static set(path, value, preventStorageUpdateEvent = false) {
		let pathParts = path.split('.');
		let key = pathParts[0];

		if(pathParts.length > 1) {
			let setPath = pathParts.slice(1).join('.');
			let obj = LocalStorageEditor.get(key) || {};

			let result = EO(obj).set(setPath, value);

			localStorage.setItem(key, JSON.stringify(result));
			!preventStorageUpdateEvent && this.dispatchStorageUpdate("SET", key, result, setPath);
		}
		else {
			key && localStorage.setItem(key, JSON.stringify(value));
			!preventStorageUpdateEvent && this.dispatchStorageUpdate("SET", key, value);
		}
	}

	static onExternalChange(callback) {
		return EventListeners.createListener(window, "storage", callback);
	}

	static onChange(path, callback, comparator = IS.equal) {
		let oldValue = this.get(path);
		const __updateHandler = () => {
			const newValue = this.get(path);

			if(!comparator(newValue, oldValue)) {
				callback(newValue, oldValue);

				oldValue = newValue;
			}
		};

		const localChangeListener = EventListeners.createListener(window, EVENT_NAME_LOCAL_STORAGE_UPDATED, __updateHandler);
		const externalChangeListener = this.onExternalChange(__updateHandler);

		return () => {
			localChangeListener();
			externalChangeListener();
		}
	}

	static remove(path, preventStorageUpdateEvent = false) {
		let pathParts = path.split('.');

		if(pathParts.length > 1) {
			let key = pathParts[0];
			let getPath = pathParts.slice(1).join('.');
			let obj = LocalStorageEditor.get(key, getPath) || {};

			delete obj[ArrayUtils.lastItem(pathParts)];

			this.set(pathParts.slice(0, -1).join(''), obj, true);
			!preventStorageUpdateEvent && this.dispatchStorageUpdate("REMOVE", key, obj, getPath);
		}
		else {
			localStorage.removeItem(pathParts[0]);
			!preventStorageUpdateEvent && this.dispatchStorageUpdate("REMOVE", pathParts[0], null);
		}
	}

	static dispatchStorageUpdate(type, key, data, path = undefined) {
		window.dispatchEvent(new CustomEvent(EVENT_NAME_LOCAL_STORAGE_UPDATED, {
			detail: {
				type,
				key,
				data,
				path,
			}
		}));
	}
}
