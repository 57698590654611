import { get, resolvePolymorphVar } from "../../functions/generic";
import { IS } from "../IS";
import { GoogleCoords } from "./GoogleCoords";
import { mapRangeClamped } from "../../functions/math";
import { ResourceLoader } from "../ResourceLoader";

const T_Options = {
	defaultPosition: new GoogleCoords(0, 0),
	zoom: 6,
	keyboardShortcuts: false,
	gestureHandling: 'greedy',
	streetViewControl: false,
};

export class Maps {
	/**
	 *
	 * @param {Element} element
	 * @param {function(maps): T_Options} options Options in form of callable function. This is necessary for using google.maps. ... .<ENUM> (e.g. google.maps.ControlPosition.LEFT_TOP)
	 * @param {String} apiKey
	 * @param {Promise} mapsInitPromise After maps init promise
	 * @return {Promise}
	 */
	constructor(element, options = T_Options, apiKey, mapsInitPromise = () => null) {
		this.map = null;
		this.element = element;
		this.options = resolvePolymorphVar(options, {
			function: (f) => f,
			object: (o) => () => o,
		}, {});

		let initPromise = new Promise((resolve, reject) => {
			//Initialize Google Maps API
			Maps.initializeGoogleMapsAPI(apiKey).then(() => {
				//Initialize maps for this.element if present
				resolve(this.initializeMapsForElement());
			}, (error) => {
				reject(error);
			});
		});

		mapsInitPromise(initPromise);
	}

	static initializeGoogleMapsAPI(apiKey) {
		if(IS.nestedProperty(window, `google.maps`)) {
			return Promise.resolve();
		}
		else {
			return ResourceLoader.loadScript("https://maps.googleapis.com/maps/api/js?key=" + apiKey);
		}
	}

	initializeMapsForElement(el = this.element, options = this.options) {
		if(el) {
			this.element = el;
			const {maps} = window.google;
			let optionsData = options(maps) || {};
			optionsData.center = optionsData.center ? optionsData.center : optionsData.defaultPosition || new GoogleCoords(0, 0);

			this.map = new maps.Map(el, optionsData);
			this.goToCurrentLocation(optionsData.center);
			return this.map;
		}
		return null;
	}

	goToCurrentLocation(fallbackPosition) {
		Maps.getGeoLocation().then(
			pos => this.changeMapViewport(pos),
			() => {
				this.changeMapViewport({coords: fallbackPosition});
			}
		);
	}

	changeMapViewport(position, zoom = get(this, `options.zoom`)) {
		const {map} = this;
		let pos = new GoogleCoords(position.coords);

		if(map) {
			position && map.setCenter(pos);
			zoom && map.setZoom(zoom);
		}
	}

	/**
	 * Get geo location
	 * ---
	 * Returns promise with geo position of the device if allowed by user.
	 * On success returns pos in form of {GeolocationPosition}
	 * @param {{
	 *     maximumAge?: Number,
	 *     timeout?: Number,
	 *     enableHighAccuracy?: Boolean,
	 * }} options
	 * @returns {Promise}
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
	 */
	static getGeoLocation(options) {
		return new Promise(((resolve, reject) => {
			if(navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					pos => resolve(pos),
					error => reject(error),
					options
				);
			}
			else {
				let httpError = "Geolocation can NOT be invoked over an unsecured (HTTP) network";
				let notSupportedError = "Geolocation is NOT supported on the device";

				//geolocation is not supported or is accessed via unsecured protocol
				reject({
					code: "NOT_SUPPORTED",
					message: window.geolocation ? (
						window.location.protocol === "http:" ? httpError : notSupportedError
					) : notSupportedError
				});
			}
		}));
	}

	animatedPanTo(position, duration, intervalStorage = this) {
		return Maps.animatedPanTo(this.map, position, duration, intervalStorage);
	}

	static animatedPanTo(map, position, duration = 200, intervalStorage = window) {
		Maps.clearAnimatedPanInterval(intervalStorage);
		const timeout = 10;

		let {lat: startLat, lng: startLng} = new GoogleCoords(map.getCenter());
		let {lat: endLat, lng: endLng} = new GoogleCoords(position);

		return new Promise(((resolve) => {
			let i = 0;
			intervalStorage._mapsAnimatedPanInterval = setInterval(() => {
				i+= timeout;
				let lat = mapRangeClamped(i, 0, duration, startLat, endLat);
				let lng = mapRangeClamped(i, 0, duration, startLng, endLng);

				map.setCenter(new GoogleCoords(lat, lng));

				if(i > duration) {
					Maps.clearAnimatedPanInterval(intervalStorage);
					resolve();
				}
			}, timeout);
		}));
	}

	static clearAnimatedPanInterval(intervalStorage = window) {
		clearInterval(intervalStorage._mapsAnimatedPanInterval);
	}

	followMarker(marker, interval, intervalStorage = this) {
		Maps.followMarker(this.map, marker, interval, intervalStorage);
	}

	static followMarker(map, marker, interval = 1000, intervalStorage = window) {
		if(!marker) {
			Maps.clearFollowMarkerInterval(intervalStorage);
		}

		const __follow = () => {
			intervalStorage._mapsFollowMarkerInterval = setInterval(() => {
				if(marker) {
					if(!new GoogleCoords(marker.position).isEqual(new GoogleCoords(map.getCenter()))) {
						map.setCenter(marker.position);
					}
				}
				else {
					Maps.clearFollowMarkerInterval(intervalStorage);
				}
			}, interval);
		};

		Maps.clearFollowMarkerInterval(intervalStorage);
		let center = new GoogleCoords(map.getCenter());
		let targetPos = new GoogleCoords(marker.position);

		if(!targetPos.isWithinRadius(center, 0.0005)) {
			Maps.clearAnimatedPanInterval(intervalStorage);
			Maps.animatedPanTo(map, targetPos, 200, intervalStorage).then(() => {
				__follow();
			});
		}
		else {
			__follow();
		}
	}

	static clearFollowMarkerInterval(intervalStorage = window) {
		clearInterval(intervalStorage._mapsFollowMarkerInterval);
	}

	focusOnPolygon(polygon) {
		Maps.focusOnPolygon(this.map, polygon);
	}

	static focusOnPolygon(maps, polygon) {
		maps.fitBounds(polygon.bounds);
	}
}
