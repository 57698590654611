import { get, IS, resolvePolymorphVar } from "@green24/js-utils";
import { RIS } from "./ReactIS";

export class ValueUpdateListeners {
	constructor(root) {
		this._root = root;
		this._valueUpdateListeners = [];
	}

	get root() {
		return this._root || {};
	}

	/**
	 * Add
	 * ---
	 * Adds a value update listener
	 * @param {string|string[]} path
	 * @param {function(newValue, oldValue)} callback
	 * @param {boolean|string} blocking
	 * @param {function(a, b, isEqual: ValueUpdateListeners._isEqual)|string} comparator Is equal comparator, true; equal = no refresh, false; not equal = call refresh callback
	 */
	add(path, callback, blocking = false, comparator) {
		this._valueUpdateListeners.push({
			path: RIS.array(path) ? path : [path],
			callback,
			blocking,
			comparator,
		});
	}

	remove(path) {
		this._valueUpdateListeners = this._valueUpdateListeners.filter(listener => !listener.path.includes(path));
	}

	reflectPropToState(propPath, stateKey = propPath, blocking = false, comparator = undefined, root = this.root) {
		return this.add(
			"props." + propPath,
			value => {
				root.setState({[stateKey]: value});
			},
			blocking,
			comparator
		);
	}

	getValueWithUpdateListener(path, callback, blocking = false, comparator, root = this.root) {
		//In older versions the first argument was root which was moved to the back since it's not being customized that often.
		if(!(IS.string(path) || IS.array(path))) {
			console.error("Path provided:", path);
			throw Error(
				`Path is not of a valid type.\nMost probably, there is still a forgotten old definition starting with a root argument.\nLike: "this._valueUpdateListeners.getValueWithUpdateListener(this, ...)"\n`
			);
		}

		if(!root) {
			console.error("Root provided:", root);
			throw Error("Root is not valid! The getValueWithUpdateListener won't work properly unless it has a valid component reference.");
		}

		this.add(path, callback, blocking, comparator);

		path = RIS.array(path) ? path : [path];

		callback(...path.map(path => [get(root, path), undefined]).flat(1));
	}

	/**
	 * Component did update
	 * ---
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @param {Object} [newProps]
	 * @param {Object} [newState]
	 */
	componentDidUpdate(prevProps, prevState, newProps = this.root.props, newState = this.root.state) {
		if(!newProps || !newState) {
			throw Error("New component state is not valid! Either provide newProps and newState manually or include a reference to the component during ValueUpdateListener construction.");
		}

		let stop = false;
		let blockedListenerGroups = [];

		this._valueUpdateListeners.forEach(listener => {
			if(stop === false && !blockedListenerGroups.includes(listener.blocking)) {
				const updateResults = listener.path.map(path => {
					let pathParts = path.split(".");
					let newValue = get(pathParts[0] === "props" ? newProps : newState, pathParts.slice(1));
					let prevValue = get(pathParts[0] === "props" ? prevProps : prevState, pathParts.slice(1));

					const isNotMatch = !resolvePolymorphVar(
						listener.comparator,
						{
							function: f => f(prevValue, newValue, (a, b) => this._isEqual(a, b)),
							string: s => this._isEqual(get(prevValue, s), get(newValue, s)),
						},
						() => this._isEqual(prevValue, newValue),
						true
					);

					return {
						path,
						isNotMatch,
						newValue,
						prevValue,
					}
				});

				if(updateResults.some(result => result.isNotMatch)) {
					let shouldBlockGroup = false;
					if(RIS.fnc(listener.callback)) {
						const blockingGroup = listener.callback(...updateResults.map(result => [result.newValue, result.oldValue]).flat(1));

						if(RIS.defined(blockingGroup)) {
							shouldBlockGroup = blockingGroup;
						}
					}

					//If blocking = true, it should block all following listeners to prevent unnecessary re-triggering with the same value
					// (if the async cannot update it up in time)
					// (useful if listener contains value update which affects other values that are listened for later on)
					if(listener.blocking === true || shouldBlockGroup === true) {
						stop = true;
					}
					else if(listener.blocking || shouldBlockGroup) {
						//Block only for a specific group
						blockedListenerGroups.push(listener.blocking || shouldBlockGroup);
					}
				}
			}
		});
	}

	clear() {
		this._valueUpdateListeners = [];
	}

	_isEqual(a, b) {
		if(!a && !b) {
			return a === b;
		}

		if(RIS.fnc(a) && RIS.fnc(b)) {
			return a && b && a.id && b.id && a.id === b.id;
		}

		//Symbol is a unique per instance object and cannot be parsed by the JSON (.stringify() will omit the entry)
		if(typeof a === "symbol" && typeof b === "symbol") {
			return a === b;
		}

		const componentAliases = {};
		const replacer = (k, v) => {
			if(RIS.component(v) || v instanceof Symbol) {
				if(componentAliases[v]) return componentAliases[v];

				return componentAliases[v] = - Date.now() - Math.random();
			}

			return v;
		};

		//Nested object **string** comparison; This WILL FAIL if the objects are not 100% the same (structure, order, types, ...)
		return JSON.stringify(a, replacer) == JSON.stringify(b, replacer);
	}
}
