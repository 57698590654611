import { cloneElement, Component, Fragment } from 'preact';
import PropTypes from "prop-types";
import { ComponentUtils } from "../../class/ComponentUtils";
import { IS, LocalizedTerm, resolvePolymorphVar } from "@green24/js-utils";
import { RIS } from "../../class/ReactIS";
import withLocalizationContext from "./hoc/withLocalizationContext";
import { AppUtils } from "../../class/AppUtils";

class Interpret extends Component {
	render({className, style, children, id, localizationContext, fallback, params, searchInAlternatives, searchInFallbackLanguage}) {
		if(id) {
			return (
				<bdi className={className} style={style}>
					{this._interpret(id)}
				</bdi>
			);
		}

		return ComponentUtils.resolvePolymorphProp(
			children,
			{
				function: f => f(localizationContext),
				component: c => cloneElement(c, {localizationContext}),
			},
			() => children,
		) || null;
	}

	_findTerm(termKey) {
		const {localizationContext, fallback, searchInAlternatives, searchInFallbackLanguage} = this.props;

		if(localizationContext) {
			return localizationContext.findTerm(termKey, fallback, searchInAlternatives, searchInFallbackLanguage);
		}

		return new LocalizedTerm({termKey: `${termKey} (Missing context)`});
	}

	_interpret(id) {
		const {localizationContext, fallback, params, displayTextArrayLanguageCodeKey} = this.props;

		if(AppUtils.isDev && !localizationContext) {
			console.error(`LocalizationContext was not found for the Interpret component. Completely reload the page and if the problem persists, check if the context provider is missing.`);
		}

		return ComponentUtils.resolvePolymorphProp(
			id,
			{
				array: arr => {
					if(arr.every(item => IS.property(item, displayTextArrayLanguageCodeKey))) {
						return (arr.find(item => item[displayTextArrayLanguageCodeKey] == localizationContext.getActiveLanguageISOCode()) || {}).text;
					}
					return arr;
				},
				function: f => f(localizationContext),
				component: c => cloneElement(c, {localizationContext}),
			},
			() => {
				if(!IS.empty(params)) {
					let hasNonStandardParam = resolvePolymorphVar(
						params,
						{
							array: arr => arr.some(item => RIS.component(item)),
							object: o => Object.values(o).some(item => RIS.component(item)),
						},
						() => true,
						true
					);

					if(hasNonStandardParam) {
						let term = this._findTerm(id);
						if(term.valid) {
							let parts = term.splitIntoParts(false, fallback);

							return parts.map((part, i) => {
								return (
									<Fragment key={part.value + i}>
										{this._resolvePart(part)}
									</Fragment>
								);
							});
						}

						return term.toString();
					}
				}

				let term = this._findTerm(id).resolveVariables(params);
				if(term.valid) {
					return this._applyPostProcessing(term);
				}
				return term.toString();
			}
		) || null;
	}

	_resolvePart(part) {
		const {params, fallback} = this.props;

		switch (part.type) {
			case "TEXT":
				return this._applyPostProcessing(
					new LocalizedTerm({
						term: part.value,
						fallback,
					})
				);
			case "VARIABLE":
				return params[part.value];
			case "TRANSLATE":
				return this._interpret(part.value);
		}
	}

	_applyPostProcessing(term) {
		const {postProcessingRules} = this.props;

		const parts = term.splitIntoPostProcessingParts(postProcessingRules);

		if(parts.length == 1 && parts[0].type == "TEXT") return parts[0].value;

		return parts.map((part, i) => {
			return (
				<Fragment key={part.value + i}>
					{this._getComponentForPostProcessingPart(part)}
				</Fragment>
			)
		});
	}

	_getComponentForPostProcessingPart(part) {
		const {postProcessingRules} = this.props;

		switch (part.type) {
			case "TEXT":
				return part.value;
			case "BOLD":
				return <b>{part.value}</b>;
			case "ITALIC":
				return <i>{part.value}</i>;
			default:
				// eslint-disable-next-line no-case-declarations
				let {processor} = postProcessingRules[part.type] || {};

				if(IS.fnc(processor)) {
					return processor(part.value);
				}

				if(AppUtils.isDev) {
					console.warn("Processor not defined for PP of type:", part.type);
				}
				return part.value;
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			id: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
			params: PropTypes.any,
			fallback: PropTypes.string,

			searchInAlternatives: PropTypes.bool,
			searchInFallbackLanguage: PropTypes.bool,

			/**
			 * @type {Object<string, {
			 *     opening: string|RegExp,
			 *     closing: string|RegExp,
			 *     processor: (partValue: string) => string,
			 * }>}
			 */
			postProcessingRules: PropTypes.object,

			displayTextArrayLanguageCodeKey: PropTypes.string,

			//withLocalizationContext
			localizationContext: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			searchInAlternatives: true,
			searchInFallbackLanguage: true,
			displayTextArrayLanguageCodeKey: "languageCode",
		}
	}
}

export default withLocalizationContext(Interpret);
