import { EventListeners } from "./EventListeners";
import { StackNotifier } from "./StackNotifier";
import { E_PointerInputMode } from "../models/constants/PointerInputConstants";

/**
 * Notifier events
 * ---
 * @enum string
 * @readonly
 */
const E_NotifierEvents = {
	MOUSE_ACTIVE: "mouse-active",
	TOUCH_ACTIVE: "touch-active",
	MODE_CHANGED: "mode-changed",
};

/**
 * Pointer input manager
 * ---
 * Handles a change of input source between mouse, touch (and other possible pointer inputs in the future).
 */
export class PointerInputManager {
	constructor(eventsTarget = window) {
		this._stackNotifier = new StackNotifier();
		this._eventListeners = new EventListeners();

		this._lastMode = undefined;
		this._mouseEventSuppressed = false;
		this._mouseInhibitorTimeout = null;

		if(eventsTarget) {
			this.registerListeners(eventsTarget);
		}
	}

	static get enum() {
		return {
			mode: E_PointerInputMode,
		}
	}

	/**
	 * On mouse active
	 * ---
	 * @param {Function} callback
	 * @param {boolean} override Override previous callback
	 */
	onMouseActive(callback, override = false) {
		this._stackNotifier.on(E_NotifierEvents.MOUSE_ACTIVE, callback, override);
	}

	/**
	 * On touch active
	 * ---
	 * @param {Function} callback
	 * @param {boolean} override Override previous callback
	 */
	onTouchActive(callback, override = false) {
		this._stackNotifier.on(E_NotifierEvents.TOUCH_ACTIVE, callback, override);
	}

	/**
	 * On input mode changed
	 * ---
	 * @param {function({newMode: string, oldMode: string})} callback
	 * @param {boolean} override Override previous callback
	 */
	onInputModeChanged(callback, override = false) {
		this._stackNotifier.on(E_NotifierEvents.MODE_CHANGED, callback, override);
	}

	/**
	 * Register listeners
	 * ---
	 * @param {Element|window} target
	 */
	registerListeners(target = window) {
		this._eventListeners.add(window, "touchstart", e => {
			clearTimeout(this._mouseInhibitorTimeout);

			this.setMode(E_PointerInputMode.TOUCH);
		});

		this._eventListeners.add(window, "touchend", e => {
			clearTimeout(this._mouseInhibitorTimeout);

			this._mouseEventSuppressed = true;
			this._mouseInhibitorTimeout = setTimeout(() => {
				this._mouseEventSuppressed = false;
			}, 10);
		});

		this._eventListeners.add(window, "mousedown", e => {
			if(!this._mouseEventSuppressed) {
				this.setMode(E_PointerInputMode.MOUSE);
			}
		});
	}

	/**
	 * Clears all listeners
	 * ---
	 */
	clearListeners() {
		this._eventListeners.clear();
	}

	/**
	 * Destroy
	 * ---
	 * Destroys the manager instance
	 */
	destroy() {
		this.clearListeners();
		clearTimeout(this._mouseInhibitorTimeout);
	}

	/**
	 * Set mode
	 * ---
	 * @param {E_PointerInputMode} mode
	 */
	setMode(mode) {
		if(this._lastMode != mode) {
			switch (mode) {
				case E_PointerInputMode.TOUCH:
					this._stackNotifier.notify(E_NotifierEvents.TOUCH_ACTIVE);
					break;
				default:
					this._stackNotifier.notify(E_NotifierEvents.MOUSE_ACTIVE);
					break;
			}

			this._stackNotifier.notify(E_NotifierEvents.MODE_CHANGED, {
				newMode: mode,
				oldMode: this._lastMode
			});

			this._lastMode = mode;
		}
	}
}
