import { Component } from "preact";
import PropTypes from "prop-types";
import { Calc, combineClasses, EventListeners } from "@green24/npm-javascript-utils";
import ButtonsConstructor from "../input/Button/ButtonsConstructor";
import ButtonComponent from "../input/Button/ButtonComponent";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";

class Tabs extends Component {
	constructor() {
		super();

		this.state = {
			leftEnabled: true,
			rightEnabled: true,
		};

		this._activeRef = null;
		this._carouselRef = null;
		this._valueUpdateListeners = new ValueUpdateListeners(this);
		this._eventListeners = new EventListeners();
	}

	componentDidMount() {
		this._valueUpdateListeners.add("props.tabs", () => {
			setTimeout(() => {
				this._focusOnActive();
			}, 10);
		});

		this._eventListeners.add(window, "resize", () => this._toggleScrollButtons());

		this._toggleScrollButtons();

		setTimeout(() => {
			this._focusOnActive(true);
		}, 10);
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	componentWillUnmount() {
		this._eventListeners.clear();
	}

	render({className, style, tabs, onTabSelected}, {leftEnabled, rightEnabled}) {
		return (
			<section className={combineClasses("tabs", className)} style={style}>
				<ButtonComponent
					className={"round"}
					icon={"chevron-left"}
					disabled={!leftEnabled}

					onClick={() => this._offsetScroll(-1)}
				/>

				<div
					className={"tabs-carousel"}
					onWheel={e => this._handleHorizontalScroll(e)}
					onScroll={() => this._toggleScrollButtons()}

					ref={ref => this._carouselRef = ref}
				>
					<ButtonsConstructor
						buttons={(tabs || []).map((tab, i) => {
							const sharedTabData = {
								active: false,
								...tab,
								data: {
									...tab.data,
								},
							};

							return {
								...sharedTabData,
								action: () => onTabSelected(sharedTabData, i),
								ref: (ref) => {
									if(sharedTabData.active && ref) {
										this._setActiveRef(ref)
									}
								},
							}
						})}
						sharedProps={{className: "no-style"}}
					/>
				</div>

				<ButtonComponent
					className={"round"}
					icon={"chevron-right"}
					disabled={!rightEnabled}

					onClick={() => this._offsetScroll(1)}
				/>
			</section>
		);
	}

	_setActiveRef(ref) {
		this._activeRef = ref;
	}

	_focusOnActive(immediate = false) {
		const ref = this._activeRef;

		if(ref && ref.base) {
			ref.base.scrollIntoView({
				behavior: immediate ? "auto" : "smooth",
				inline: "center",
			});
		}
	}

	_handleHorizontalScroll(e) {
		const ref = this._carouselRef;

		if(ref) {
			// noinspection JSSuspiciousNameCombination
			ref.scrollLeft += e.deltaY;

			const canScrollLeft = ref.scrollLeft > 0;
			const canScrollRight = ref.scrollLeft + ref.offsetWidth < ref.scrollWidth;

			if(canScrollLeft && canScrollRight) {
				e.preventDefault();
			}
		}
	}

	_toggleScrollButtons() {
		const ref = this._carouselRef;
		if(ref) {
			const {leftEnabled, rightEnabled} = this.state;
			const canScrollLeft = ref.scrollLeft > 0;
			const canScrollRight = ref.scrollLeft + ref.offsetWidth < ref.scrollWidth;

			if(!canScrollLeft) {
				this.setState({leftEnabled: false});
			}
			else if(!leftEnabled) {
				this.setState({leftEnabled: true});
			}

			if(!canScrollRight) {
				this.setState({rightEnabled: false});
			}
			else if(!rightEnabled) {
				this.setState({rightEnabled: true});
			}
		}
	}

	_offsetScroll(direction) {
		const ref = this._carouselRef;

		ref.style.scrollBehavior = "smooth";
		ref.scrollLeft = Calc.clamp(ref.scrollLeft + direction * ref.offsetWidth / 2, 0, ref.scrollWidth);
		ref.style.scrollBehavior = null;
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			tabs: PropTypes.array.isRequired,
			onTabSelected: PropTypes.func.isRequired,
		}
	}
}

export default Tabs;
