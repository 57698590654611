import { Component as PComponent } from "preact";
import PropTypes from "prop-types";
import { InputUtils, ValueUpdateListeners } from "@green24/npm-preact-utils";
import { Debouncer, E_Modification, IdleManager, IS } from "@green24/npm-javascript-utils";
import withRootConsumer from "./withRootConsumer";
import withFormContext from "./withFormContext";

/**
 * A public higher-order component to access the imperative API
 */
export const withInput = Component => {
	class HOC extends PComponent {
		constructor(props) {
			super();

			this.state = {
				value: props.value,
			};

			this._valueUpdateListeners = new ValueUpdateListeners(this);
		}

		componentDidMount() {
			const {debouncerDelay, idleDelay, formContext} = this.props;

			this._valueUpdateListeners.add("props.debouncerDelay", (delay) => this._registerDebouncer(delay));
			this._valueUpdateListeners.add("props.idleDelay", (delay) => this._registerIdleManager(delay));

			this._valueUpdateListeners.add("props.value", () => this._updateValue(), true);
			this._valueUpdateListeners.add("props.path", () => this._updateValue(), true);
			this._valueUpdateListeners.add("props.root", () => this._updateValue(), true);

			this._valueUpdateListeners.add("state.value", () => this.props.formContext && this.props.formContext.updateValidity(this));

			this._registerDebouncer(debouncerDelay);
			this._registerIdleManager(idleDelay);

			formContext && formContext.registerInput(this);
		}

		componentDidUpdate(previousProps, previousState, snapshot) {
			this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
		}

		componentWillUnmount() {
			const {formContext} = this.props;

			formContext && formContext.unregisterInput(this);
		}

		render(
			{path, root, onModify, value: propsValue, debounceDelay, idleDelay, onImmediateModify, invalid, formContext, ...props},
			{value}
		) {
			return (
				<Component
					{...props}
					invalid={invalid || (formContext ? formContext.isInputInvalid(this) : false)}
					value={value}
					onModify={(value, modificationType = E_Modification.ITEM_SET, immediate = false) => {
						this._handleInputValueUpdate(value, modificationType, immediate)
					}}
					onBlur={() => {
						const {path, modificationType} = this._lastModification || {};
						this._handleInputValueUpdate(value, path, modificationType, true);
						formContext && formContext.updateValidity(this);
					}}
				/>
			);
		}

		validate(value) {
			const {required} = this.props;

			if(required) {
				value = value === undefined ? this.state.value : value;

				return !IS.empty(value) && IS.valid(value);
			}

			return true;
		}

		onInvalidStateChanged() {
			this.forceUpdate();
		}

		_updateValue() {
			const {path, value, root} = this.props;
			this.setState({value: InputUtils.resolveValue(path, value, root)});
		}

		_handleInputValueUpdate(value, modificationType = E_Modification.ITEM_SET, immediate = false) {
			const {onImmediateModify, path, onValue} = this.props;

			value = onValue(value);

			this.setState({value});
			onImmediateModify(value, path, modificationType);

			this._lastModification = {value, path, modificationType};

			if(immediate) {
				this._notifyModification(value);
			}
			else {
				if(this._debouncer || this._idle) {
					if(this._debouncer) {
						this._debouncer.trigger(() => this._notifyModification(value, modificationType));
					}

					if(this._idle) {
						this._idle.callback = () => this._notifyModification(value, modificationType);
						this._idle.trigger();
					}
					return;
				}

				//Fallback if idle & debouncer aren't set
				this._notifyModification(value, modificationType);
			}
		}

		_notifyModification(value, modificationType) {
			const {onModify, path} = this.props;
			onModify(value, path, modificationType);
		}

		_registerDebouncer(delay = 0) {
			if(delay > 0) {
				this._debouncer = new Debouncer(delay);
			}
			else {
				this._debouncer = null;
			}
		}

		_registerIdleManager(delay = 0) {
			if(delay > 0) {
				this._idle = new IdleManager(() => null, delay);
			}
			else {
				this._idle = null;
			}
		}

		static get displayName() {
			return "withInput(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				//Either value or path + root are required

				//Data path
				path: PropTypes.string,
				//Data root
				root: PropTypes.object,
				//Direct value override
				value: PropTypes.any,

				debouncerDelay: PropTypes.number,
				idleDelay: PropTypes.number,

				min: PropTypes.number,
				max: PropTypes.number,
				maxLength: PropTypes.number,

				required: PropTypes.bool,
				invalid: PropTypes.bool,

				onModify: PropTypes.func,
				onImmediateModify: PropTypes.func,

				onValue: PropTypes.func,

				//withFormContext
				formContext: PropTypes.object,
			}
		}

		static get stateTypes() {
			return {
				value: PropTypes.any,
			}
		}

		static get defaultProps() {
			return {
				invalid: false,
				debouncerDelay: 200,
				idleDelay: 500,
				onModify: (value, path, type) => null,
				onImmediateModify: (value, path, type) => null,
				onValue: value => value,
			}
		}
	}

	return withRootConsumer(withFormContext(HOC));
};

export default withInput;
