export class Calc {
	/**
	 * Clamp
	 * ---
	 * Clamps a value between min - max
	 * @param {Number} value Provided value
	 * @param {Number} min Min possible value returned
	 * @param {Number} max Max possible value returned
	 * @returns {Number}
	 */
	static clamp(value, min, max) {
		if(min != undefined && max != undefined) {
			return Math.max(Math.min(value, max), min);
		}
		else if(min != undefined) {
			return Math.max(value, min);
		}
		else if(max != undefined) {
			return Math.min(value, max);
		}
		return value;
	}

	/**
	 * Lerp
	 * ---
	 * Linear interpolation between **a** and **b**
	 * @param {Number} alpha % distance from **a**
	 * @param {Number} a Min value
	 * @param {Number} b Max value
	 * @returns {Number} Specific distance from **a**
	 */
	static lerp(alpha, a, b) {
		return (1 - alpha) * a + b * alpha;
	};

	/**
	 * Inverse lerp
	 * ---
	 * Inverted linear interpolation between **a** and **b**
	 * @param {Number} value Provided value
	 * @param {Number} a Min value
	 * @param {Number} b Max value
	 * @returns {Number} % (alpha) distance from **a**
	 */
	static invLerp(value, a, b) {
		return (value - a) / (b - a);
	};


	/**
	 * Map range
	 * ---
	 * Accepts value from range low - high and returns lowOut - highOut with the same distance
	 *
	 * **Notice!** Range can overflow. If the resulting value needs to be clamped, @see mapRangeClamped
	 *
	 * @param {Number} value Provided value
	 * @param {Number} low Lowest acceptable **value** value
	 * @param {Number} high Highest acceptable **value** value
	 * @param {Number} lowOut Lowest return value
	 * @param {Number} highOut Highest return value
	 * @return {Number}
	 * @see mapRangeClamped
	 */
	static mapRange(value, low, high, lowOut = 0, highOut = 1) {
		return lowOut + (highOut - lowOut) * (value - low) / (high - low);
	};

	/**
	 * Map range clamped
	 * @param {Number} value
	 * @param {Number} low Lowest acceptable **value** value
	 * @param {Number} high Highest acceptable **value** value
	 * @param {Number} lowOut Lowest return value
	 * @param {Number} highOut Highest return value
	 * @return {Number}
	 */
	static mapRangeClamped(value, low, high, lowOut = 0, highOut = 1) {
		return Calc.clamp(Calc.mapRange(value, low, high, lowOut, highOut), Math.min(lowOut, highOut), Math.max(lowOut, highOut));
	};

	/**
	 * Round
	 * ---
	 * Round value with precision to **decimals** (n) decimals
	 * @param value
	 * @param decimals Precision
	 * @returns {number}
	 */
	static round(value, decimals = 2) {
		return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
	};

	static mod(value, n) {
		return ((value % n) + n) % n;
	}

	static radToDeg(radians) {
		return radians * 180 / Math.PI;
	}

	static degToRad(degrees) {
		return degrees / 180 * Math.PI;
	}

	static randomWithinRange(min, max) {
		return Math.random() * (max - min) + min;
	}
}
