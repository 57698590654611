import { IS } from "@green24/js-utils";
import { AppUtils } from "../AppUtils";

export class ButtonInputUtils {
	static isDeveloperClick(e, disabled, callback) {
		if(
			AppUtils.isDev &&
			disabled &&
			e.ctrlKey
		) {
			e.preventDefault();

			IS.fnc(callback) && callback(e);
			return true;
		}
		return false;
	}
}
