import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import { productGroupServices } from "../../../services/product-group-services";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";
import Loading from "../../shared/Loading";
import ErrorBlock from "../../shared/ErrorBlock";
import withProject from "./hoc/withProject";
import { APIConnector } from "@green24/npm-javascript-utils";
import withSiteSettingsContext from "../settings/hoc/withSiteSettingsContext";
import { withLocalizationContext } from "../../components";

export const ProductGroupsContext = createContext();

class productGroupLoader extends Component {
	constructor() {
		super();

		this.state = {
			loading: true,
			error: null,
			groups: [],
		};

		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.getValueWithUpdateListener("props.localizationContext.activeLanguage", activeLanguage => {
			this._refreshGroups(activeLanguage.ISO639_1, this.props.siteSettingsContext.currency);
		});
		this._valueUpdateListeners.add( "props.siteSettingsContext.currency", currency => {
			this._refreshGroups(this.props.localizationContext.activeLanguage.ISO639_1, currency);
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	render({children, localizationContext, showLoading, showError, project, siteSettingsContext}, {loading, error, groups}) {
		if(loading && showLoading) {
			return <Loading style={{color: "var(--primary-color)"}} title={"products.loading"} className={"with-background full-page"}/>;
		}

		if(error && showError) {
			return <ErrorBlock error={error} onRetry={() => this._refreshGroups(localizationContext.activeLanguage.ISO639_1, siteSettingsContext.currency)}/>;
		}

		const contextData = {
			states: {
				loading,
				error,
			},
			groups,
			refresh: (locale = localizationContext.activeLanguage.ISO639_1, currency = siteSettingsContext.currency) => this._refreshGroups(locale, currency),
		};

		return (
			<ProductGroupsContext.Provider value={contextData}>
				{children}
			</ProductGroupsContext.Provider>
		)
	}

	_refreshGroups(languageCode, currency) {
		const {project} = this.props;

		if(project) {
			this.setState({loading: true, error: null});

			productGroupServices(project.id).getList({lang: languageCode, currency}).then(groups => {
				this.setState({groups});
			}, (error) => {
				this.setState({error});
			}).finally(() => {
				this.setState({loading: false});
			});
		}
		else {
			console.error("project.id is undefined");
			this.setState({
				loading: false,
				error: APIConnector.getErrorFromCode(400)
			});
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			showLoading: PropTypes.bool,
			showError: PropTypes.bool,

			//withLocalizationContext
			localizationContext: PropTypes.object,

			//withProject
			project: PropTypes.object,

			//withSiteSettingsContext
			siteSettingsContext: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			groups: PropTypes.array,
		}
	}

	static get defaultProps() {
		return {
			showLoading: true,
			showError: true,
		}
	}
}

export default withProject(withLocalizationContext(withSiteSettingsContext(productGroupLoader)));
