import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import ButtonsConstructor from "./Button/ButtonsConstructor";
import withInput from "./hoc/withInput";

class RadioGroup extends Component {
	render({className, style, options, value, onModify}, {}) {
		return (
			<section className={combineClasses("radio-group", className)} style={style}>
				<ButtonsConstructor
					buttons={options.map(option => {
						return {
							id: option.id,
							icon: (
								<div>
									<div className={"radio-circle"}/>
								</div>
							),
							...option,
							active: option.value == value,
							action: () => onModify(option.value),
						}
					})}
					sharedProps={{className: "no-style"}}
				/>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			options: PropTypes.array,
			value: PropTypes.any,

			onModify: PropTypes.func,
		}
	}
}

export default withInput(RadioGroup);
