import { cloneElement, Component, Fragment } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";

class DynamicTextarea extends Component {
	_renderTextarea() {
		const {value, style, className} = this.props;

		return (
			<Fragment>
				<textarea
					{...this.props}
					className={combineClasses(
						className,
						"textarea-input"
					)}
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						height: "100%",
						overflow: "hidden",
						resize: "none",
						...style
					}}
				/>
				{/* <pre> must inherit the same styles as textarea (at least in terms of any text transform; e.g. line-height, font-size, etc.) */}
				<pre
					className={"textarea-input"}
					style={{
						width: "100%",
						visibility: "hidden",
						pointerEvents: "none",
						wordWrap: "break-word",
					}}
				>
					{value}
					<br/>
				</pre>
			</Fragment>
		)
	}

	render({wrapper}) {
		if(wrapper) {
			return cloneElement(wrapper, {
				children: this._renderTextarea(),
				style: {
					...wrapper.props.style,
					className: "textarea input",
					position: "relative",
					display: "flex",
					flexDirection: "flex-start",
				},
			});
		}

		return this._renderTextarea();
	}

	static get propTypes() {
		return {
			value: PropTypes.any,
			onInput: PropTypes.func,
			wrapper: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			onInput: () => null,
			wrapper: <div/>,
		}
	}
}

export default DynamicTextarea;
