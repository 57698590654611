import { Component as PComponent } from "preact";
import { ProductGroupsContext } from "../project/ProductGroupsLoader";
import { IS } from "@green24/npm-javascript-utils";

/**
 * A public higher-order component to access the imperative API
 */
export const withProductGroups = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<ProductGroupsContext.Consumer>
					{(groupsContext) => {
						return (
							<Component
								groups={groupsContext.groups.filter(group => !IS.empty(group.products))}
								groupsContext={groupsContext}
								{...props}
							/>
						);
					}}
				</ProductGroupsContext.Consumer>
			);
		}

		static get displayName() {
			return "withProductGroups(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withProductGroups;
