import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import { combineClasses, IS } from "@green24/npm-javascript-utils";

export const FormContext = createContext();

class Form extends Component {
	constructor() {
		super();

		this._inputsData = [];
	}

	render({className, style, children}, {}) {
		const formContext = {
			registerInput: input => this._registerInput(input),
			unregisterInput: input => this._unregisterInput(input),
			validate: () => this._validate(),
			updateValidity: (input, value) => this._updateValidityForInput(input, value),
			isInputInvalid: input => this._isInputInvalid(input),
		};

		return (
			<form
				className={combineClasses("form", className)}
				style={style}
				onSubmit={e => this._handleSubmit(e)}
			>
				<FormContext.Provider value={formContext}>
					{children}
				</FormContext.Provider>
			</form>
		);
	}

	_registerInput(input) {
		this._inputsData.push({
			input,
			invalid: undefined,
		});
	}

	_unregisterInput(input) {
		this._inputsData = this._inputsData.filter(inputData => inputData.input != input);
	}

	_validate() {
		this._inputsData.forEach(data => this._updateValidityForInput(data.input));

		return !this._inputsData.some(data => data.invalid);
	}

	_updateValidityForInput(input, value) {
		let data = this._inputsData.find(data => data.input == input);

		if(data) {
			let newInvalidState = !this._checkInputValidity(data.input, value);
			let invalidStateChanged = data.invalid !== newInvalidState;

			data.invalid = newInvalidState;

			if(invalidStateChanged && IS.fnc(input.onInvalidStateChanged)) {
				input.onInvalidStateChanged(newInvalidState);
			}
		}
	}

	_checkInputValidity(input, value) {
		return input.validate ? input.validate(value) : false;
	}

	_isInputInvalid(input) {
		return (this._inputsData.find(data => data.input == input) || {}).invalid;
	}

	_handleSubmit(e) {
		e.preventDefault();

		this.props.onSubmit(this._validate());
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			onSubmit: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			onSubmit: () => null,
		}
	}
}

export default Form;
