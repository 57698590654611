/**
 * StoreActions Button model
 * ---
 * @type {{
 *  icon: M_Icons,
 *  action: (function(): null),
 *  className: String,
 *  styles: Object,
 *  text: String|Element,
 *  disabled: Boolean,
 *  preset: String,
 *  linkProps: Object,
 *  activeClass: String,
 *  href: String,
 * }}
 */
export const M_ActionButton = {
	className: null,
	style: null,
	icon: null,
	text: null,
	action: () => null,
	disabled: false,
	preset: null,
	linkProps: null,
	activeClass: "active",
	href: null,
};

export const M_ButtonPresets = {

};
