import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import withInput from "./hoc/withInput";
import ErrorBlock from "../ErrorBlock";
import RadioGroup from "./RadioGroup";
import { currencyServices } from "../../../services/currency-services";
import { Interpret, withLocalizationContext } from "../../components";

class CurrencySelector extends Component {
	constructor(props) {
		super();

		this.state = {
			fetching: false,
			error: null,
			currencies: ["CZK"],
		}
	}

	componentDidMount() {
		this._getCurrencies();
	}

	_renderContent() {
		const {value, onModify, localizationContext} = this.props;
		const {fetching, error, currencies} = this.state;

		if(fetching) return <div><Interpret id={"loading.currencies"}/></div>;
		if(error) return <ErrorBlock error={error} onRetry={() => this._getCurrencies()}/>;

		return (
			<RadioGroup
				options={currencies.map(currency => {
					const currencyTranslation = new Intl.NumberFormat(localizationContext.activeLanguage.ISO639_1, {
						style: "currency",
						currency,
					}).formatToParts().find(
						part => part.type == "currency"
					);

					return {
						text: `${currency} (${currencyTranslation ? currencyTranslation.value : ''})`,
						textTranslated: true,
						value: currency
					};
				})}
				value={value}
				onModify={onModify}
			/>
		)
	}

	render({className, style}, {}) {
		return (
			<section className={combineClasses("currency-selector", className)} style={style}>
				{this._renderContent()}
			</section>
		);
	}

	_getCurrencies() {
		this.setState({fetching: true, error: null});

		currencyServices.getList().then(currencies => {
			this.setState({currencies});
		}, error => {
			this.setState({error});
		}).finally(() => {
			this.setState({fetching: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//withInput
			value: PropTypes.any,
			onModify: PropTypes.func,

			//withLocalizationContext
			localizationContext: PropTypes.object,
		}
	}
}

export default withInput(withLocalizationContext(CurrencySelector));
