import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import ButtonsConstructor from "../input/Button/ButtonsConstructor";
import Fa from "../Fa";
import { withLocalizationContext } from "../../components";

class ActiveLanguagePicker extends Component {
	render({className, style, localizationContext}, {showOptions}) {
		const {internationalizer, activeLanguage, setActiveLanguage} = localizationContext;

		return (
			<section className={combineClasses("active-language-picker", className)} style={style}>
				<ButtonsConstructor
					buttons={internationalizer.getAllRegions().map(region => {
						return {
							icon: (
								<div className={"icon-wrapper"}>
									<Fa icon={this._getIconForRegion(region)}/>
								</div>
							),
							text: region,
							active: activeLanguage.isRelatedCode(region),
							disabled: activeLanguage.isRelatedCode(region),
							action: () => setActiveLanguage(region),
						}
					})}
					sharedProps={{className: "no-style"}}
				/>
			</section>
		);
	}

	_getIconForRegion(region) {
		switch (region) {
			case "cs":
				return "czech-flag";
			case "en":
				return "english-flag";
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//withLocalizationContext
			localizationContext: PropTypes.object,
			internationalizer: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			showOptions: PropTypes.bool,
		}
	}
}

export default withLocalizationContext(ActiveLanguagePicker);
