import { resolvePolymorphVar } from "./generic";
import { IS } from "../classes/IS";

/**
 * Has parent
 * ---
 * Returns true if startElement has one of the targets as a parent
 * @param {HTMLElement} startElement
 * @param {HTMLElement} target
 * @return {Boolean}
 */
export const hasParent = (startElement, ...target) => {
	let el = startElement;

	while (el && el.parentElement) {
		if (target.includes(el)) {
			return true;
		}
		el = el.parentElement;
	}
	return false;
};

/**
 * Closest
 * ---
 * Returns closest element based on selector
 * @param {HTMLElement} startElement
 * @param {String} selector
 * @return {HTMLElement|null}
 */
export const closest = (startElement, selector) => {
	let target = startElement;

	while (target && target.parentElement) {
		if (matchSelector(target, selector)) {
			return target;
		}
		target = target.parentElement;
	}
	return null;
};

/**
 * Match selector
 * ---
 * Utility function that checks if the target element matches the selector conditions
 * ```
 *  <div id="a"/> - matchSelector('pseudo: <div id="a"> reference', '#a') => true
 *  <div id="b"/> - matchSelector('pseudo: <div id="a"> reference', '#a') => false
 *  <div data-test/> - matchSelector('pseudo: <div data-test> reference', '[data-test]') => true
 *  ...
 * ```
 * @param {HTMLElement} element
 * @param {String} selector
 * @return {Boolean}
 */
export const matchSelector = (element, selector) => {
	if (element && element.parentElement) {
		let elements = [...element.parentElement.querySelectorAll(selector)];
		return !!elements.find(item => (item === element));
	}
	return false;
};

/**
 * CSS
 * ---
 * Set styles to element
 * @param {Object|Array|String} styles Array of style properties and values
 * @param {HTMLElement} target Target
 * @returns {Object|null}
 */
export const css = (styles, target = null) => {
	let styleDefinitions = resolvePolymorphVar(
		styles,
		{
			//Handle object style definitions
			object: o => o,
			//Handle array style definitions
			array: arr => {
				let result = {};

				//For each style rule
				arr.forEach(styleRule => {
					//Style can also be in many forms (object/array/string)
					let [key, value] = resolvePolymorphVar(
						styleRule,
						{
							string: s => s.split(':'),
							arr: arr => arr,
							object: o => {
								let {key, value, important} = o;

								return [
									key.trim(),
									value.trim() + (important === true ? '!important' : important || '')
								];
							}
						},
						[]
					);

					//Store only non-empty style definitions
					if(!IS.empty(key) && !IS.empty(value)) {
						result[key.trim()] = value.trim();
					}
				});

				return result;
			},
			string: s => {
				let result = {};

				s.split(';').forEach(styleRule => {
					let [key, value] = styleRule.split(':');
					if(!IS.empty(key) && !IS.empty(value)) {
						result[key.trim()] = value.trim();
					}
				});

				return result;
			},
		},
		{}
	);

	//Target is defined, styles will be applied directly onto it
	if(target) {
		Object.keys(styleDefinitions).forEach(styleKey => {
			const styleValue = styleDefinitions[styleKey];

			if(styleValue) {
				//Test if the key is NOT a css variable (e.g. --test: ...) and contains an uppercase letters
				if(!/^--/.test(styleKey) && /[A-Z]/.test(styleKey)) {
					//Replace camelCase text with kebab-case so it can be applied into the setProperty()
					styleKey = styleKey.replace(/[A-Z]/g, x => '-' + x[0].toLowerCase());
				}

				let value = styleValue;
				let important = '';

				//Test for important delimiter ("!important"|"!undefined"), defaults to ''
				if(value.includes('!')) {
					[important, value] = value.split('!');
					important = important.trim();

					//Convert "undefined" to undefined type
					if(important === "undefined") {
						important = undefined;
					}
				}

				target.style.setProperty(styleKey.trim(), value.trim(), important);
			}
		});
	}

	//Target is not defined, styles will be returned in form of an Object

	let result = {};
	Object.keys(styleDefinitions).forEach(styleKey => {
		let styleValue = styleDefinitions[styleKey];
		if(styleValue) {
			//Test if the key is NOT a css variable (e.g. --test: ...)
			if(!/^--/.test(styleKey)) {
				//Replace camelCase with kebab-case so it can be used either as a style variable (.style.something = ...)
				// or for a framework like React which accepts only the cameCase
				styleKey = styleKey.replace(/(-.)/g, x => x[1].toUpperCase()).trim();
			}
			result[styleKey] = styleValue.trim();
		}
	});

	return result;
};
