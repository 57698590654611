import { IS } from "../IS";
import { BatchGroup } from "./BatchGroup";

export class BatchManager {
	constructor() {
		this._groups = [];
	}

	/**
	 * Add
	 * ---
	 * Adds a promise(s) to the appropriate group according to the identifier
	 * @param {*} identifier
	 * @param {AdvancedPromise} promises
	 * @return {AdvancedPromise[]}
	 */
	add(identifier, ...promises) {
		let group = this._findGroup(identifier);
		if(group) {
			return group.add(...promises);
		}
		else {
			group = new BatchGroup(identifier, ...promises);
			this._groups.push(group);

			group.onResolved((ident) => {
				let index = this._findGroup(ident, true);
				this._groups.splice(index, 1);
			});

			return promises;
		}
	}

	/**
	 * Find
	 * ---
	 * Finds a group of promises according to the identifier
	 * @param {*} identifier
	 * @return {BatchGroup}
	 */
	find(identifier) {
		return this._findGroup(identifier);
	}

	/**
	 * @private
	 * Find group
	 * ---
	 * @param {*} identifier
	 * @param {boolean} findIndex
	 * @return {BatchGroup}
	 */
	_findGroup(identifier, findIndex = false) {
		return this._groups[findIndex ? "findIndex" : "find"](group => IS.equal(group.identifier, identifier));
	}
}
