import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import { ThemeContext } from "./Theme/ThemeProvider";
import { Fragment } from "react";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import { Link } from "preact-router";
import Fa from "./Fa";
import ButtonsConstructor from "./input/Button/ButtonsConstructor";

class Header extends Component {
	render({className, style, breadcrumbs}, {}) {
		return (
			<ThemeContext.Consumer>
				{({getLogo, getTitle}) => (
					<header className={combineClasses("header", className)} style={style}>
						<Link href={(breadcrumbs[breadcrumbs.length - 2] || {href: APP_ROUTE.BASE}).href} className={"logo-wrapper"}>
							{
								breadcrumbs.length > 0 ? <Fa icon={"arrow-alt-left"} className={"back-icon"}/> : getLogo()
							}
						</Link>

						<div className={"header-content"}>
							<div className={"breadcrumbs"}>
								{[
									{
										text: getTitle(),
										href: APP_ROUTE.BASE,
									},
									...breadcrumbs,
								].map((breadcrumb, i) => (
									<Fragment key={i}>
										<Link href={breadcrumb.href}>
											{breadcrumb.text}
										</Link>
										<div className={"separator"}/>
									</Fragment>
								))}
							</div>

							<div className={"action-buttons"}>
								<ButtonsConstructor
									buttons={[
										{
											icon: "cog",
											className: "open-site-settings contained round",
											action: () => window.siteSettings.open(),
										}
									]}
									sharedProps={{accent: "secondary"}}
								/>
							</div>
						</div>
					</header>
				)}
			</ThemeContext.Consumer>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			breadcrumbs: PropTypes.array,
		}
	}

	static get defaultProps() {
		return {
			breadcrumbs: [],
		}
	}
}

export default Header;
