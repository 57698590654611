export class RequestHeaders {
	static json(token, additionalHeaders) {
		const headers = {
			"Content-Type": 'application/json; charset=utf-8',
			...additionalHeaders,
		};

		if(token) {
			headers["x-auth-token"] = token;
		}

		return new Headers(headers);
	}

	static file(token, additionalHeaders) {
		const headers = {
			...additionalHeaders,
		};

		if(token) {
			headers["x-auth-token"] = token;
		}

		return new Headers(headers);
	}

	static urlEncoded(token, additionalHeaders) {
		const headers = {
			"Content-type": 'application/x-www-form-urlencoded',
			...additionalHeaders,
		};

		if(token) {
			headers["x-auth-token"] = token;
		}

		return new Headers(headers);
	}
}
