import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses, css, EventListeners, get, hasParent, IS, ObjectUtils, ParentDomUtils, resolvePolymorphVar } from "@green24/npm-javascript-utils";
import withInput from "./hoc/withInput";
import Fa from "../Fa";
import { createPortal } from "preact/compat";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";
import ButtonsConstructor from "./Button/ButtonsConstructor";

class Dropdown extends Component {
	constructor(props) {
		super();

		this.state = {
			opened: props.opened,
		};

		this._eventListeners = new EventListeners();
		this._valueUpdateListeners = new ValueUpdateListeners(this);
		this._inputRef = null;
		this._optionsRef = null;
	}

	componentDidMount() {
		this._valueUpdateListeners.add("state.opened", opened => {
			if(opened) {
				this._eventListeners.add(window, "mousedown", e => {
					if(!hasParent(e.target, this._inputRef, this._optionsRef)) {
						this.setState({opened: false});
					}
				});

				this._eventListeners.add(ParentDomUtils.findScrollParent(this._inputRef), "scroll", e => {
					this._positionDropdownOptions();
				});

				this._eventListeners.add(window, "resize", e => {
					this._positionDropdownOptions();
				});

				this._positionDropdownOptions();
			}
			else {
				this._eventListeners.clear();
			}
		});
	}

	componentDidUpdate(previousProps, previousState) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	componentWillUnmount() {
		this._eventListeners.clear();
	}

	render({className, style, value, options, onOption, onLabel, onModify, onIsActive, closeOnSelect, contentProps}, {opened}) {
		const activeOption = options.find(option => onIsActive(option, value));

		return (
			<section
				className={combineClasses("dropdown input", className)}
				style={style}

				ref={ref => this._inputRef = ref}
			>
				<div
					className={"input-wrapper"}

					onClick={() => this.setState({opened: true})}
				>
					<ButtonsConstructor buttons={[
						{
							text: " ",
							textTranslated: true,
							...(activeOption && this._processOption(activeOption)),
							className: "input",
							action: null,
						}
					]} sharedProps={{className: "no-style"}}/>

					<Fa icon={"caret-down"}/>
				</div>

				{
					opened &&
					createPortal(
						<section
							className={combineClasses("dropdown-content", contentProps.className)}

							ref={ref => this._optionsRef = ref}
						>
							<div className={"options"}>
								<ButtonsConstructor
									buttons={options.map(option => this._processOption(option))}
									sharedProps={{className: "no-style"}}
								/>
							</div>
						</section>,
						document.querySelector("#dropdownRoot")
					)
				}
			</section>
		);
	}

	_processOption(option) {
		const {onOption, onIsActive, onModify, closeOnSelect, value} = this.props;

		const externalOptionData = onOption(option);
		const isActive = onIsActive(option, value);

		const defaultButtonOptions = {
			className: "option",
		};

		return {
			className: resolvePolymorphVar(
				externalOptionData.className,
				{
					function: f => f(defaultButtonOptions.className),
				},
				() => combineClasses(externalOptionData.className, defaultButtonOptions.className),
				true
			),
			...externalOptionData,
			active: isActive,
			action: () => {
				if(!isActive) {
					onModify(option, undefined, true);

					if(closeOnSelect) {
						this.setState({opened: false});
					}
				}
			},
		};
	}

	_positionDropdownOptions() {
		if(this._optionsRef && this._inputRef) {
			let {style, side} = this._computeDropdownPosition(this._inputRef, this._optionsRef);

			style = ObjectUtils.mapAsObject(style, (key, value) => ({
				key,
				value: IS.number(value) ? value + "px" : value,
			}));

			css(style, this._optionsRef);
			this._optionsRef.setAttribute("data-side", side);
		}
	}

	_computeDropdownPosition = (triggerRef, contentRef, ignoreTriggerSizeLimits = false) => {
		if(triggerRef && contentRef) {
			let triggerRect = triggerRef.getBoundingClientRect();
			let contentRect = contentRef.getBoundingClientRect();
			let {innerHeight, innerWidth} = window;
			let availableSpaceOnBottom = innerHeight - triggerRect.bottom;
			let availableSpaceOnTop = innerHeight - (innerHeight - triggerRect.top);

			let side = "bottom";
			let contentStyle = {
				top: triggerRect.bottom,
				left: triggerRect.left,
				width: triggerRect.width,
				maxWidth: innerWidth - (ignoreTriggerSizeLimits ? 0 : triggerRect.left),
			};

			//If content can fit under the trigger
			if(contentStyle.top + contentRect.height > innerHeight) {
				//If available space on top is bigger then on the bottom
				if(availableSpaceOnTop > availableSpaceOnBottom) {
					//Preferred align is top
					side = "top";
					contentStyle = {
						...contentStyle,
						top: null,
						bottom: innerHeight - triggerRect.top,
						maxHeight: availableSpaceOnTop,
					}
				}
				else {
					//Restrict content height
					contentStyle = {
						...contentStyle,
						maxHeight: availableSpaceOnBottom,
					}
				}
			}

			return {
				style: contentStyle,
				side,
			};
		}
		return {style: {}, side: null};
	};

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			options: PropTypes.array.isRequired,
			closeOnSelect: PropTypes.bool,
			contentProps: PropTypes.object,

			onOption: PropTypes.func,
			onLabel: PropTypes.func,
			onIsActive: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			opened: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			options: [],
			closeOnSelect: true,

			onOption: option => null,
			onLabel: v => v,
			onIsActive: (option, value) => get(option, "id", option) === get(value, "id", value),
			contentProps: {},
		}
	}
}

export default withInput(Dropdown);
