import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import { createContext, createPortal } from "preact/compat";
import { ValueUpdateListeners } from "../../class/ValueUpdateListeners";
import { ComponentUtils } from "../../class/ComponentUtils";
import { RIS } from "../../class/ReactIS";

export let ModalContext = createContext();
export const setModalContext = context => ModalContext = context;

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			opened: props.opened,
		};

		if(this.state.opened) {
			props.onOpened();
		}

		this._toBeClosedTimeout = null;
		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.add("props.opened", opened => {
			clearTimeout(this._toBeClosedTimeout);

			if(opened) {
				this.setState({opened});
			}
			else {
				this.setState({toBeClosed: true});
			}
		});

		this._valueUpdateListeners.add("state.opened", opened => {
			const {onOpened, onClosed} = this.props;

			if(opened) {
				onOpened();
			}
			else {
				onClosed();
			}
		});

		this._valueUpdateListeners.add("state.toBeClosed", close => {
			clearTimeout(this._toBeClosedTimeout);

			if(close) {
				const {closingDuration} = this.props;

				if(closingDuration > 0) {
					this._toBeClosedTimeout = setTimeout(() => {
						this.setState({opened: false});
					}, closingDuration);
				}
				else {
					this.setState({opened: false});
				}
			}
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	_renderModal({className, style, children, accent}, {toBeClosed, opened}) {
		const modalContext = {
			close: () => this.close(),
		};

		return (
			<section
				style={style}
				className={combineClasses(
					"modal-wrapper",
					className,
					opened && "opened",
					toBeClosed && "close",
					accent && accent,
				)}
			>
				<div className={"shadow"} onClick={() => modalContext.close()}/>

				<ModalContext.Provider value={modalContext}>
					{
						ComponentUtils.propagateProps(children, {
							className: combineClasses(children.props.className, "modal"),
							modalContext: RIS.customComponent(children) ? modalContext : undefined,
							style: {...children.props.style, ...style}
						}, true)
					}
				</ModalContext.Provider>
			</section>
		);
	}

	render({portalTarget}, {opened}) {
		if(!opened) return null;

		const modal = this._renderModal(this.props, this.state);

		if(portalTarget) {
			return createPortal(modal, document.querySelector(portalTarget));
		}

		return modal;
	}

	close() {
		this.setState({toBeClosed: true});
	}

	static get sharedTypes() {
		return {
			opened: PropTypes.bool,
		}
	}

	static get propTypes() {
		return {
			...Modal.sharedTypes,
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.element,

			portalTarget: PropTypes.string,

			accent: PropTypes.string,

			closingDuration: PropTypes.number,

			onOpened: PropTypes.func,
			onClosed: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...Modal.sharedTypes,
			toBeClosed: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			onOpened: () => null,
			onClosed: () => null,
			closingDuration: 100,
			portalTarget: "#modalRoot",
		}
	}
}

export default Modal;
