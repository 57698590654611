import { Component } from "preact";
import PropTypes from "prop-types";
import { ArrayUtils, combineClasses, E_Modification, IS } from "@green24/npm-javascript-utils";
import { Fragment } from "react";
import Fa from "../../components/shared/Fa";
import RadioGroup from "../../components/shared/input/RadioGroup";
import Checkbox from "../../components/shared/input/Checkbox";
import TooltipIcon from "../../components/shared/TooltipIcon";
import Dropdown from "../../components/shared/input/Dropdown";
import ImageFrame from "../../components/shared/ImageFrame";
import FeatureCost from "../../components/shared/FeatureCost";

class ProductFeaturesController extends Component {
	_renderDescription(option) {
		if(option.description) {
			return (
				<span className={"description"}>
					<TooltipIcon>
						{option.description}
					</TooltipIcon>
				</span>
			);
		}
	}

	_renderAdditionalCost(option) {
		if(IS.defined(option.cost) && option.cost != 0) {
			return (
				<div className={"price"}>
					<FeatureCost feature={option}/>
				</div>
			);
		}
	}

	_renderOption(option) {
		return (
			<div className={"option-content"}>
				<ImageFrame src={option.links.icon}/>

				<label>{option.title}</label>
			</div>
		)
	}

	_renderOptions({options, selectionType, id}) {
		const {onModify, featuresStates} = this.props;

		const __getSuffix = (option) => (
			<>
				{this._renderDescription(option)}

				{this._renderAdditionalCost(option)}
			</>
		);

		switch (selectionType) {
			case "RADIO":
				return (
					<RadioGroup
						value={featuresStates[id][0]}
						options={
							options.map(option => {
								return {
									id: option.id,
									className: "option",
									text: this._renderOption(option),
									value: option.id,
									outerSuffix: __getSuffix(option),
									disabled: option.disabled,
								}
							})
						}
						onImmediateModify={(value) => onModify(id, value)}
					/>
				);
			case "CHECKBOX":
				return options.map(option => {
					const isActive = featuresStates[id].includes(option.id);

					return (
						<Fragment key={option.id}>
							<Checkbox
								className={"option"}
								text={this._renderOption(option)}
								value={isActive}
								onImmediateModify={() => onModify(id, option.id, isActive ? E_Modification.ARRAY_SPLICE : E_Modification.ARRAY_PUSH)}
								suffix={__getSuffix(option)}
								disabled={option.disabled}
							/>
						</Fragment>
					)
				});
			case "DROPDOWN":
				const activeID = String(featuresStates[id][0]);
				return (
					<Dropdown
						className={combineClasses(options.some(option => option.links.icon) && "with-icons")}
						options={options}
						value={ArrayUtils.findByID(options, activeID)}
						contentProps={{
							className: combineClasses(
								"product-features-controller-dropdown",
								options.some(option => option.links.icon) && "with-icons"
							)
						}}

						onOption={option => {
							return {
								id: option.id,
								text: this._renderOption(option),
								outerSuffix: __getSuffix(option),
								disabled: option.disabled,
							}
						}}
						onValue={option => option.id}
						onLabel={option => option && option.title}

						onImmediateModify={(value) => onModify(id, value)}
					/>
				);
			default:
				console.warn("No group input handler detected for type:", selectionType);
				return options.map(option => this._renderOption(option));
		}
	}

	_renderGroup(group) {
		return (
			<div className={"group"}>
				<div className={"group-icon"}>
					{
						group.icon &&
						<Fa icon={group.icon}/>
					}
				</div>

				<div className={"group-content"}>
					<h4>{group.title}</h4>

					<div className={"options"}>
						{this._renderOptions(group)}
					</div>
				</div>
			</div>
		);
	}

	_renderGroups(groups) {
		return groups.map(group => {
			return (
				<Fragment key={group.id}>
					{this._renderGroup(group)}
				</Fragment>
			)
		})
	}

	render({className, style, optionGroups}, {}) {
		return (
			<section className={combineClasses("product-features-controller", className)} style={style}>
				{this._renderGroups(optionGroups || [])}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,

			optionGroups: PropTypes.array,
			featuresStates: PropTypes.object,

			onModify: PropTypes.func.isRequired,
		}
	}
}

export default ProductFeaturesController;
