import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import Fa from "../Fa";
import { E_Project } from "../../../models/constants/Enums_Project";
import withProject from "../../other/project/hoc/withProject";
import { css } from "@green24/npm-javascript-utils";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";

export const ThemeContext = createContext();

class ThemeProvider extends Component {
	constructor() {
		super();

		this._valueUpdateListeners = new ValueUpdateListeners(this);
		this._valueUpdateListeners.getValueWithUpdateListener("props.project.links.color", (themeColors) => {
			let [accentColor, onAccentColor] = (themeColors || '').split(';');

			css({
				"--accent-color": accentColor,
				"--on-accent-color": onAccentColor,
				"--primary-color": accentColor,
				"--on-primary-color": onAccentColor,
			}, document.documentElement);
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	render({children, theme, project}, {}) {
		const themeData = {
			theme,
			getLogo: () => this._getLogo(theme),
			getTitle: () => project.name || this._getTitle(theme),
		};

		return (
			<ThemeContext.Provider value={themeData}>
				{children}
			</ThemeContext.Provider>
		);
	}

	_getLogo(theme) {
		switch (theme) {
			case E_Project.NIKOLA:
				return <Fa icon={"nikola-icon"}/>;
		}
	}

	_getTitle(theme) {
		switch (theme) {
			case E_Project.NIKOLA:
				return "Nikola";
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			theme: PropTypes.string,

			//withProject
			projectContext: PropTypes.object,
			project: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			theme: E_Project.NIKOLA,
		}
	}
}

export default withProject(ThemeProvider);
