import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import ButtonComponent from "./Button/ButtonComponent";

class ToggleButton extends Component {
	render({className, style, value, onModify, prefix, suffix, ...props}, {}) {
		return (
			<section className={combineClasses("toggle-button", !!value && "toggled", className)} style={style}>
				{prefix}
				<ButtonComponent
					{...props}
					icon={
						<div className={"toggle-rail-wrapper"}>
							<div className={"toggle-rail"}>
								<div className={"toggle-handle"}/>
							</div>
						</div>
					}
					onClick={() => onModify(!value)}
					className={combineClasses(props.className, "no-style")}
				/>
				{suffix}
			</section>
		);
	}

	static get propTypes() {
		return {
			...ButtonComponent.propTypes,

			value: PropTypes.any,
			prefix: PropTypes.any,
			suffix: PropTypes.any,

			onModify: PropTypes.func,
		}
	}
}

export default ToggleButton;
