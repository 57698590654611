import { cloneElement, Component, toChildArray } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";

class WidestContent extends Component {
	render({className, style, children, visibilityControlStyle}, {}) {
		const hasVisibilityController = toChildArray(children).some(
			child => child.props.visible || child.props["data-visible"]
		);

		return (
			<span
				className={combineClasses(
					"widest-content",
					visibilityControlStyle,
					className
				)}
				style={style}
			>
				{hasVisibilityController ? children : toChildArray(children).map((child, i) => {
					if(i == 0) {
						return cloneElement(
							child,
							{
								"data-visible": '',
							}
						);
					}

					return child;
				})}
			</span>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			visibilityControlStyle: PropTypes.oneOf(["opacity", "visibility"]),
		}
	}

	static get defaultProps() {
		return {
			visibilityControlStyle: "opacity",
		}
	}
}

export default WidestContent;
