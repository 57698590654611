import React from "react";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import ButtonsConstructor from "./input/Button/ButtonsConstructor";
import { Interpret } from "../components";
import { ErrorUtils } from "../../utils/ErrorUtils";
import Fa from "./Fa";
import { ComponentUtils } from "@green24/npm-preact-utils";

class ErrorBlock extends React.Component {
	render({className, style, error, errorOverrides, dataOverrides, onRetry, children, actionButtons, data}) {
		data = data || this._processError(error, errorOverrides[error.statusCode], dataOverrides);

		const retryButton = {
			icon: "sync-alt",
			text: "retry",
			elevation: 1,
			action: () => onRetry(),
		};

		const defaultButtons = [
			data.retry && onRetry && retryButton,
		];

		return (
			<section className={combineClasses("error-block", className)} style={style}>
				<Fa icon={data.icon || "exclamation-triangle"} className={"icon"}/>

				<h1>{data.titleTranslated ? data.title : <Interpret id={data.title} params={data.titleTranslationParams}/>}</h1>

				<p>{data.messageTranslated ? data.message : <Interpret id={data.message} params={data.messageTranslationParams} fallback={data.message}/>}</p>

				<ButtonsConstructor buttons={ComponentUtils.resolvePolymorphProp(
					actionButtons,
					{
						array: arr => [...defaultButtons, ...arr],
						function: f => f({data, defaultButtons, retryButton}),
					},
					() => defaultButtons,
				)}/>

				{children}
			</section>
		);
	}

	_processError(error, errorOverrides, dataOverrides) {
		return ErrorUtils.getErrorFromResponse(error, errorOverrides, dataOverrides);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			/**
			 * @type HttpResponseError
			 */
			error: PropTypes.object.isRequired,
			errorOverrides: PropTypes.object,
			dataOverrides: PropTypes.object,
			onRetry: PropTypes.func,
			actionButtons: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			errorOverrides: {},
			dataOverrides: {},
			onRetry: null,
		}
	}
}

export default ErrorBlock;
