import { EventListeners } from "../EventListeners";
import { Route } from "./Route";
import { Logger } from "../Logger";
import { QS } from "../QueryStringManager";
import { IS } from "../IS";
import { AdvancedPromise } from "../AdvancedPromise";
import { uSID } from "../../functions/generic";
import { RouterHistory } from "./RouterHistory";

const logger = new Logger("router");

const CUSTOM_ROUTER_GO_BACK_EVENT_NAME = "router-go-back";
const CUSTOM_ROUTE_EVENT_NAME = "router-redirect";

export class Router {
	constructor(
		{
			overrideLinks = true,
			readyOnMount = false,
		} = {}
	) {
		this._routerHistory = new RouterHistory();
		this._browserHistory = new RouterHistory();

		this._routes = [];
		this._prevPath = Router.currentPath;

		this._eventListeners = new EventListeners();

		this._eventListeners.add(window, "popstate", e => this._handlePopState(e));
		this._eventListeners.add(window, CUSTOM_ROUTE_EVENT_NAME, e => this._handleRouteChange(e, true));
		this._eventListeners.add(window, CUSTOM_ROUTER_GO_BACK_EVENT_NAME, e => this._handleGoBackEvent(e));

		if(overrideLinks) {
			this._eventListeners.add(document, "click", e => {
				if(e.metaKey || e.ctrlKey || e.shiftKey) return;
				if(e.defaultPrevented) return;

				const path = (e.composedPath() || []);
				const target = path.find(el => el.matches && el.matches('a'));

				if(target?.matches?.("a")) {
					if(target.hasAttribute('download') || target.getAttribute('rel') === 'external') return;
					if(target.matches("[native]")) return;

					if(target.hasAttribute("back")) {
						e.preventDefault();

						Router.goBack();
					}

					if(target.hasAttribute("back-to") || target.hasAttribute("data-back-to")) {
						e.preventDefault();

						Router.goBackTo(
							target.getAttribute("back-to") || target.getAttribute("data-back-to"),
							{
								fallback: target.getAttribute("back-to-fallback") || target.getAttribute("data-back-to-fallback"),
							}
						);

						return;
					}

					let href = target.href;
					if(!href) return;

					try {
						if(new URL(href).host != window.location.host) return;
					}
					catch (e) {}

					// Check for "mailto:", "tel:", etc. in the href
					if(href.includes('mailto:') || href.includes("tel:")) return;

					e.preventDefault();

					if(target.getAttribute("href").charAt(0) == '#' || target.hasAttribute("from-here")) {
						href = window.location.pathname + target.getAttribute("href");
					}

					if(target.hasAttribute("attach-query") && !href.includes('?')) {
						href+= window.location.search;
					}

					if(target.hasAttribute("attach-hash") && !href.includes('#')) {
						href+= window.location.hash;
					}

					target.dispatchEvent(new CustomEvent("exit", {detail: href}));

					Router.redirect(href);
				}
			});
		}

		if(readyOnMount) {
			this.ready();
		}
	}

	/**
	 * On
	 * ---
	 * @param {string} path
	 * @param {string|function(): void} response
	 */
	on(path, response) {
		this._routes.push(new Route(path, {
			response,
			exit: false,
		}));
	}

	onExit(path, response) {
		this._routes.push(new Route(path, {
			response,
			exit: true,
		}));
	}

	ready() {
		console.log(window.history.state?.id);
		if(window.history.state?.id) {
			this._routerHistory.addState(window.history.state.id, Router.currentPath);
			this._browserHistory.addState(window.history.state.id, Router.currentPath);
		}
		else {
			const id = uSID();
			console.log(id);
			window.history.replaceState({id, a: 1}, "", Router.currentPath);
			this._routerHistory.addState(id, Router.currentPath);
			this._browserHistory.addState(id, Router.currentPath);
		}

		this._resolveEntryRoutes(Router.currentPath, undefined);
	}

	_handlePopState(e) {
		this._browserHistory.handlePopState(e);

		const {undo, redo} = this._browserHistory.history;
		// console.log("pop", [...undo], [...redo]);
		this._routerHistory.setHistory(undo, redo);

		this._handleRouteChange({detail: null});
	}

	_handleRouteChange({detail}, registerRedirectToHistory = true) {
		const prevPath = this._prevPath;
		const currentPath = Router.currentPath;

		logger.isTypeAllowed("info") && console.log("Route changed", prevPath, currentPath);

		this._resolveExitRoutes(prevPath, currentPath).then(() => {
			//If the _handleRouteChange was instigated from a popstate listener (detail == null), it should skip this process
			if(detail?.replace !== undefined) {
				if(detail.replace) {
					//The state is already replaced so there is no additional action needed

					if(registerRedirectToHistory && detail?.historyEntry && this._prevPath) {
						detail.routerHistory && this._routerHistory.addReplaceState(detail.id, currentPath);
						detail.browserHistory && this._browserHistory.addReplaceState(detail.id, currentPath);
					}
				}
				else {
					const id = uSID();
					console.log(id, detail.prevID);

					//Rollback a previous state so that the history has a correct path when the user will want to go back
					window.history.replaceState({id: detail.prevID, b: 1}, "", this._prevPath);

					//Add a new state from the redirect
					window.history.pushState({id, c: 1}, "", currentPath);

					if(registerRedirectToHistory && detail?.historyEntry && this._prevPath) {
						detail.routerHistory && this._routerHistory.addPushState(id, currentPath);
						detail.browserHistory && this._browserHistory.addPushState(id, currentPath);
					}
				}
			}

			//Must update _prevPath before processing the entry routes, because if the entry route has a string response it would use an outdated data.
			// Note, string response is supposed to redirect to an another path.
			this._prevPath = currentPath;

			this._resolveEntryRoutes(currentPath, prevPath);
		}, routeInstance => {
			logger.isTypeAllowed("info") && console.log(`Route to "${currentPath}" prevented by: `, routeInstance);

			//Return to the previous state
			window.history.replaceState({id: detail.prevID, d: 1}, "", this._prevPath);
		});
	}

	_resolveEntryRoutes(path, prevPath) {
		const relevantRoutes = this._findRelevantRoutes(path);
		if(!relevantRoutes.length) return;

		let i = 0;
		const __next = () => {
			i++;

			if(i < relevantRoutes.length) {
				__processRoute();
			}
		};

		const __processRoute = () => {
			const route = relevantRoutes[i];

			const response = route.getResponse({
				params: route.extractParams(path),
				query: QS(window.location.search).toObject(true),
				prevPath,
			}, __next);

			if(IS.string(response)) {
				Router.redirect(response, {replace: true});
			}
			else if(response instanceof Promise || response instanceof AdvancedPromise) {
				response.catch(() => __next());
			}
		};

		__processRoute();
	}

	_resolveExitRoutes(prevPath, nextPath) {
		const relevantRoutes = this._findRelevantRoutes(prevPath, true);
		if(!relevantRoutes.length) return Promise.resolve();

		return new Promise((resolve, reject) => {
			let i = 0;
			const __next = () => {
				i++;

				if(i < relevantRoutes.length) {
					__processRoute();
				}
				else {
					resolve();
				}
			};

			const __processRoute = () => {
				const route = relevantRoutes[i];

				const response = route.getResponse({
					nextPath,
				}, __next);

				if(response instanceof Promise || response instanceof AdvancedPromise) {
					response.then(__next, reject);
				}
			};

			__processRoute();
		});
	}

	_findRelevantRoutes(path, exitOnly = false) {
		return this._routes.filter(routeInstance => (exitOnly ? routeInstance.isExitRoute : true) && routeInstance.isSamePath(path));
	}

	_handleGoBackEvent({detail}) {
		if(this._routerHistory.canUndo) {
			if(detail.after) {
				const {path: undoUrl, id: stateID} = this._routerHistory.getFurthestUndoStateToPath(detail.after) || {};

				if(undoUrl) {
					Router.redirect(undoUrl, {historyEntry: true, routerHistory: false});

					this._routerHistory.goBackToByID(stateID);
				}
			}
			else if(detail.to) {
				const {path: undoUrl, id: stateID} = this._routerHistory.getClosestUndoStateToPath(detail.to) || {};

				if(undoUrl) {
					Router.redirect(undoUrl, {historyEntry: true, routerHistory: false});

					this._routerHistory.goBackToByID(stateID);
				}
			}
			else {
				const {path: backUrl, id: stateID} = this._routerHistory.getUndoState();

				Router.redirect(backUrl);

				this._routerHistory.goBackToByID(stateID);
			}

			return;
		}

		console.log(`Route for expression ${detail.to} not found, ${detail.fallback} was used as a fallback.`);//TODO: Remove
		return;//TODO: Remove

		if(detail.fallback) {
			logger.isTypeAllowed("error") && console.error(`Route for expression "${detail.to}" not found. "${detail.fallback}" was used as a fallback.`);
			Router.redirect(detail.fallback);
		}
		else {
			logger.isTypeAllowed("error") && console.error(`Route for expression "${detail.to}" not found and no fallback was defined.`);
		}
	}

	static get currentPath() {
		return window.location.pathname + window.location.search + window.location.hash;
	}

	static redirect(to, {replace = false, historyEntry = true, routerHistory = historyEntry, browserHistory = historyEntry} = {}) {
		const id = uSID();
		const prevID = window.history.state?.id;

		//Always replace state at this stage, if not prevented by the exit routes, add a push state later if replace = false
		window.history.replaceState({id, e: 1}, "", to);

		window.dispatchEvent(new CustomEvent(CUSTOM_ROUTE_EVENT_NAME, {detail: {to, replace, historyEntry, routerHistory, browserHistory, prevID, id}}));
	}

	static goBack(fallback = undefined) {
		window.dispatchEvent(new CustomEvent(CUSTOM_ROUTER_GO_BACK_EVENT_NAME, {detail: {fallback}}));
	}

	static goBackTo(to, {fallback = undefined} = {}) {
		window.dispatchEvent(new CustomEvent(CUSTOM_ROUTER_GO_BACK_EVENT_NAME, {detail: {to, fallback}}));
	}

	static goBackAfter(url, {fallback = undefined} = {}) {
		window.dispatchEvent(new CustomEvent(CUSTOM_ROUTER_GO_BACK_EVENT_NAME), {detail: {after: url, fallback}});
	}
}
