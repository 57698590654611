import { Component } from 'preact';
import PropTypes from "prop-types";
import { createContext } from "preact/compat";

export let ThemeComponentsContext = createContext();
export const setThemeComponentsContext = context => ThemeComponentsContext = context;

class ThemeComponentsProvider extends Component {
	render({components, children}, {}) {
		return (
			<ThemeComponentsContext.Provider value={components}>
				{children}
			</ThemeComponentsContext.Provider>
		);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			/**
			 * @type Object<string, function>
			 */
			components: PropTypes.object,
		}
	}
}

export default ThemeComponentsProvider;
