import { ArrayUtils, E_Modification, EO } from "@green24/js-utils";

export class ComponentStateUtils {
	static toggleBoolean(target, key) {
		target.setState(state => ({[key]: !state[key]}));
	}

	static removeIndex(target, key, index) {
		target.setState(state => {
			return {
				[key]: ArrayUtils.removeIndex(state[key], index, true, true),
			};
		});
	}

	static modify(target, key, value, path, modificationType = E_Modification.ITEM_SET) {
		target.setState(state => {
			const newData = EO(state[key]).clone();
			newData.modifyV2(value, path, modificationType);

			return {
				[key]: newData,
			}
		});
	}

	static toggleItemInArray(target, key, item, matchResolver = ({item1, item2}) => item1 == item2) {
		target.setState(state => {
			if(
				state[key].some(
					(item2, i) => matchResolver({item1: item, item2, i})
				)
			) {
				return {
					[key]: state[key].filter(
						(item2, i) => !matchResolver({item1: item, item2, i})
					),
				}
			}

			return {
				[key]: [...state[key], item],
			}
		});
	}

	static offsetNumber(target, key, offset = 1) {
		target.setState(state => {
			return {
				[key]: state[key] + offset,
			}
		});
	}
}
