import { get } from "../functions/generic";

const regexCharacters = /[^a-zA-Z]/g;
const regexNumbers = /[^0-9]/g;

export class SortRules {
	static idDesc(a, b) {
		return SortRules.propertyDesc(a, b, "id");
	}

	static idAsc(a, b) {
		return -1 * SortRules.idDesc(a, b);
	}

	/**
	 * Name DESC
	 * ---
	 * Z -> A, 9 -> 0
	 * @param {Object} a
	 * @param {Object} b
	 * @return {-1|0|1}
	 */
	static nameDesc(a, b) {
		return SortRules.alphaNumericDesc(a, b);
	}

	/**
	 * Name ASC
	 * ---
	 * A -> Z, 0 -> 9
	 * @param {Object} a
	 * @param {Object} b
	 * @return {-1|0|1}
	 */
	static nameAsc(a, b) {
		return -1 * SortRules.nameDesc(a, b);
	}

	/**
	 * Property DESC
	 * @param {Object} a
	 * @param {Object} b
	 * @param propertyPath
	 * @return {-1|0|1}
	 */
	static propertyDesc(a, b, propertyPath) {
		return get(b, propertyPath, 0) - get(a, propertyPath, 0);
	}

	/**
	 * Property ASC
	 * ---
	 * @param {Object} a
	 * @param {Object} b
	 * @param propertyPath
	 * @return {-1|0|1}
	 */
	static propertyAsc(a, b, propertyPath) {
		return -1 * SortRules.propertyDesc(a, b, propertyPath);
	}

	static alphaNumericDesc(a, b) {
		const aString = a.replace(regexCharacters, "");
		const bString = b.replace(regexCharacters, "");
		if (aString === bString) {
			const aNumbers = parseInt(a.replace(regexNumbers, ""), 10);
			const bNumbers = parseInt(b.replace(regexNumbers, ""), 10);
			return aNumbers === bNumbers ? 0 : aNumbers > bNumbers ? 1 : -1;
		}
		return aString > bString ? 1 : -1;
	}

	static alphaNumericAsc(a, b) {
		return -1 * SortRules.alphaNumericDesc(a, b);
	}
}
