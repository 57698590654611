import { cloneDeep } from "../functions/lodash-cloneDeep";
import { IS } from "./IS";
import { merge } from "../functions/lodash-merge";

export class StoreHelpers {
	static deepClone(obj) {
		return cloneDeep(obj);
	}

	/**
	 * @deprecated
	 * Merge objects (b into a)
	 * ---
	 * Helper function, merges two objects.
	 * ```
	 *  a={prop1:10, prop2:{prop3:30}}
	 *  b={prop1:20, prop2:{prop4:40}}
	 *
	 *  object.assign(a,b) => {prop1:10, prop2:{prop4:40}} //Shallow merge
	 *
	 *  mergeObjects(a,b) => {prop1:20, prop2:{prop3:30, prop4:40}} //Recursive merge
	 * ```
	 * @param {Object} a Target object; All properties from **b** will be set here
	 * @param {Object} b Source object; Source of all properties to merge
	 * @param {function({a: Object, b: Object, key: String})} recursionPassHandle This handle controls if the merge should go deeper when nested object is found.
	 * @param {function(value)} preAssignHandle Is triggered every time before setting the value to Object **a**
	 */
	static mergeObjects(a, b, recursionPassHandle = () => true, preAssignHandle = v => v) {
		for(const bKey in b) {
			if(IS.ownProperty(b, bKey)) {
				// If a.property does not exist or is a value (string, number, boolean), replace it with b.property.
				// NB - we merge {b} into {a} even if a[prop] already equals b[prop] (checking first is slower than just
				// making the change).
				if(!IS.ownProperty(a, bKey) || (typeof (a[bKey]) !== "object")) {
					a[bKey] = preAssignHandle(b[bKey]);
				}
				// If we have a nested level, recurse down to the nested level to merge children.
				if(typeof (b[bKey]) === "object" && recursionPassHandle({a, b, key: bKey})) {
					StoreHelpers.mergeObjects(a[bKey], b[bKey]);
				}
			}
		}
		return a;
	}

	/**
	 * Deep merge (b into a)
	 * ---
	 * Helper function, merges two objects.
	 * ```
	 *  a={prop1:10, prop2:{prop3:30}}
	 *  b={prop1:20, prop2:{prop4:40}}
	 *
	 *  object.assign(a,b) => {prop1:10, prop2:{prop4:40}} //Shallow merge
	 *
	 *  mergeObjects(a,b) => {prop1:20, prop2:{prop3:30, prop4:40}} //Recursive merge
	 * ```
	 * @param {Object} a Target object; All properties from **b** will be set here
	 * @param {Object} b Source object; Source of all properties to merge
	 * @param {Boolean} immutable If true, **a** will be cloned before the merge in order to prevent merge mutations on source object
	 * @param {function(continueSymbol, key, aValue, bValue, a, b, stack): contiueSymbol|*} customizer
	 */
	static deepMerge(a, b, immutable = false, customizer) {
		if(immutable) {
			return merge(StoreHelpers.deepClone(a), b, customizer);
		}
		return merge(a, b, customizer);
	}
}
