import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses, EventListeners } from "@green24/npm-javascript-utils";
import { ProductUtils } from "../../utils/ProductUtils";
import Tippy from "../../components/shared/Tippy";
import Fa from "../../components/shared/Fa";
import { Interpret, Price } from "../../components/components";
import { FeatureCustomizerContext } from "./ProductFeatureCustomizer";

class ProductConfiguratorFooter extends Component {
	constructor() {
		super();

		this._eventListeners = new EventListeners();
	}

	componentDidMount() {
		//FFS: This could hide the footer when the input is focused
		// this._eventListeners.add(window, "focus", () => {
		// 	this.setState({isStatic: true});
		// }, {capture: true});
		//
		// this._eventListeners.add(window, "blur", () => {
		// 	this.setState({isStatic: false});
		// }, {capture: true});
	}

	componentWillUnmount() {
		this._eventListeners.clear();
	}

	render({className, style, children, showPrice, product, navigationButtons}, {isStatic}) {
		return (
			<footer className={combineClasses(
				"product-configurator-footer",
				isStatic && "static",
				className,
			)} style={style}>
				{
					showPrice &&
					product &&
					<div className={"total-price"}>
						<FeatureCustomizerContext.Consumer>
							{({enabledFeatures}) => {
								const totalPrice = ProductUtils.getTotalCost(product, enabledFeatures);
								const featuresPrice = totalPrice - product.cost;

								return totalPrice > 0 && (
									<>
										<Fa icon={"shopping-cart"}/>

										<Tippy
											options={{
												trigger: "click"
											}}
											content={
												<div>
													<label><Interpret id={"cost.base"}/></label>
													<p><Price value={product.cost}/></p>

													<label><Interpret id={"cost.additional"}/></label>
													<p><Price value={featuresPrice}/></p>
												</div>
											}
										>
											<Price value={totalPrice}/>
										</Tippy>
									</>
								)
							}}
						</FeatureCustomizerContext.Consumer>
					</div>
				}

				{children}

				<nav className={"navigation-buttons"}>
					{navigationButtons}
				</nav>
			</footer>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			product: PropTypes.object.isRequired,
			showPrice: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			showPrice: true,
		}
	}
}

export default ProductConfiguratorFooter;
