export const E_WeekDay = {
	SUNDAY: "SUNDAY",
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
};

export const E_TimeString = {
	YEAR: "YEAR",
	WEEK: "WEEK",
	DAY: "DAY",
	HOUR: "HOUR",
	MINUTE: "MINUTE",
	SECOND: "SECOND",
	MILLISECOND: "MILLISECOND",
};

export const E_TimeString_Format = {
	YEAR: 'y',
	WEEK: 'w',
	DAY: 'd',
	HOUR: 'h',
	MINUTE: 'm',
	SECOND: 's',
	MILLISECOND: "ms",
};

export const E_TimeString_FormatDuration = {
	YEAR: 31557600000,
	WEEK: 604800000,
	DAY: 86400000,
	HOUR: 3600000,
	MINUTE: 60000,
	SECOND: 1000,
	MILLISECOND: 1,
};
