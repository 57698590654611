import { Component, Fragment } from "preact";
import PropTypes from "prop-types";
import { combineClasses, get, PointerInputManager } from "@green24/npm-javascript-utils";
import Header from "../../components/shared/Header";
import ButtonsConstructor from "../../components/shared/input/Button/ButtonsConstructor";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import { apiConnector } from "../../services/api-connector";
import withProducts from "../../components/other/product/withProducts";
import { route } from "preact-router";
import ButtonComponent from "../../components/shared/input/Button/ButtonComponent";
import { Interpret, Price, WidestContent } from "../../components/components";

class ProductSelector extends Component {
	constructor(props) {
		super();

		this.state = {
			selectedProduct: get(props, "products.0"),
		};

		this._touchActive = false;
		this._pointerInputManager = new PointerInputManager();
		this._pointerInputManager.onInputModeChanged(({newMode}) => {
			this._touchActive = newMode == PointerInputManager.enum.mode.TOUCH;
		});
	}

	componentWillUnmount() {
		this._pointerInputManager.destroy();
	}

	_renderProduct(product, isSelected) {
		const {productGroup} = this.props;

		return (
			<a
				href={APP_ROUTE.CONFIGURATOR(productGroup.id, product.id)}
				className={combineClasses(
					"product",
					isSelected && "visible"
				)}
			>

				{
					product.startingCost > 0 &&
					<div className={"price"}>
						<Interpret id={"price.startsAt"} params={{
							price: (
								<b><Price value={product.startingCost} currency={product.currency} removeFractionsIfZero={true}/></b>
							)
						}}/>
					</div>
				}

				<div
					className={"background"}
					style={product.links.image &&{
						backgroundImage: `url(${apiConnector.api + product.links.image})`,
					}}
				/>

				<div className={"product-text-container"}>
					<h1 className={combineClasses(product.description && "with-description")}>{product.title}</h1>
					{
						product.description &&
						<pre>{product.description}</pre>
					}

					<div className={"action-buttons"}>
						<ButtonComponent text={"configure"} variation={"outline"}/>
					</div>
				</div>
			</a>
		);
	}

	render({className, style, products, productGroup}, {selectedProduct}) {
		return (
			<section className={combineClasses("product-selector", className)} style={style}>
				<Header breadcrumbs={[
					{
						text: productGroup.title,
						href: APP_ROUTE.PRODUCT_GROUP(productGroup.id),
					}
				]}/>

				<div className={"content"}>
					<div className={"max-width"}>
						<div className={"relative-container"}>
							{
								products.map(product => {
									const isSelected = selectedProduct.id == product.id;

									return (
										<Fragment key={product.id}>
											{this._renderProduct(product, isSelected)}
										</Fragment>
									);
								})
							}
						</div>

						<nav className={"lineup"}>
							<ButtonsConstructor
								buttons={products.map(product => {
									const isActive = product.id == selectedProduct.id;

									return {
										text: (
											<WidestContent>
												<span visible={isActive}><Interpret id={"configure"}/></span>
												<span visible={!isActive}>{product.title}</span>
											</WidestContent>
										),
										textTranslated: !isActive,
										active: isActive,
										accent: isActive ? "primary" : "secondary",
										buttonProps: {
											onMouseEnter: () => this.setState({selectedProduct: product}),
										},
										action: () => (!this._touchActive || isActive) && route(APP_ROUTE.CONFIGURATOR(productGroup.id, product.id)),
									}
								})}
								onButton={button => <div className={"product-option"}>{button}</div>}
							/>
						</nav>
					</div>
				</div>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			products: PropTypes.array.isRequired,
			productGroup: PropTypes.object.isRequired,
		}
	}
}

export default withProducts(ProductSelector);
