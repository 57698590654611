import { Component, createContext } from "preact";
import PropTypes from "prop-types";

export let CurrencyContext = createContext();
export const setCurrencyContext = context => CurrencyContext = context;

class CurrencyProvider extends Component {
	render({children, currency}) {
		return (
			<CurrencyContext.Provider value={{
				currency
			}}>
				{children}
			</CurrencyContext.Provider>
		);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,
			currency: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			currency: "CZK",
		}
	}
}

export default CurrencyProvider;
