export const M_L10N_DefaultCustomization = {
	//Must remain undefined, the specific class is defined directly in the code.
	localizerClass: undefined,
	//Must remain undefined, the specific class is defined directly in the code.
	termClass: undefined,
	onTermKey: termKey => '#' + termKey,
	pluralRuleJoin: '_',
	postProcessingRules: {
		BOLD: {
			opening: `<b>`,
			closing: `</b>`,
		},
		ITALIC: {
			opening: `<i>`,
			closing: `</i>`,
		},
	},
};
