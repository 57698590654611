import { Component } from 'preact';
import PropTypes from "prop-types";
import { E_Language, Internationalizer } from "@green24/npm-javascript-utils";
import cs from "../../../i18n/cs.json";
import en from "../../../i18n/en.json";
import shared from "../../../i18n/shared.json";
import { AppUtils } from "../../../utils/AppUtils";
import { LocalizationProvider as GenericLocalizationProvider } from "../../components";

class LocalizationProvider extends Component {
	render({children}, {}) {
		return (
			<GenericLocalizationProvider
				initialLanguage={
					localStorage.getItem("lang") || Internationalizer.resolveLanguageFromNavigator(["cs", "en"], "en")
				}
				fallbackLanguage={"en"}
				sharedDictionary={shared}
				languages={{
					[E_Language.CZECH]: {regions: {cs}},
					[E_Language.ENGLISH]: {regions: {en}},
				}}
				onActiveLanguageChange={(_, internationalizer) => {
					if(AppUtils.hasWindow) {
						document.title = internationalizer.findTerm("app.title").resolveVariables({project: "Green24"}).toString();
					}
				}}
			>
				{children}
			</GenericLocalizationProvider>
		);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,
		}
	}
}

export default LocalizationProvider;
