import { Component as PComponent } from "preact";
import PropTypes from "prop-types";
import { APIConnector, ArrayUtils } from "@green24/npm-javascript-utils";
import { ProjectUtils } from "../../../../utils/ProjectUtils";
import withProducts from "../../product/withProducts";
import { APP_ROUTE } from "../../../../models/constants/AppRoute";
import Header from "../../../shared/Header";
import ErrorBlock from "../../../shared/ErrorBlock";

/**
 * A public higher-order component to access the imperative API
 */
export const withProduct = (Component, {showError = true} = {}) => {
	class HOC extends PComponent {
		render({products, projectContext, productGroup, productID, productGroupID, ...props}) {
			const product = ArrayUtils.findByID(products, productID);

			if(!product && showError) {
				return (
					<section>
						<Header breadcrumbs={[
							{
								text: productGroup ? productGroup.title : '#' + productGroupID,
								href: APP_ROUTE.PRODUCT_GROUP(productGroupID),
							},
							{
								text: '#' + productID,
								href: APP_ROUTE.CONFIGURATOR(productGroupID, productID),
							}
						]}/>

						<ErrorBlock
							className={"full-page"}
							error={APIConnector.getErrorFromCode(404)}
							dataOverrides={{
								title: "product.notFound",
							}}
							actionButtons={[
								{
									text: "redirect.home",
									href: APP_ROUTE.BASE,
								}
							]}
						/>
					</section>
				)
			}

			ProjectUtils.sortProductOptions(product);

			return (
				<Component
					product={product}
					productGroup={productGroup}
					productID={parseInt(productID)}
					{...props}
				/>
			);
		}

		static get displayName() {
			return "withProduct(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				productID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

				//withProducts
				products: PropTypes.array,
			}
		}

		static get defaultProps() {
			return {
				products: [],
			}
		}
	}

	return withProducts(HOC);
};

export default withProduct;
