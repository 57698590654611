import { Component as PComponent } from "preact";
import { CurrencyContext } from "../CurrencyProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withCurrencyContext = Component => {
	class HOC extends PComponent {
		render(props) {
			return (
				<CurrencyContext.Consumer>
					{(currencyContext) => (
						<Component
							currencyContext={currencyContext}
							{...props}
						/>
					)}
				</CurrencyContext.Consumer>
			);
		}

		static get displayName() {
			return "withCurrencyContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withCurrencyContext;
