import { IS } from "./IS";
import { resolvePolymorphVar } from "../functions/generic";

const REGEX_IDENTIFIER_REGEX = /^\/.*\/g?i?u?m?s?y?$/i;
const CHARACTERS_TO_ESCAPE = /[.*+?^${}()|[\]\\]/g;

export class RegexUtils {
	static containsRegex(str) {
		if(IS.string(str)) {
			return REGEX_IDENTIFIER_REGEX.test(str.trim());
		}
		return str instanceof RegExp;
	}

	static escapeString(str) {
		if(IS.string(str)) {
			return str.replace(CHARACTERS_TO_ESCAPE, '\\$&');
		}
		return str;
	}

	/**
	 * Mask as RegExp
	 * ---
	 * Returns an object containing RexExp source and flags properties
	 * @param {string|{source: string, flags?: string}|RegExp} regex
	 * @return {{flags: string, source: string}}
	 */
	static maskAsRegExp(regex) {
		if(IS.property(regex, "source")) {
			return {
				source: regex.source,
				flags: regex.flags || '',
			};
		}

		return resolvePolymorphVar(
			regex,
			{
				string: s => ({
					source: s,
					flags: '',
				}),
			}
		)
	}

	/**
	 * As RegExp
	 * ---
	 * Returns a RegExp instance from string, {source, flags} object or a RegExp instance
	 * @param {string|{source: string, flags?: string}|RegExp} value
	 * @param {string} [flags] Override flags
	 * @return {RegExp}
	 */
	static asRegExp(value, flags) {
		if(typeof value == "string") return new RegExp(this.escapeString(value), flags);
		if(IS.property("source")) return new RegExp(value.source, flags || value.flags);

		console.error("Arguments provided:", value, flags);
		throw TypeError("Unhandled format provided into the RegexUtils.asRegExp");
	}

	/**
	 * Concat
	 * ---
	 * Concatenate multiple regexes into one
	 * @param {[RegExp|string]} regexes
	 * @param {string} join
	 * @param {string|RegExp} prefix
	 * @param {string|RegExp} suffix
	 * @return {RegExp}
	 */
	static concat(regexes, join = '', prefix = '', suffix = '') {
		return new RegExp(
			this.maskAsRegExp(prefix).source + regexes.map(regex => this.maskAsRegExp(regex).source).join(join) + this.maskAsRegExp(suffix).source,
			regexes.map(regex => regex.flags).join('')
		);
	}

	/**
	 * Concat group
	 * ---
	 * Concatenates multiple regexes in a group formation e.g.: (group1)|(group2)
	 * @param {[RegExp|string]} regexes
	 * @param {string} groupsJoin
	 * @param {string|RegExp} prefix
	 * @param {string|RegExp} suffix
	 * @return {RegExp}
	 */
	static concatGroups(regexes, groupsJoin = '|', prefix = '', suffix = '') {
		return this.concat(regexes, `)${groupsJoin}(`, prefix + '(', ')' + suffix);
	}
}
