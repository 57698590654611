import { LocalStorageEditor } from "@green24/js-utils";

export class AppUtils {
	static get isDev() {
		return process.env.NODE_ENV == "development";
	}

	static get isProduction() {
		return process.env.NODE_ENV == "production";
	}

	static get hasWindow() {
		return typeof window !== "undefined";
	}

	/**
	 * Get global modal reference
	 * @return {GlobalModal}
	 */
	static get globalModal() {
		return window.modal;
	}

	/**
	 * Should save data
	 * ---
	 * Returns if there is a network saver enabled (chrome's lite mode or forced by a localStorage key)
	 * @return {boolean}
	 */
	static shouldSaveData(ignoreSaveDataLocalStorageKey = "ignoreSaveData", saveDataLocalStorageKey = "saveData") {
		if(LocalStorageEditor.has("ignoreSaveData")) return false;

		return LocalStorageEditor.isTrue(saveDataLocalStorageKey) || ('connection' in navigator && navigator.connection.saveData);
	}

	/**
	 * Register full page refresher
	 * ---
	 * Registers a listener for any <script> with a "hot-update" keyword and refreshes the whole page
	 */
	static registerFullPageRefresher() {
		const scriptsObserver = new MutationObserver((mutationList) => {
			const addedScripts = [...mutationList[0].addedNodes].filter(node => node.nodeName == "SCRIPT");
			const hotUpdate = addedScripts.find(script => script.src.includes('hot-update'));

			if(hotUpdate) {
				location.reload();
			}
		});

		scriptsObserver.observe(document.head, {
			childList: true,
			subtree: true
		});
	}
}
