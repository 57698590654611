import { IS } from "./IS";
import { ArrayUtils } from "./ArrayUtils";

/**
 * Query string manager
 * ---
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export class QueryStringManager {
	/**
	 * Constructor
	 * ---
	 * @param {String|QueryStringManager|Object} queryData
	 */
	constructor(queryData) {
		this._entries = [];

		if(IS.object(queryData)) {
			if(queryData instanceof QueryStringManager) {
				//If same instance, only copy entries data
				this._entries = [...queryData.entries()];
			}
			else {
				this.setFromObject(queryData);
			}
		}
		else {
			//Parse string via URLSearchParams
			for(const [key, value] of new URLSearchParams(queryData).entries()) {
				this.append(key, value);
			}
		}
	}

	/**
	 * To object
	 * ---
	 * Returns query data
	 * @param {Boolean} stackSameKeyValues If true, multiple values with the same key are combined into the array
	 * @returns {Object}
	 */
	toObject(stackSameKeyValues = false) {
		let result = {};

		this.entries().forEach(({key, value}) => {
			if(stackSameKeyValues) {
				//Check if property already exists
				if(IS.property(result, key)) {
					//Check if property value is an array
					if(IS.array(result[key])) {
						//Push
						result[key].push(value);
					}
					else {
						//Convert to array
						result[key] = [result[key], value];
						//Mark array as custom, so it won't be mishandled if used with .setFromObject()
						Object.defineProperty(result, "__multiple__", {value: true});
					}
				}
				else {
					//Set new property
					result[key] = value;
				}
			}
			else {
				//Set/Overwrite property value
				result[key] = value;
			}
		});

		return result;
	}

	/**
	 * To string
	 * ---
	 * @param {Boolean} prependQueryIndicator If true prepends '?'
	 * @returns {String} Query string
	 */
	toString(prependQueryIndicator = true) {
		let str;
		let pureString = true;

		//Check for objects
		this.entries().forEach(({value}) => {
			if(pureString && IS.object(value)) {
				pureString = false;
			}
		});

		if(pureString) {
			let query = new URLSearchParams();

			//Populate URLSearchParams
			this.entries().forEach(({key, value}) => query.append(key, value));

			str = query.toString();
			if(IS.empty(str)) {
				return '';
			}
		}
		else {
			let query = new QueryStringManager(this);

			//Process any objects
			query.entries().forEach(({key, value}) => {
				if(IS.object(value)) {
					query.set(key, JSON.stringify(value));
				}
			});

			return query.toString(prependQueryIndicator);
		}

		return prependQueryIndicator ? '?' + str : str;
	}

	/**
	 * To valid data string
	 * ---
	 * Returns query string without empty/undefined entries
	 * ```
	 *  //Examples
	 *  new QueryString("test=").toString() => "?test="
	 *  new QueryString("test=").toValidDataString() => ''
	 *  new QueryString("test=&test2=a").toValidDataString() => "?test2=a"
	 * ```
	 * @param prependQueryIndicator If true prepends '?'
	 * @returns {String} Query string
	 * @see toString
	 */
	toValidDataString(prependQueryIndicator = true) {
		let query = new QueryStringManager(this);

		query.entries().forEach(({key, value}) => {
			if(IS.empty(value)) {
				query.remove(key);
			}
		});

		return query.toString(prependQueryIndicator);
	}

	/**
	 * Set from object
	 * ---
	 * Sets entries from the provided object
	 * @param {Object} obj
	 */
	setFromObject(obj) {
		Object.keys(obj).forEach(key => {
			//Process custom array created within .toObject()
			if(IS.array(obj[key]) && IS.property(obj[key], "__multiple__")) {
				obj[key].forEach(item => this.append(key, item));
			}
			else {
				this.set(key, obj[key]);
			}
		});
	}

	/**
	 * Set entry
	 * ---
	 * Sets entry. Already existing entries with the same key are removed.
	 * @param {String} key
	 * @param {*} value
	 */
	set(key, value) {
		this.remove(key);
		this.append(key, value);
	}

	/**
	 * Entries
	 * ---
	 * Returns all entries
	 * @returns {{key: String, value: *}[]}
	 */
	entries() {
		return this._entries;
	}

	/**
	 * Append entry
	 * ---
	 * Appends entry even if the key is already used.
	 * @param {String} key
	 * @param {*} value
	 */
	append(key, value) {
		this._entries.push({key, value});
	}

	/**
	 * Remove
	 * ---
	 * Removes all entries containing the target key
	 * @param {String} key
	 */
	remove(key) {
		this._entries = this.entries().filter(entry => entry.key != key);
	}

	/**
	 * Entries keys
	 * ---
	 * Returns collection of the entry keys
	 * @returns {Array}
	 */
	keys() {
		return ArrayUtils.extract(this.entries(), "key");
	}

	/**
	 * Entries values
	 * ---
	 * Returns collection of the entry values
	 * @returns {Array}
	 */
	values() {
		return ArrayUtils.extract(this.entries(), "value");
	}

	/**
	 * Has entry
	 * ---
	 * Returns if entries contains specified entry
	 * @param {function(entry, i, entries)|String} resolver
	 * @returns {Boolean}
	 */
	has(resolver) {
		return IS.valid(this.get(resolver));
	}

	/**
	 * Get entry
	 * ---
	 * Returns closest entry from the start, found by resolver
	 * @param {function(entry, i, entries)|String} resolver
	 * @param {Boolean} returnObject Returns object if necessary otherwise just it's value
	 * @returns {{key: String, value: *}|*}
	 */
	get(resolver, returnObject = false) {
		let entry = this.entries().find((entry, i) => {
			if(IS.fnc(resolver)) {
				return resolver(entry, i, this.entries());
			}
			return entry.key == resolver;
		});

		if(!returnObject) {
			return (entry || {}).value;
		}
		return entry;
	}

	/**
	 * Get all
	 * ---
	 * Returns all entries found by resolver
	 * @param {function(entry, i, entries)|String} resolver
	 * @returns {{key: String, value: *}[]}
	 */
	getAll(resolver) {
		return this.entries().filter((entry, i) => {
			if(IS.fnc(resolver)) {
				return resolver(entry, i, this.entries());
			}
			return entry.key == resolver;
		});
	}
}

/**
 * @constructor
 * QS
 * ---
 * Shorthand to create a QueryStringManager
 * @borrows QueryStringManager
 * @param {String|QueryStringManager|Object} queryData
 * @returns QueryStringManager
 */
export const QS = (queryData) => new QueryStringManager(queryData);
