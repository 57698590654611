import { StorageManager } from "../StorageManager";
import { EVENT_NAME_LOCAL_STORAGE_UPDATED, LocalStorageEditor } from "./LocalStorageEditor";
import { EventListeners } from "../../EventListeners";
import { E_StorageEvents } from "../../../models/constants/StorageManagerConstants";
import { ObjectUtils } from "../../ObjectUtils";
import { IS } from "../../IS";
import { get } from "../../../functions/generic";

const UNDEFINED = Symbol("Undefined");

export class LocalStorageManager extends StorageManager {
	constructor() {
		super(localStorage);

		this._lastState = {...localStorage};

		this._eventListeners = new EventListeners();
		this._eventListeners.add(window, "storage", e => this._handleStorageUpdateEvent(e));
		this._eventListeners.add(window, EVENT_NAME_LOCAL_STORAGE_UPDATED, e => this._handleStorageUpdateEvent(e));
	}

	clearListeners() {
		this._eventListeners.clear();
	}

	get(path) {
		return LocalStorageEditor.get(path);
	}

	set(path, data) {
		LocalStorageEditor.set(path, data);

		super.set(path, data);
	}

	remove(path) {
		LocalStorageEditor.remove(path);

		super.remove(path);
	}

	clear() {
		localStorage.clear();

		super.clear();
	}

	_handleStorageUpdateEvent() {
		const newState = {...localStorage};
		const {added, changed, removed} = ObjectUtils.diff(newState, this._lastState);
		//There will be only one change at a time so it's safe to merge them and get the first item
		let addedOrRemoved = {...added, ...removed};
		let path, data;

		if(IS.empty(addedOrRemoved) && !IS.empty(changed)) {
			const changeO = this._parseLocalStorageObject(changed);
			const changeKey = Object.keys(changeO)[0];
			const change = Object.values(changeO)[0];

			path = changeKey + '.' + this._getChangePath(change, this._parseLocalStorageObject(this._lastState)[changeKey] || {});
			data = get(changeO, path, UNDEFINED);

			if(data === UNDEFINED) {
				data = undefined;
			}
		}
		else {
			path = Object.keys(addedOrRemoved)[0] || '';
			data = Object.values(this._parseLocalStorageObject(addedOrRemoved))[0];
		}

		this._stackNotifier.notify(E_StorageEvents.UPDATE, {path, data});

		this._lastState = newState;
	}

	_getChangePath(newData, oldData) {
		let path = [];
		let target = newData;
		let oldTarget = oldData;
		let iteration = 0;

		while (IS.object(target) && iteration < 1000) {
			const {added, changed, removed} = ObjectUtils.diff(target, oldTarget);
			const change = {...added, ...changed, ...removed};
			const changeKey = Object.keys(change)[0];

			if(changeKey) {
				path.push(changeKey);
			}

			target = target[changeKey];
			oldTarget = oldTarget[changeKey];
			iteration++;
		}

		return path.join('.');
	}

	_parseLocalStorageObject(o) {
		return ObjectUtils.mapAsObject(o, (key, value) => {
			try {
				value = JSON.parse(value);
			}
			catch (e) {}

			return {
				key,
				value,
			}
		})
	}
}
