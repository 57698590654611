import { StoreHelpers } from "./StoreHelpers";
import { M_AdvancedPromise_DefaultOptions } from "../models/Models_AdvancedPromise";
import { E_AdvancedPromise_State } from "../models/constants/AdvancedPromiseConstants";
import { resolvePolymorphVar } from "../functions/generic";
import { IS } from "./IS";

export class AdvancedPromise {
	constructor(resolver, options) {
		this._resolutionQueue = [];

		this.state = E_AdvancedPromise_State.PENDING;
		this.result = undefined;

		this._options = StoreHelpers.deepMerge(AdvancedPromise.defaultOptions, options, true);

		if(IS.fnc(resolver)) {
			resolver(
				data => this.fulfill(data),
				data => this.reject(data),
			);
		}
	}

	/**
	 * Get default options
	 * ---
	 * @return {M_AdvancedPromise_DefaultOptions}
	 */
	static get defaultOptions() {
		return M_AdvancedPromise_DefaultOptions;
	}

	/**
	 * Get enums
	 * ---
	 * @return {{
	 *  state: E_AdvancedPromise_State
	 * }}
	 */
	static get enum() {
		return {
			state: E_AdvancedPromise_State,
		}
	}

	/**
	 * Get options
	 * ---
	 * @returns {M_AdvancedPromise_DefaultOptions}
	 */
	get options() {
		return this._options;
	}

	/**
	 * is resolved
	 * ---
	 * @return {boolean}
	 */
	get isResolved() {
		return this.state != E_AdvancedPromise_State.PENDING;
	}

	/**
	 * Reset
	 * ---
	 * Reset promise's state
	 */
	reset() {
		this.state = E_AdvancedPromise_State.PENDING;
		this.result = undefined;
	}

	/**
	 * Then
	 * ---
	 * @param {function(PromiseFulfilledResult)} onFulfilled
	 * @param {function(PromiseRejectedResult)} onRejected
	 * @param {function(fulfilled: PromiseFulfilledResult, rejected: PromiseRejectedResult)} onFinally
	 * @param {boolean} fulfilledReturnsData
	 * @param {boolean} finallyExpectsData
	 * @return {AdvancedPromise}
	 * @see Promise.then
	 */
	then(onFulfilled, onRejected, onFinally, fulfilledReturnsData = true, finallyExpectsData = false) {
		let listenerData = {
			onFulfilled,
			onRejected,
			onFinally,
			finallyExpectsData,
			fulfilledReturnsData,
		};

		this._resolutionQueue.push(listenerData);

		switch (this.state) {
			case E_AdvancedPromise_State.FULFILLED:
			case E_AdvancedPromise_State.REJECTED:
				this._handleListener(listenerData);
				break;
		}

		return this;
	}

	/**
	 * Finally
	 * ---
	 * @param {function(fulfilled: PromiseFulfilledResult, rejected: PromiseRejectedResult)} onFinally
	 * @param {boolean} expectData
	 * @return {AdvancedPromise}
	 */
	finally(onFinally, expectData = false) {
		const listenerDefinition = {
			onFinally,
			finallyExpectsData: expectData,
		};

		this._resolutionQueue.push(listenerDefinition);

		if(this.isResolved) {
			this._handleListener(listenerDefinition);
		}

		return this;
	}

	/**
	 * Catch
	 * ---
	 * @param {function(PromiseRejectedResult)} onRejected
	 * @return {AdvancedPromise}
	 */
	catch(onRejected) {
		this._resolutionQueue.push({onRejected});

		if(this.isResolved) {
			this._handleListener({onRejected});
		}

		return this;
	}

	/**
	 * Fulfill
	 * ---
	 * Fulfills the promise from the external source
	 * @param {*} data
	 */
	fulfill(data) {
		if(this.isResolved && !this.options.repeatable) {
			return;
		}
		this.state = E_AdvancedPromise_State.FULFILLED;
		this.result = this._processData(data);

		this._iterateQueue();
	}

	/**
	 * Reject
	 * ---
	 * Rejects the promise from the external source
	 * @param {*} data
	 */
	reject(data) {
		if(this.isResolved && !this.options.repeatable) {
			return;
		}
		this.state = E_AdvancedPromise_State.REJECTED;
		this.result = this._processData(data);

		this._iterateQueue();
	}

	/**
	 * Clear stack
	 * ---
	 * Clears the queue of .then, .catch and .finally callbacks
	 */
	clearStack() {
		this._resolutionQueue = [];
	}

	/**
	 * Resolve
	 * ---
	 * Creates a static AdvancedPromise that is fulfilled by default
	 * @param {*} data
	 * @return {AdvancedPromise}
	 * @see Promise.resolve
	 */
	static resolve(data) {
		let promise = new AdvancedPromise();
		promise.fulfill(data);
		return promise;
	}

	/**
	 * Reject
	 * ---
	 * Creates a static AdvancedPromise that is rejected by default
	 * @param data
	 * @return {AdvancedPromise}
	 * @see Promise.reject
	 */
	static reject(data) {
		let promise = new AdvancedPromise();
		promise.reject(data);
		return promise;
	}

	_iterateQueue() {
		this._resolutionQueue.forEach(listenerDefinition => {
			let listenerData = resolvePolymorphVar(
				listenerDefinition,
				{
					array: arr => ({
						onFulfilled: arr[0],
						onRejected: arr[1],
						onFinally: arr[2],
						finallyExpectsData: arr[3],
					}),
				},
				listenerDefinition,
			);

			this._handleListener(listenerData);
		});
	};

	_handleListener(listenerData) {
		const {onFulfilled, onRejected, onFinally, finallyExpectsData, fulfilledReturnsData} = listenerData;
		let baseResult = this.result;

		switch (this.state) {
			case E_AdvancedPromise_State.FULFILLED:
				if(IS.fnc(onFulfilled)) {
					let fulfilledValue = onFulfilled(this.result, baseResult);

					if(fulfilledReturnsData) {
						this.result = fulfilledValue;
					}
				}

				if(IS.fnc(onFinally)) {
					onFinally(finallyExpectsData ? this.result : undefined, undefined);
				}
				break;
			case E_AdvancedPromise_State.REJECTED:
				if(IS.fnc(onRejected)) {
					onRejected(this.result);
				}

				if(IS.fnc(onFinally)) {
					onFinally(undefined, finallyExpectsData ? this.result : undefined);
				}
				break;
		}
	}

	_processData(data) {
		if(!IS.empty(this.options.as)) {
			return {[this.options.as]: data};
		}
		return data;
	}
}
