import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import withProduct from "../../components/other/project/hoc/withProduct";
import { APP_ROUTE } from "../../models/constants/AppRoute";
import Router from "preact-router";
import ProductConfigurator from "./ProductConfigurator";
import ProductConfigurationSummary from "./ProductConfigurationSummary";
import Header from "../../components/shared/Header";
import { CurrencyProvider } from "../../components/components";
import ProductFeatureCustomizer from "./ProductFeatureCustomizer";

class ProductConfiguratorWizard extends Component {
	render({className, style, product, categoryID, productGroup}, {}) {
		return (
			<section className={combineClasses("product-configurator-wizard", className)} style={style}>
				<Header breadcrumbs={[
					{
						text: productGroup.title,
						href: APP_ROUTE.PRODUCT_GROUP(productGroup.id),
					},
					{
						text: product.title,
						href: APP_ROUTE.CONFIGURATOR(productGroup.id, product.id),
					},
				]}/>

				<CurrencyProvider currency={product.currency}>
					<ProductFeatureCustomizer categories={product.categories}>
						<Router>
							<ProductConfigurationSummary product={product} productGroup={productGroup} path={APP_ROUTE.CONFIGURATOR_SUMMARY()}/>
							<ProductConfigurator product={product} productGroup={productGroup} categoryID={parseInt(categoryID)} default/>
						</Router>
					</ProductFeatureCustomizer>
				</CurrencyProvider>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//Router
			categoryID: PropTypes.string,
		}
	}
}

export default withProduct(ProductConfiguratorWizard);
