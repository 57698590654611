import { Component as PComponent } from "preact";
import { FeatureCustomizerContext } from "../../../routes/ProductConfigurator/ProductFeatureCustomizer";

/**
 * A public higher-order component to access the imperative API
 */
export const withFeatureCustomizerContext = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<FeatureCustomizerContext.Consumer>
					{(featureCustomizerContext) => (
						<Component
							featureCustomizerContext={featureCustomizerContext}
							{...props}
						/>
					)}
				</FeatureCustomizerContext.Consumer>
			);
		}

		static get displayName() {
			return "withFeatureCustomizerContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withFeatureCustomizerContext;
