import { IS } from "../IS";
import { ObjectUtils } from "../ObjectUtils";
import { get, resolvePolymorphVar } from "../../functions/generic";
import { RegexUtils } from "../RegexUtils";
import { M_L10N_DefaultCustomization } from "../../models/L10N/Models_L10N";
import { StringUtils } from "../StringUtils";
import { E_L10N_PartType } from "../../models/constants/LanguageConstants";
import { ArrayUtils } from "../ArrayUtils";

/**
 * @typedef PartData
 * @type {{
 *     type: E_L10N_PartType,
 *     value: string,
 *     optional: boolean,
 * }}
 */

const REGEX_VARIABLE_OPENING = /\\?[%@]{/;
const REGEX_VARIABLE_CLOSING = /}\??/;
const REGEX_VARIABLE_CONTENTS = /.*?/;
const REGEX_VARIABLE = new RegExp(REGEX_VARIABLE_OPENING.source + REGEX_VARIABLE_CONTENTS.source + REGEX_VARIABLE_CLOSING.source);
const REGEX_VARIABLE_OPTIONAL = /\?$/;

const STRING_PLACEHOLDER = "<|PLACEHOLDER|>";
const REGEX_PLACEHOLDER = RegexUtils.escapeString(STRING_PLACEHOLDER);

export class LocalizedTerm {
	constructor(data, localizer = undefined, customization) {
		let {term, termKey, fallback} = data || {};

		this._term = term;
		this._termKey = termKey;
		this._localizer = localizer;
		this._fallback = fallback;
		this._customization = {
			...LocalizedTerm.model.defaultCustomization,
			termClass: LocalizedTerm,
			...customization
		};
	}

	static get model() {
		return {
			defaultCustomization: M_L10N_DefaultCustomization,
		};
	}

	static get enum() {
		return {
			partType: E_L10N_PartType,
		}
	}

	/**
	 * Get processed term key
	 * ---
	 * @return {string}
	 */
	get processedTermKey() {
		if(this._termKey) {
			return this._customization.onTermKey(this._termKey);
		}
	}

	/**
	 * Get term
	 * ---
	 * @return {string}
	 */
	get term() {
		return this._termToString(this._term) || this._fallback || this.processedTermKey;
	}

	/**
	 * Get valid
	 * ---
	 * @return {boolean}
	 */
	get valid() {
		let term = this._termToString(this._term);

		return IS.defined(term) && !IS.empty(term) && !IS.equal(term, this._fallback, this.processedTermKey);
	}

	/**
	 * Get regex
	 * ---
	 * @return {{
	 *  variableOptional: RegExp,
	 *  variableClosing: RegExp,
	 *  variableOpening: RegExp,
	 *  variable: RegExp
	 * }}
	 */
	get regex() {
		return {
			variable: REGEX_VARIABLE,
			variableOpening: REGEX_VARIABLE_OPENING,
			variableClosing: REGEX_VARIABLE_CLOSING,
			variableOptional: REGEX_VARIABLE_OPTIONAL,
		};
	}

	/**
	 * Get localizer
	 * ---
	 * @return {Localizer}
	 */
	get localizer() {
		return this._localizer;
	}

	/**
	 * Clone
	 * ---
	 * @return {LocalizedTerm}
	 */
	clone() {
		return new this.constructor({
			term: this._term,
			fallback: this._fallback,
			termKey: this._termKey,
		}, this._localizer, this._customization);
	}

	/**
	 * Set localizer
	 * ---
	 * @param {Localizer} localizer
	 */
	setLocalizer(localizer) {
		this._localizer = localizer;
	}

	/**
	 * To string
	 * ---
	 * @param {boolean} process
	 * @param {boolean} includeZWNJ
	 * @return {string}
	 */
	toString(process = true, includeZWNJ = false) {
		let term = this.term;
		if(term) {
			if(term.charAt(0) === '‌') return term;

			let text = term;
			if(process && term.charAt(0) != '‌') {
				text = this._processVariablesWithinString(term);
			}

			return `${includeZWNJ ? '‌' : ''}${text}`;
		}

		return '';
	}

	/**
	 * Resolve variables
	 * ---
	 * @param {Object|Array|undefined} variables
	 * @return {LocalizedTerm}
	 */
	resolveVariables(variables = undefined) {
		return resolvePolymorphVar(
			variables,
			{
				array: arr => this.fillVariablesFromArray(arr),
				object: o => this.fillVariablesFromObject(o),
			},
			() => this,
			true
		);
	}

	/**
	 * Fill variables from object
	 * ---
	 * @param {Object} o
	 * @return {LocalizedTerm}
	 */
	fillVariablesFromObject(o) {
		if(!this.valid) return this;

		this._term = this._processVariablesWithinString(this.term, o);
		return this;
	}

	/**
	 * Fill variables from array
	 * ---
	 * @param {Array} arr
	 * @return {LocalizedTerm}
	 */
	fillVariablesFromArray(arr) {
		if(!this.valid) return this;

		this._term = this._processVariablesWithinString(this.term, ObjectUtils.arrayToObject(arr));
		return this;
	}

	/**
	 * Split into parts
	 * ---
	 * @param {boolean} trim
	 * @param {*} fallback
	 * @return {[PartData]|*}
	 */
	splitIntoParts(trim = false, fallback = []) {
		//Split the term into an array of parts (e.g. text (pure string), variable, etc.)
		let parts = this._splitIntoParts(this.term, trim);
		return IS.empty(parts) ? fallback : parts;
	}

	/**
	 * Split into post processing parts
	 * ---
	 * Returns a collection of objects containing type of post processing and a value to be processed.
	 * @param {Object<string, string>} customRules Custom rules declaration
	 * @return {[{type: string, value: string}]}
	 */
	splitIntoPostProcessingParts(customRules) {
		if(!this.valid) {
			return [
				{
					type: "TEXT",
					value: this.toString(),
				}
			];
		}

		const rules = {
			...this._customization.postProcessingRules,
			...customRules,
		};
		const term = this.toString();
		const allRulesTest = RegexUtils.concat(ObjectUtils.map(rules, (_, rule) => {
			return RegexUtils.concatGroups([
				RegexUtils.asRegExp(rule.opening),
				RegexUtils.asRegExp(rule.closing)
			]);
		}), '|');

		if(allRulesTest.test(term)) {
			return this._splitIntoPostProcessingParts(term, rules);
		}

		return [
			{
				type: "TEXT",
				value: term,
			}
		];
	}

	/**
	 * @private
	 * Term to string
	 * ---
	 * @param {string|Object} termData
	 * @return {string}
	 */
	_termToString(termData) {
		return resolvePolymorphVar(
			termData,
			{
				object: o => o.text,
				string: s => s,
			},
		);
	}

	/**
	 * @private
	 * Split into parts
	 * @param {string} str
	 * @param {boolean} trimValue
	 * @return {PartData[]}
	 */
	_splitIntoParts(str, trimValue = false) {
		const matchRegex = new RegExp(this.regex.variable.source, (this.regex.variable.flags || ''));
		const parts = StringUtils.splitToParts(str, matchRegex);
		return parts.map(part => this._processSubstring(part, trimValue));
	}

	/**
	 * @private
	 * Process variables within string
	 * ---
	 * @param {string} str
	 * @param {Object} variablesObject
	 * @return {string}
	 */
	_processVariablesWithinString(str, variablesObject) {
		str = str.replace(new RegExp(this.regex.variable.source, (this.regex.variable.flags || '') + 'g'), substring => {
			let {type, value, optional} = this._processSubstring(substring);

			switch (type) {
				case E_L10N_PartType.VARIABLE:
					return get(variablesObject, value, optional ? STRING_PLACEHOLDER : substring);
				case E_L10N_PartType.TRANSLATE:
					return this._findTermFromParentController(value, optional ? STRING_PLACEHOLDER : undefined).toString();
				case E_L10N_PartType.TEXT:
				default:
					return value;
			}
		});

		// str = str.replace(new RegExp(`( +?\\?)?(${REGEX_PLACEHOLDER})( +?\\?)?`, 'g'), '');
		str = str.replace(new RegExp(` *?(${REGEX_PLACEHOLDER}) *?`, 'g'), '');

		return str;
	}

	/**
	 * @private
	 * Process substring
	 * ---
	 * @param {string} substring
	 * @param {boolean} trimValue
	 * @return {PartData}
	 */
	_processSubstring(substring, trimValue = true) {
		if(substring.charAt(0) == '\\') return {
			type: "TEXT",
			value: substring.slice(1),
			optional: false,
		};

		const optional = this.regex.variableOptional.test(substring);
		const opening = this.regex.variableOpening.source;
		const closing = this.regex.variableClosing.source;
		let value = substring.replace(new RegExp(`(${opening})|(${closing})`, 'g'), '');

		if(trimValue) {
			value = value.trim();
		}

		switch (substring.charAt(0)) {
			case '%':
				return {
					type: E_L10N_PartType.VARIABLE,
					value,
					optional,
				};
			case '@':
				return {
					type: E_L10N_PartType.TRANSLATE,
					value,
					optional,
				};
			default:
				return {
					type: E_L10N_PartType.TEXT,
					value,
					optional: false,
				}
		}
	}

	/**
	 * @private
	 * Find term from parent controller
	 * ---
	 * @param {string} termKey
	 * @param {*} fallback
	 * @return {LocalizedTerm}
	 */
	_findTermFromParentController(termKey, fallback) {
		if(this.localizer) {
			return this.localizer.handleFindTermFromChildren(termKey, fallback);
		}

		return new this._customization.termClass({
			termKey,
			fallback,
		});
	}

	/**
	 * @private
	 * Split into post processing parts
	 * ---
	 * @param {string} str
	 * @param {Object<string, string>} rules
	 * @return {[{type: string, value: string}]}
	 */
	_splitIntoPostProcessingParts(str, rules) {
		const allRulesTest = RegexUtils.concat(ObjectUtils.map(rules, (_, rule) => {
			return RegexUtils.concatGroups([
				RegexUtils.concat([/\\?/, RegexUtils.asRegExp(rule.opening)]),
				RegexUtils.concat([/\\?/, RegexUtils.asRegExp(rule.closing)]),
			]);
		}), '|');

		//Split and remove any irrelevant clutter like undefined and empty strings
		const stringParts = str.split(allRulesTest).filter(v => !!v);

		//If there is no post processing marker, no reason to iterate so just return the whole string
		if(stringParts.length == 1) {
			return {
				type: "TEXT",
				value: stringParts[0],
			};
		}

		let ppParts = [];
		for(let i = 0; i < stringParts.length; i++) {
			const part = stringParts[i];

			let type;
			if(part.charAt(0) === '\\') {
				type = undefined;
			}
			else {
				type = ObjectUtils.findKey(rules, (_, rule) => RegexUtils.asRegExp(rule.opening).test(part));
			}

			let value = undefined;

			if(type) {
				if(!rules[type].closing) {
					value = stringParts[i];
				}
				else {
					let startIndex = i + 1;

					//Iterate till the closing is found
					for(i = startIndex; i < stringParts.length; i++) {
						if(
							stringParts[i] &&
							stringParts[i].charAt(0) != '\\' &&
							RegexUtils.asRegExp(rules[type].closing).test(stringParts[i])
						) {
							value = stringParts.slice(startIndex, i).join('');
							break;
						}
					}

					//If closing was not found, set the whole string from the startIndex as a value
					// **Warning!** Need to compensate for the startIndex = i + 1 by subtracting 1
					if(value === undefined) {
						value = stringParts.slice(Math.max(0, startIndex - 1)).join('');
					}
				}
			}
			else {
				value = stringParts[i];
			}

			type = type || "TEXT";

			let lastItem = ArrayUtils.lastItem(ppParts);
			if(lastItem && lastItem.type == type) {
				lastItem.value += value;
			}
			else {
				ppParts.push({
					type,
					value,
				});
			}
		}

		return ppParts;
	}
}
