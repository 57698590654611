import { IS } from "./IS";
import { get, resolvePolymorphVar } from "../functions/generic";
import { ArrayUtils } from "./ArrayUtils";

export class ObjectUtils {
	static addProperties(o, properties, propertiesConfiguration, sharedConfiguration, immutable = true) {
		let target = immutable ? {...o} : o;

		ObjectUtils.forEach(properties, (key, value) => {
			Object.defineProperty(target, key, {
				...sharedConfiguration,
				...(propertiesConfiguration || {})[key],
				value,
			});
		});

		return target;
	}

	static withoutProperties(o, exclude, immutable = true) {
		let target = immutable ? {...o} : o;

		exclude.forEach(excludedKey => {
			delete target[excludedKey];
		});

		return target;
	}

	static get(o, path, fallback) {
		return get(o, path, fallback);
	}

	/**
	 * Set
	 * ---
	 * Sets the data according to the path. If the end of the path cannot be reached, it will create what is missing.
	 * ```
	 *  a = {b: {}}
	 *  ObjectUtils.set(a, "b.c.0.d", 1) => {b:{c:[{d:1}]}}
	 *  ...
	 *  a = {b:[{id: 5}]}
	 *  ObjectUtils.set(a, "b.#5.a.0.d", 1) => {b:[{id: 5, a:[0:{d:1}]}]}
	 * ```
	 *
	 * **Notice!** Standalone numbers in the path are processed as an Array index.
	 *
	 * **Notice!** When using a #[Number] (e.g. #4) in the path, it will be ALWAYS placed inside a {} since it's expecting {id: [Number], ...}
	 *
	 * @param {Object} o Source object
	 * @param {string|[string]} path Path
	 * @param {*} value Value to set
	 * @param {{
	 *  allowArrays: boolean,
	 *  returnPath: boolean,
	 * }} options
	 * @returns {string|Object} Returns resulting object or a path if `returnPath` is true
	 */
	static set(o = {}, path, value, {allowArrays = true, returnPath = false} = {}) {
		if(IS.empty(path) || !(IS.array(path) || IS.string(path))) return;

		path = IS.string(path) ? path.split(".") : path;

		const __isInt = (v) => (/^\d+$/g.test(v));
		const __findTargetIndexFromID = (target, id) => ArrayUtils.findByID(target, id.replace('#', ''), true);
		const __pathAsArrayItem = (item) => allowArrays ? __isInt(item) || ['$'].includes(item) || `${item}`.match(/^#/) : false;

		let pathToReturn = [];
		let target = o;
		path.forEach((item, i) => {
			let nextPathItem = path[i + 1];

			//If is last item in path
			if(i + 1 == path.length) {
				//If is part of an array
				if(__pathAsArrayItem(item)) {
					//If is not a number (e.g. '#4' or '$')
					if(!__isInt(item)) {
						//If is valid target with appropriate id (e.g. '#2' => [{id: 2}])
						if(__findTargetIndexFromID(target, item) > -1) {
							let id = parseInt(item.replace('#', ''));
							//If is id valid (not NaN) which might happen if item === '$'
							if(!IS.nan(id)) {
								let index = ArrayUtils.findByID(target, item.replace('#', ''), true);
								target[index] = value;
								pathToReturn.push(index);
							}
							else {
								// '$' case, set value at the last index
								target[target.length - 1] = value;
								pathToReturn.push(target.length - 1);
							}
						}
						else {
							target.push(value);
							pathToReturn.push(target.length - 1);
						}
					}
					else {
						if(IS.ownProperty(target, parseInt(item))) {
							target[parseInt(item)] = value;
							pathToReturn.push(parseInt(item));
						}
						else {
							target.push(value);
							pathToReturn.push(target.length - 1);
						}
					}
				}
				else {
					target[item] = value;
					pathToReturn.push(item);
				}
			}
			else {
				//If is part of an array
				if(__pathAsArrayItem(item)) {
					//If is not a number (e.g. '#4' or '$')
					if(!__isInt(item)) {
						//If is valid target with appropriate id (e.g. '#2' => [{id: 2}])
						let targetIndex = __findTargetIndexFromID(target, item);
						if(targetIndex > -1) {
							target = target[targetIndex];
							pathToReturn.push(targetIndex);
						}
						else {
							let arrLength;
							let id = parseInt(item.replace('#', ''));
							//If is id valid (not NaN) which might happen if item === '$'
							if(!IS.nan(id)) {
								arrLength = target.push({id});
								target = target[arrLength - 1];
							}
							else {
								if(target.length == 0) {
									arrLength = target.push(__pathAsArrayItem(nextPathItem) ? [] : {});
								}
								else {
									arrLength = target.length;
								}

								target = target[arrLength - 1];
							}

							pathToReturn.push(arrLength - 1);
						}
					}
					else {
						if(!IS.object(target[item])) {
							target[item] = __pathAsArrayItem(nextPathItem) ? [] : {};
						}
						target = target[item];
						pathToReturn.push(item);
					}
				}
				else {
					// If item already exists, step deeper
					if(IS.object(target[item])) {
						target = target[item];
					}
					else {
						target[item] = __pathAsArrayItem(nextPathItem) ? [] : {};
						target = target[item];
					}
					pathToReturn.push(item);
				}
			}
		});

		if(returnPath) {
			return pathToReturn.join('.');
		}

		return o;
	}

	/**
	 * Resolve presets
	 * @param {Object} o
	 * @param {Object} presetsCollection
	 * @param {string} keyword
	 * @param {undefined|"prepend"|"append"|"placeholder"|function(Object)} placementSolver
	 * @return {*}
	 */
	static resolvePresets(o, presetsCollection, placementSolver, keyword = "preset") {
		if(IS.property(o, keyword) && !IS.empty(presetsCollection)) {
			let presetData = presetsCollection[o[keyword]];
			let oClone = {...o};
			delete oClone[keyword];

			let mergedData = resolvePolymorphVar(
				placementSolver,
				{
					string: s => {
						switch (s) {
							case "append":
								return {
									...oClone,
									...presetData
								};
							case "prepend":
								return {
									...presetData,
									...oClone
								};
							case "placeholder":
								let o1 = {};
								let o2 = {};
								let placeholderMatched = false;

								Object.keys(o).forEach(key => {
									if(key == keyword) {
										placeholderMatched = true;
										return;
									}

									(placeholderMatched ? o2 : o1)[key] = o[key];
								});

								return {
									...o1,
									...presetData,
									...o2,
								};
							default:
								throw Error("Unknown placement solver: ", s);
						}
					},
					function: f => f(oClone, presetData),
				},
				{
					...presetData,
					...oClone
				}
			);

			return ObjectUtils.resolvePresets(mergedData, presetsCollection, placementSolver, keyword);
		}
		return o;
	}

	/**
	 * For each
	 * ---
	 * @param {Object} o
	 * @param {function(key, value, i, o)} callback
	 */
	static forEach(o, callback = () => null) {
		Object.keys(o || {}).forEach((key, i) => {
			callback(key, o[key], i, o);
		});
	}

	/**
	 * Map
	 * ---
	 * @param {Object} o
	 * @param {function(key, value, i, o)} callback
	 * @return {[]}
	 */
	static map(o, callback = () => null) {
		return Object.keys(o || {}).map((key, i) => {
			return callback(key, o[key], i, o);
		});
	}

	/**
	 * Map valid
	 * ---
	 * Maps only the valid items into an array.
	 * Validity is recognized according to the **invalidIdentifier**
	 * @param {Object} o Source array
	 * @param {function(key, value, i, self)} mapper Mapper function
	 * @param {...*} invalidIdentifiers An identifier used to compare the item's validity
	 * @return {Array}
	 */
	static mapValid(o, mapper = item => item, ...invalidIdentifiers) {
		return ArrayUtils.mapValid(
			ObjectUtils.toArray(o),
			(item, i) => {
				return mapper(item.key, item.value, i, o);
			},
			...invalidIdentifiers,
		);
	}

	/**
	 * Map (as Object)
	 * ---
	 * @param {Object} o
	 * @param {function(key, value, i, o)} callback
	 * @return {Object}
	 */
	static mapAsObject(o, callback = () => null) {
		let result = {};

		ObjectUtils.forEach(o, (key, value, i, o) => {
			let callbackValue = callback(key, value, i, o);

			if(callbackValue) {
				let {key: newKey, value: newValue} = callbackValue;
				result[newKey] = newValue;
			}
		});

		return result;
	}

	/**
	 * Array to object
	 * ---
	 * Maps an array of objects (key - value pairs) into an object.
	 *
	 * Can process any contents of the array, even though the main purpose is to remap the key-value pairs.
	 * @param {Array<*>} arr Target array
	 * @param {function(item, i, self, skip)} mapper Mapping function, should return {key: *, value: *} pair or a **skip** symbol when the entry needs to be skipped
	 * @param {boolean} useIndexIfUndefinedKey If true, when the **key** is missing from the mapper, an Array index will be used instead.
	 * @return {Object}
	 */
	static arrayToObject(arr, mapper = item => item, useIndexIfUndefinedKey = true) {
		if(!IS.array(arr)) return {};

		let result = {};
		const skip = Symbol("Skip push");

		arr.forEach((item, i) => {
			let mapperResult = mapper(item, i, arr, skip);
			if(mapperResult !== skip) {
				if(IS.property(mapperResult, "key", "value")) {
					result[mapperResult.key] = mapperResult.value;
				}
				else if(useIndexIfUndefinedKey) {
					result[i] = mapperResult;
				}
			}
		});

		return result;
	};

	/**
	 * Diff
	 * ---
	 * @param {Object} newO New object data
	 * @param {Object} oldO Old object data
	 * @param {boolean} analyzeContents If true, the value of the property will be analyzed and properly sorted either to the **same** or **changed** result property
	 * @param {undefined|string|function(item1, item2)} comparator
	 * @return {{same: Object, removed: Object, added: Object, changed: Object}}
	 */
	static diff(newO, oldO, analyzeContents = true, comparator = undefined) {
		if(IS.empty(newO)) {
			return {
				removed: oldO || {},
				added: {},
				changed: {},
				same: {},
			}
		}

		if(IS.empty(oldO)) {
			return {
				removed: {},
				added: newO || {},
				changed: {},
				same: {},
			}
		}

		const {added, removed, same} = ArrayUtils.diff(Object.keys(newO), Object.keys(oldO));

		let addedO = ObjectUtils.arrayToObject(added, key => ({key, value: newO[key]}));
		let removedO = ObjectUtils.arrayToObject(removed, key => ({key, value: oldO[key]}));
		let sameO = ObjectUtils.arrayToObject(same, key => ({key, value: newO[key]}));
		let changedO = {};

		if(analyzeContents) {
			const comparatorFunction = resolvePolymorphVar(
				comparator,
				{
					string: s => (item1, item2) => {
						return IS.equal(
							get(item1, s, Symbol()),
							get(item2, s, Symbol())
						);
					},
					function: f => f,
				},
				() => (item1, item2) => IS.equal(item1, item2),
				true
			);

			changedO = ObjectUtils.mapAsObject(sameO, (key, value) => {
				if(comparatorFunction(value, oldO[key])) return;

				delete sameO[key];

				return {
					key,
					value: newO[key],
				}
			});
		}

		return {
			added: addedO,
			removed: removedO,
			same: sameO,
			changed: changedO,
		}
	}

	/**
	 * Find key
	 * ---
	 * Returns the key of the first element in the array where predicate is true, and undefined
     * otherwise.
	 *
	 * @param {Object} o
	 * @param {function(key, value, i, self)} predicate find calls predicate once for each element of the array, in ascending
     * order, until it finds one where predicate returns true. If such an element is found, find
     * immediately returns that element value. Otherwise, find returns undefined.
	 * @return {*}
	 */
	static findKey(o, predicate) {
		return Object.keys(o).find((key, i) => predicate(key, o[key], i, o));
	}

	/**
	 * Find
	 * ---
	 * Returns the value of the first element in the array where predicate is true, and undefined
     * otherwise.
	 *
	 * @param {Object} o
	 * @param {function(key, value, i, self)} predicate find calls predicate once for each element of the array, in ascending
     * order, until it finds one where predicate returns true. If such an element is found, find
     * immediately returns that element value. Otherwise, find returns undefined.
	 * @return {*}
	 */
	static find(o, predicate) {
		return o[this.findKey(o, predicate)];
	}

	/**
	 * Find and retrieve
	 * ---
	 * Unlike .find(), .findAndRetrieve() returns a value returned from the predicate (if the validValueCheck return is not falsy)
	 * @param {Object} o
	 * @param {function(key, item, index, self)} predicate
	 * @param {function(result): boolean} validValueCheck Returns if the value for a predicate is valid, falsy = not valid
	 * @return {*}
	 */
	static findAndRetrieve(o, predicate, validValueCheck = undefined) {
		return ArrayUtils.findAndRetrieve(Object.keys(o), (key, i) => predicate(key, o[key], i, o), validValueCheck);
	}

	/**
	 * To array
	 * ---
	 * Converts an Object into an array
	 * @param {Object} o
	 * @return {{key: string, value: *}[]}
	 */
	static toArray(o) {
		return Object.keys(o || {}).map(key => ({key, value: o[key]}));
	}

	/**
	 * Extract
	 * ---
	 * Extracts values from an object of any depth
	 * @param {Object} o Source object
	 * @param {string} path Extraction path
	 * @returns {Array}
	 */
	static extract(o, path) {
		if(path === '') return ObjectUtils.toArray(o);

		const FALLBACK_SYMBOL = Symbol("Fallback symbol");
		const pathStages = IS.array(path) ? path : path.split(/\.?\*\.?/);

		return ObjectUtils.map(o, (_, item) => {
			let target = IS.empty(pathStages[0]) ? item : get(item, pathStages[0], FALLBACK_SYMBOL);

			if(target !== FALLBACK_SYMBOL) {
				if(pathStages.length > 1) {
					return resolvePolymorphVar(
						target,
						{
							object: o => ObjectUtils.extract(o, pathStages.slice(1)),
							array: arr => ArrayUtils.extract(arr, pathStages.slice(1)),
						}
					);
				}

				return target;
			}
		}).flat(1);
	}

	/**
	 * Subtract
	 * ---
	 * Subtracts subtrahend object from the minuend object
	 * @param {Object} minuend The object that is to be subtracted from.
	 * @param {Object} subtrahend The object that is to be subtracted.
	 * @param {boolean} immutable If true, subtraction is done on a new object.
	 * @param {function(value1: *, value2: *): boolean} equalComparator Customizable comparator; Can be used for various data types that does not necessarily equals via IS.equal().
	 */
	static subtract(minuend, subtrahend, immutable = false, equalComparator = IS.equal) {
		if(IS.empty(minuend)) return {};
		if(IS.empty(subtrahend)) return minuend;

		let o = immutable ? {...minuend} : minuend;
		let keysToDelete = [];

		ObjectUtils.forEach(o, (key, value) => {
			if(IS.property(subtrahend, key)) {
				if(equalComparator(subtrahend[key], value)) {
					keysToDelete.push(key);
				}
				else if(IS.object(o[key]) && IS.object(subtrahend[key])) {
					value = this.subtract(o[key], subtrahend[key], immutable, equalComparator);

					if(equalComparator(subtrahend[key], value)) {
						keysToDelete.push(key);
					}
					else {
						o[key] = value;
					}
				}
			}
		});

		keysToDelete.forEach(key => delete o[key]);

		return o;
	}
}
