import { ProductConfiguratorGenericServices } from "./api-connector";

class ProjectServices extends ProductConfiguratorGenericServices {
	getItem(itemID, locale = "cs", currency, processor = data => data, signal, additionalConfiguration = {}) {
		return super.getItem(itemID, processor, signal, {
			...additionalConfiguration,
			query: {
				lang: locale,
				currency,
				...additionalConfiguration.query,
			}
		});
	}
}

export const projectServices = new ProjectServices(`/api/project`);
