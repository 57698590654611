import { IS } from "../IS";
import { E_HttpErrorCode, E_XHR_UploadState } from "../../models/constants/APIConnectorConstants";
import { APIConnector } from "./APIConnector";
import { StackNotifier } from "../StackNotifier";

/**
 * @typedef {{
 *  elapsed: number,
 *  status: E_XHR_UploadState,
 *  loaded: boolean,
 *  progress: number,
 *  indeterminate: boolean,
 *  startTime: Date,
 *  lastUpdateTime: Date,
 *  uploading: boolean,
 *  error: Object,
 *  aborted: boolean,
 * }} XHRUploaderStates
 */

export class XHRUploader extends StackNotifier {
	constructor(options, autoSend = false) {
		super();

		//Request data
		this.xhr = null;
		this.formData = null;

		//States
		this.lastUpdateTime = null;
		this.elapsed = 0;
		this.status = E_XHR_UploadState.CONNECTING;
		this.progress = 0;
		this.indeterminate = false;
		this.loaded = false;
		this.error = null;
		this.aborted = false;
		this.uploading = true;
		this.completed = false;

		this._constructRequest(options);

		if(autoSend) {
			this.send();
		}
	}

	get states() {
		return {
			elapsed: this.elapsed,
			status: this.status,
			loaded: this.loaded,
			progress: this.progress,
			indeterminate: this.indeterminate,
			startTime: this.startTime,
			lastUpdateTime: this.lastUpdateTime,
			uploading: this.uploading,
			error: this.error,
			aborted: this.aborted,
		}
	}

	/**
	 * On
	 * ---
	 * Register listeners (callbacks) for success, error and progress
	 * @param {function(data: Response)} onSuccess On success callback
	 * @param {function(data: HttpResponseError)} onError On error callback
	 * @param {function(states: XHRUploaderStates)} onProgress On progress callback
	 * @returns {XHRUploader}
	 */
	on(onSuccess, onError, onProgress) {
		IS.fnc(onSuccess) && super.on("success", onSuccess);
		IS.fnc(onError) && super.on("error", onError);
		IS.fnc(onProgress) && super.on("progress", onProgress);

		return this;
	}

	/**
	 * On success
	 * ---
	 * Register on success event callback
	 * @param {function(data: Response)} callback Callback
	 * @returns {XHRUploader}
	 */
	onSuccess(callback) {
		return super.on("success", callback);
	}

	/**
	 * On error
	 * ---
	 * Register on error event callback
	 * @param {function(data: HttpResponseError)} callback Callback
	 * @returns {XHRUploader}
	 */
	onError(callback) {
		return super.on("error", callback);
	}

	/**
	 * On progress
	 * ---
	 * Register on progress event callback
	 * @param {function(states: XHRUploaderStates)} callback Callback
	 * @returns {XHRUploader}
	 */
	onProgress(callback) {
		return super.on("progress", callback);
	}

	/**
	 * On finally
	 * ---
	 * Register on success/error (whichever comes first) event callback
	 * @param {function(Response|HttpResponseError)} callback Callback
	 * @returns {XHRUploader}
	 */
	onFinally(callback) {
		return super.on(["success", "error"], callback);
	}

	/**
	 * Send
	 * ---
	 * Sends constructed XHR request
	 */
	send() {
		this.xhr.send(this.formData);
	}

	/**
	 * Abort
	 * ---
	 * Aborts XHR request
	 */
	abort() {
		this._updateStates({aborted: true});
		this.xhr.abort();
	}

	/**
	 * @private
	 * Construct request
	 * ---
	 * Constructs XHR request based on provided **options**
	 * @param {M_APIConnector_RequestOptions} options
	 */
	_constructRequest(options) {
		const xhr = this.xhr = this._createXHR();
		const file = options.file || options.body;

		//Record start time
		this.startTime = Date.now();

		//Set response type
		xhr.responseType = options.responseType || "json";

		//Listen for onProgress
		xhr.upload.onprogress = e => {
			let states = this._handleProgressUpdate(e);
			this._updateStates(states);
		};

		//Listen for onReadyStateChange
		xhr.onreadystatechange = () => {
			let data = this._handleReadyStateChange(xhr);
			this._updateStates(data);

			if(data.completed) {
				this.notify("success", xhr.response);
			}
			else if(data.error) {
				this.notify("error", data.error);
			}
		};

		const formData = new FormData();
		const url = options.apiURL + options.url;
		const formDataName = options.formDataName;

		//Set file & file name
		formData.append(formDataName, file, file.name || "file");

		xhr.open(options.method, url, true);

		//Propagate request headers into the XHR
		options.headers.forEach((value, key) => {
			xhr.setRequestHeader(key, value);
		});

		//Setup timeout if timeout > 0 (Default = -1) @see M_APIConnector_RequestOptions.timeout for more information
		if(options.timeout > 0) {
			xhr.timeout = options.timeout;
		}

		this._updateStates({
			status: E_XHR_UploadState.CONNECTING,
			uploading: true,
			completed: false,
			aborted: false,
			error: null,
		});

		this.formData = formData;
	}

	/**
	 * @private
	 * Create XHR
	 * ---
	 * Override for tests
	 */
	_createXHR() {
		return new XMLHttpRequest();
	}

	/**
	 * @private
	 * Handle progress update
	 * ---
	 * Setup states data based on ProgressEvent
	 * @param {ProgressEvent} e Progress event
	 * @returns {XHRUploaderStates} Progress states
	 */
	_handleProgressUpdate(e) {
		clearTimeout(this._stalledTimeout);

		const {loaded, total} = e;
		// ~~ is similar to Math.floor()
		const progress = ~~(loaded / total * 100);
		const last = Date.now();

		let data = {
			elapsed: (last - this.startTime) / 1000,
			lastUpdateTime: last,
			loaded: loaded,
			progress: progress,
			indeterminate: loaded <= 0 || loaded >= total,
		};

		if(this.error) {
			data.status = E_XHR_UploadState.FAILED;
			data.indeterminate = true;
		}
		else if(!this.aborted) {
			if(progress < 100) {
				this._stalledTimeout = setTimeout(() => {
					this._updateStates({
						status: E_XHR_UploadState.STALLED,
					});
				}, 2000);
			}
			else {
				data.status = E_XHR_UploadState.PROCESSING;
				data.uploading = false;
			}
		}

		return data;
	}

	/**
	 * @private
	 * Handle ready state change
	 * ---
	 * Handle finished state (XMLHttpRequest.DONE)
	 * @param {XMLHttpRequest} xhr XMLHttpRequest instance
	 * @returns {XHRUploaderStates}
	 */
	_handleReadyStateChange(xhr) {
		if(xhr.readyState === XMLHttpRequest.DONE) {
			clearTimeout(this._stalledTimeout);

			let data = {
				indeterminate: false,
				uploading: false,
			};

			if(this.aborted) {
				this._notifyChange();
				return;
			}

			//XHR unavailable
			if(xhr.status === 0) {
				data.error = APIConnector.getErrorFromCode(E_HttpErrorCode.SERVICE_UNAVAILABLE, {message: "Server Unavailable"});
			}
			else if(xhr.status >= 400) {
				data.error = APIConnector.getErrorFromCode(xhr.status);
			}

			data.completed = !data.error;
			data.status = data.completed ? E_XHR_UploadState.COMPLETED : E_XHR_UploadState.FAILED;

			return data;
		}

		return {};
	}

	/**
	 * @private
	 * Update Uploader states
	 * ---
	 * @param {XHRUploaderStates} states States to update
	 */
	_updateStates(states) {
		if(states) {
			Object.keys(states).forEach(stateKey => {
				this[stateKey] = states[stateKey];
			});
			this._notifyChange();
		}
	}

	/**
	 * @private
	 * Notify change
	 * ---
	 * Notifies progress listeners with up to date states
	 * @see XHRUploader.states
	 */
	_notifyChange() {
		this.notify("progress", this.states);
	}
}
