import { Component } from "preact";
import PropTypes from "prop-types";
import { redirect } from "../../utils/redirect";

export class Redirect extends Component {
	componentDidMount() {
		const {to, href, replace} = this.props;

		redirect(to || href, replace);
	}

	render() {
		return null;
	}

	static get propTypes() {
		return {
			to: PropTypes.string.isRequired,
			href: PropTypes.string,

			replace: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			replace: true,
		}
	}
}
