import { Component } from "preact";
import PropTypes from "prop-types";
import Router from "preact-router";
import ProductGroupSelector from "../routes/ProductGroupSelector/ProductGroupSelector";
import { APP_ROUTE } from "../models/constants/AppRoute";
import { GlobalModal } from "./components";
import ProductConfiguratorWizard from "../routes/ProductConfigurator/ProductConfiguratorWizard";

class App extends Component {
	render({}, {}) {
		return (
			<>
				<div className={"version"}>{process.env.PACKAGE_VERSION}</div>
				<GlobalModal/>

				<Router>
					<ProductConfiguratorWizard path={APP_ROUTE.CONFIGURATOR()}/>
					<ProductGroupSelector default/>
				</Router>
			</>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default App;
