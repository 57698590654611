/**
 * Debouncer
 * ---
 * Debounces the callback function in delay interval
 */
export class Debouncer {
	/**
	 * @constructor
	 * Constructor
	 * ---
	 * @param {Number} delay
	 * @param {Function} callback OPTIONAL **WARNING!** If this value is set, callback function will be called upon Debouncer construction
	 * @param params
	 */
	constructor(delay = 200, callback = null, ...params) {
		this.timeout = null;
		this.delay = delay;
		this.callback = callback;

		if(callback) {
			this.trigger(callback, ...params);
		}
	}

	/**
	 * Call
	 * ---
	 * Immediately calls the callback function and starts debounce delay.
	 * @param {Function} callback Callback function
	 * @param {*} params Will be passed into the callback
	 */
	trigger(callback = this.callback, ...params) {
		if (!this.timeout) {
			callback(...params);
			this.timeout = setTimeout(() => {
				this.timeout = null;
			}, this.delay);
		}
	}
}
