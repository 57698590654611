import { Component as PComponent } from "preact";
import withProductGroups from "./withProductGroups";
import PropTypes from "prop-types";
import { APIConnector, ArrayUtils, get } from "@green24/npm-javascript-utils";
import ErrorBlock from "../../shared/ErrorBlock";
import { APP_ROUTE } from "../../../models/constants/AppRoute";
import Header from "../../shared/Header";

/**
 * A public higher-order component to access the imperative API
 */
export const withProducts = (Component, {showError = true} = {}) => {
	class HOC extends PComponent {
		render({groups, groupID, ...props}) {
			const productGroup = ArrayUtils.findByID(groups, groupID);

			if(!productGroup && showError) {
				return (
					<section>
						<Header breadcrumbs={[
							{
								text: '#' + groupID,
								href: APP_ROUTE.PRODUCT_GROUP(groupID),
							}
						]}/>

						<ErrorBlock
							className={"full-page"}
							error={APIConnector.getErrorFromCode(404)}
							dataOverrides={{
								title: "products.group.notFound",
							}}
							actionButtons={[
								{
									text: "redirect.home",
									href: APP_ROUTE.BASE,
								}
							]}
						/>
					</section>
				)
			}

			return (
				<Component
					products={get(productGroup, "products", [])}
					productGroup={productGroup}
					productGroupID={groupID}
					{...props}
				/>
			);
		}

		static get displayName() {
			return "withProducts(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				groupID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

				//withProductGroups
				groups: PropTypes.array.isRequired,
			};
		}
	}

	return withProductGroups(HOC);
};

export default withProducts;
