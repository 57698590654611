import { APP_CONSTANTS } from "../models/constants/AppConstants";
import { AppUtils as GenericAppUtils } from "@green24/npm-preact-utils";
import { LocalStorageEditor } from "@green24/npm-javascript-utils";

export class AppUtils extends GenericAppUtils {
	static get saveData() {
		return this.shouldSaveData(APP_CONSTANTS.LOCAL_STORAGE_KEY_IGNORE_SAVE_DATA, APP_CONSTANTS.LOCAL_STORAGE_KEY_SAVE_DATA);
	}

	static get localStorage() {
		if(this.hasWindow) {
			return {
				ignoreSaveData: localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_KEY_IGNORE_SAVE_DATA) == 1,
				saveData: LocalStorageEditor.get("siteSettings.saveData") == 1,
				api: localStorage.getItem("api"),
				theme: localStorage.getItem("theme"),
				lang: localStorage.getItem("lang"),
			}
		}

		return {};
	}

	static get showHiddenProductOptions() {
		if(this.isDev) {
			return !!localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_SHOW_HIDDDEN_OPTIONS);
		}
	}
}
