import { ArrayUtils, IS, ObjectUtils, SortRules } from "@green24/npm-javascript-utils";
import { AppUtils } from "./AppUtils";
import { E_CostUnit } from "../models/constants/Enums_Tariff";

export class ProductGroupUtils {
	static sortProductGroups(groups) {
		groups.sort((a, b) => SortRules.propertyAsc(a, b, "orderPosition"));

		groups.forEach(group => {
			ProductUtils.sortProducts(group.products);
		});
	}
}

export class ProductUtils {
	static get categories() {
		return ProductCategoriesUtils;
	}

	static getTotalCost(product, enabledFeatures) {
		const {cost, percent} = ProductFeaturesUtils.getEnabledFeaturesCost(enabledFeatures);
		const priceWithoutPercent = product.cost + cost;

		return priceWithoutPercent + priceWithoutPercent / 100 * percent;
	}

	static sortProducts(products) {
		products.sort((a, b) => SortRules.propertyAsc(a, b, "orderPosition"));
	}
}

export class ProductCategoriesUtils {
	static processCategories(projectCategories, enabledFeatures = []) {
		const enabledFeaturesIDs = ProductFeaturesUtils.getAllEnabledFeaturesIDs(enabledFeatures);

		return ArrayUtils.mapValid(projectCategories, category => {
			const groups = ArrayUtils.mapValid(category.groups, group => {
				const options = ArrayUtils.mapValid(group.options, option => {
					const {hidden, disabled} = ProductFeaturesUtils.getOptionStateFromRequirements(option, enabledFeaturesIDs);

					if(AppUtils.showHiddenProductOptions ? false : hidden) return;

					return {
						...option,
						disabled: disabled || (hidden ? AppUtils.showHiddenProductOptions : false),
					};
				});

				if(options.length || AppUtils.showHiddenProductOptions) {
					return {
						...group,
						options,
						disabled: options.length ? false : AppUtils.showHiddenProductOptions,
					};
				}
			});

			if(groups.length || AppUtils.showHiddenProductOptions) {
				return {
					...category,
					groups,
					disabled: groups.length ? false : AppUtils.showHiddenProductOptions,
				};
			}
		});
	}

	static getAllFeatures(categories) {
		return ArrayUtils.extract(categories, "groups.*.options");
	}

	static getFeaturesStates(categories) {
		let data = {};

		categories.forEach(category => {
			category.groups.forEach(group => {
				let activeOptions = group.options.filter(option => option.defaultState);
				data[group.id] = activeOptions.map(option => option.id);
			});
		});

		return data;
	}

	static getEnabledFeatures(categories, featuresStates = this.getFeaturesStates(categories)) {
		return ArrayUtils.mapValid(categories, category => {
			return ArrayUtils.mapValid(category.groups, group => {
				return group.options.filter(option => featuresStates[group.id]?.includes(option.id));
			});
		}).flat(2);
	}

	static findAllOptionsWithFailedRequirements(categories, enabledFeatures = []) {
		const enabledFeaturesIDs = ProductFeaturesUtils.getAllEnabledFeaturesIDs(enabledFeatures);

		return ArrayUtils.mapValid(categories, category => {
			return ArrayUtils.mapValid(category.groups, group => {
				return ArrayUtils.mapValid(group.options, option => {
					const {hidden, disabled} = ProductFeaturesUtils.getOptionStateFromRequirements(option, enabledFeaturesIDs);

					if(hidden || disabled) {
						return option;
					}
				});
			}, []);
		}, []).flat(3);
	}

	static findGroupFromOption(categories, option) {
		return ArrayUtils.findAndRetrieve(categories, category => {
			return ArrayUtils.findAndRetrieve(category.groups, group => {
				return group.options.some(({id}) => id == option.id) ? group : undefined;
			});
		});
	}

	static isObjectVisible(categories, modelName, enabledFeatures) {
		const groups = categories.map(category => category.groups).flat(1);

		const relevantOptionsGroups = groups.map(group => {
			return group.options.filter(option => {
				if(!IS.empty(option.links.model)) {
					return option.links.model.split(',').some(key => new RegExp('^' + key).test(modelName));
				}
				return false;
			});
		});

		if(relevantOptionsGroups.length == 0) return true;

		return relevantOptionsGroups.every(options => {
			return options.length == 0 || options.some(option => {
				return !!ArrayUtils.findByID(enabledFeatures, option.id);
			});
		});
	}
}

export class ProductFeaturesUtils {
	static getEnabledFeaturesCost(enabledFeatures) {
		enabledFeatures = enabledFeatures.filter(feature => !feature.costIndividual);

		const {currency, percentage} = ArrayUtils.categorize(enabledFeatures, {
			currency: feature => feature.costUnit == E_CostUnit.CURRENCY,
			percentage: feature => feature.costUnit == E_CostUnit.PERCENTAGE,
		});

		let cost = 0;
		currency.forEach(feature => {
			cost += feature.cost;
		});

		let percent = 0;
		percentage.forEach(feature => {
			percent+= feature.cost;
		});

		return {
			cost,
			percent,
		};
	}

	static getAllModelKeysFromFeatures(features) {
		const validFeatures = ArrayUtils.mapValid(features, feature => (feature.links.model || '').split(','), '').flat(1);
		return ArrayUtils.mapValid(validFeatures, undefined, '');
	}

	static getAllEnabledFeaturesModelKeys(enabledFeatures) {
		const validKeys = ArrayUtils.mapValid(enabledFeatures, feature => (feature.links.model || '').split(','), '').flat(1);
		return ArrayUtils.mapValid(validKeys, undefined, '');
	}

	static getAllEnabledFeaturesIDs(enabledFeatures) {
		return enabledFeatures.map(feature => feature.id);
	}

	static getFailedRequirementsForOption(option, enabledFeaturesIDs = []) {
		return option.requirements.filter(requirement => {
			switch (requirement.type) {
				case "OR":
					return !requirement.options.some(option => enabledFeaturesIDs.includes(option.id));
				case "AND":
				default:
					return requirement.options.some(option => !enabledFeaturesIDs.includes(option.id));
			}
		});
	}

	static getOptionStateFromRequirements(option, enabledFeaturesIDs) {
		const failedRequirements = ProductFeaturesUtils.getFailedRequirementsForOption(option, enabledFeaturesIDs);
		const hidden = failedRequirements.some(requirement => requirement.visibilityType == "GONE");
		const disabled = failedRequirements.some(requirement => requirement.visibilityType == "DISABLED");

		return {
			hidden,
			disabled,
		};
	}

	static removeInvalidFeatureStates(currentFeatureStates, invalidIDs) {
		return ObjectUtils.mapAsObject(currentFeatureStates, (key, value) => {
			return {
				key,
				value: ArrayUtils.filterByBlackAndWhiteList(value, invalidIDs),
			}
		});
	}

	static isInstallationLocationRequired(enabledFeatures) {
		return enabledFeatures.some(option => !!option.installationDescriptionRequired);
	}
}
