import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses, E_Autocomplete, IS } from "@green24/npm-javascript-utils";
import withInput from "./hoc/withInput";
import { ComponentUtils } from "@green24/npm-preact-utils";
import { Interpret } from "../../components";
import DynamicTextarea from "./DynamicTextarea";

class TextField extends Component {
	_renderInput(props) {
		const {textarea, fixture} = this.props;
		const inputProps = {
			...props,
			placeholder: ' ',
		};

		if(textarea) {
			return <DynamicTextarea {...inputProps}/>;
		}

		return <input {...inputProps} type={"text"} disabled={fixture}/>;
	}

	render({className, style, value, label, onModify, onBlur, required, invalid, translationProps, prefix, suffix, maxLength, counter, name, autocomplete, fixture}, {}) {
		value = value || '';
		const id = Math.random() + Date.now();
		const fieldID = "textField_" + id;

		return (
			<section
				className={combineClasses(
					"text-field input",
					required && "required",
					invalid && "invalid",
					counter && "with-counter",
					fixture && "fixture",
					className
				)}
				style={style}
			>
				<label className={"prefix-label"} for={fieldID}>
					{prefix}
				</label>

				<div className={combineClasses("input-wrapper", IS.empty(value) && "empty",)}>
					{
						counter &&
						<label for={fieldID} className={"counter"}>
							{
								maxLength &&
								<span>{`${value}`.length} / {maxLength}</span>
								||
								<span>{`${value}`.length}</span>
							}
						</label>
					}

					{this._renderInput({
						id: fieldID,
						name,
						autocomplete,

						value,
						required,
						maxLength,

						onInput: e => onModify(e.target.value),
						onBlur,
					})}

					<label
						className={"field-label"}
						for={fieldID}
					>
						{
							label &&
							ComponentUtils.resolvePolymorphProp(
								label,
								{
									function: f => f(required, translationProps),
									string: s => (
										<>
											<Interpret id={s} {...translationProps}/>
											{required && <b>•</b>}
										</>
									),
								},
								() => label,
							)
						}
					</label>
				</div>

				<label className={"suffix-label"} for={fieldID}>
					{suffix}
				</label>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			prefix: PropTypes.any,
			suffix: PropTypes.any,

			label: PropTypes.any,
			translationProps: PropTypes.object,
			counter: PropTypes.bool,
			maxLength: PropTypes.number,
			textarea: PropTypes.bool,
			required: PropTypes.bool,
			invalid: PropTypes.bool,
			name: PropTypes.string,
			autocomplete: PropTypes.oneOf(Object.values(E_Autocomplete)),
			fixture: PropTypes.bool,

			//withInput
			value: PropTypes.string,
			onModify: PropTypes.func,
			onBlur: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			textarea: false,
			fixture: false,
		}
	}
}

export default withInput(TextField);
