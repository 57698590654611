import { M_Logger_DefaultScopeData } from "../models/Models_Logger";
import { LocalStorageEditor } from "./Storage/LocalStorage/LocalStorageEditor";

let scopes = {};
if(typeof window !== "undefined") {
	scopes = window.__LOGGER_SCOPES__ = {};
}

export class Logger {
	constructor(scope, {
		scopesRoot = scopes,
		scopeData = M_Logger_DefaultScopeData,
	} = {}) {
		this._root = scopesRoot;
		this._scope = scope;

		if(typeof localStorage !== "undefined") {
			Logger.registerScope(scope, {
				...scopeData,
				...LocalStorageEditor.get(`logger.${scope}`),
			});
		}
		else {
			Logger.registerScope(scope, {...scopeData});
		}
	}

	static get model() {
		return {
			defaultScopeData: M_Logger_DefaultScopeData,
		}
	}

	static registerScope(scope, defaultScopeData = M_Logger_DefaultScopeData) {
		scopes[scope] = defaultScopeData;
	}

	static isScopeAllowed(scope, type) {
		return scopes[scope][type];
	}

	static getScopes() {
		return Object.keys(scopes);
	}

	static getScopeData(scope) {
		return scopes[scope];
	}

	static toggleType(scope, type, enable = undefined) {
		enable = enable === undefined ? !scopes[scope][type] : enable;

		scopes[scope][type] = enable;

		if(typeof localStorage !== "undefined") {
			LocalStorageEditor.set(`logger.${scope}.${type}`, enable);
		}
	}

	static enableType(scope, type) {
		this.toggleType(scope, type, true);
	}

	static disableType(scope, type) {
		this.toggleType(scope, type, false);
	}

	isScopeAllowed() {
		return this._root[this._scope];
	}

	/**
	 * Is type allowed
	 * ---
	 * @param {"info"|"warning"|"error"|string} type
	 * @returns {boolean}
	 */
	isTypeAllowed(type = "info") {
		return this._root[this._scope][type];
	}

	toggleType(type, enable = undefined) {
		Logger.toggleType(this._scope, type, enable);
	}

	enableType(type) {
		Logger.enableType(this._scope, type);
	}

	disableType(type) {
		Logger.disableType(this._scope, type);
	}
}
