import { IS } from "../IS";

export class LocalizedRegion {
	constructor(regionData) {
		let {terms, regionCode} = regionData;

		this._regionCode = regionCode.toLowerCase();
		this._terms = terms;
	}

	get terms() {
		return this._terms;
	}

	get ISOCode() {
		return this._regionCode;
	}

	hasTerm(termKey) {
		return IS.property(this.terms, termKey);
	}

	getTerm(termKey) {
		return this.terms[termKey];
	}
}
