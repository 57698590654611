// Console styles
export const CONSOLE_ERROR_BOLD = "font-size: 1.2em; font-weight: bold; color: red";
export const CONSOLE_WARN_BOLD = "font-size: 1.2em; font-weight: bold; color: orange";

export const JSON_REACT_REPLACER_KEYS = ['$$typeof', 'type', 'ref', '_owner', '_store', '_self'];

export const E_Modification = {
	ARRAY_PUSH: "ARRAY_PUSH",
	ARRAY_SPLICE: "ARRAY_SPLICE",
	ARRAY_CLEAR: "ARRAY_CLEAR",
	ARRAY_REMOVE_BY_ID: "ARRAY_REMOVE_BY_ID",
	ITEM_SET: "ITEM_SET",
	ITEM_REMOVE: "ITEM_REMOVE"
};

export const E_Autocomplete = {
	/**
	 * **Note:** In most modern browsers, setting autocomplete to "off" will not prevent a password manager
	 * from asking the user if they would like to save username and password information, or from
	 * automatically filling in those values in a site's login form.
	 *
	 * If you are defining a user management page where a user can specify a new password for another person,
	 * and therefore you want to prevent auto-filling of password fields, you can use **autocomplete="new-password"**
	 */
	OFF: "off",
	ON: "on",

	//Names
	NAME: "name",
	HONORIFIC_PREFIX: "honorific-prefix",
	GIVEN_NAME: "given-name",
	ADDITIONAL_NAME: "additional-name",
	FAMILY_NAME: "family-name",
	HONORIFIC_SUFFIX: "honorific-suffix",
	NICKNAME: "nickname",

	EMAIL: "email",
	USERNAME: "username",

	NEW_PASSWORD: "new-password",
	CURRENT_PASSWORD: "current-password",

	ONE_TIME_CODE: "one-time-code",

	ORGANIZATION_TITLE: "organization-title",
	ORGANIZATION: "organization",

	STREET_ADDRESS: "street-address",

	//Each individual line of the street address.
	//These should only be present if the "street-address" is not present.
	ADDRESS_LINE1: "address-line1",
	ADDRESS_LINE2: "address-line2",
	ADDRESS_LINE3: "address-line3",

	//@see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Administrative_levels_in_addresses
	ADDRESS_LEVEL1: "address-level1", //The first administrative level in the address. This is typically the province in which the address is located. In the United States, this would be the state. In Switzerland, the canton. In the United Kingdom, the post town.
	ADDRESS_LEVEL2: "address-level2", //The second administrative level, in addresses with at least two of them. In countries with two administrative levels, this would typically be the city, town, village, or other locality in which the address is located.
	ADDRESS_LEVEL3: "address-level3", //The third administrative level, in addresses with at least three administrative levels.
	ADDRESS_LEVEL4: "address-level4", //The finest-grained administrative level, in addresses which have four levels.

	COUNTRY: "country",
	COUNTRY_NAME: "country-name",
	POSTAL_CODE: "postal-code",

	CC_NAME: "cc-name",
	CC_GIVEN_NAME: "cc-given-name",
	CC_ADDITIONAL_NAME: "cc-additional-name",
	CC_FAMILY_NAME: "cc-family-name",
	CC_NUMBER: "cc-number",
	CC_EXP: "cc-exp",
	CC_EXP_MONTH: "cc-exp-month",
	CC_EXP_YEAR: "cc-exp-year",
	CC_CSC: "cc-csc",
	CC_TYPE: "cc-type",

	TRANSACTION_CURRENCY: "transaction-currency",
	TRANSACTION_AMOUNT: "transaction-amount",

	LANGUAGE: "language",

	BDAY: "bday",
	BDAY_DAY: "bday-day",
	BDAY_MONTH: "bday-month",
	BDAY_YEAR: "bday-year",

	SEX: "sex",

	TEL: "tel",
	TEL_COUNTRY_CODE: "tel-country-code",
	TEL_NATIONAL: "tel-national",
	TEL_AREA_CODE: "tel-area-code",
	TEL_LOCAL: "tel-local",
	TEL_EXTENSION: "tel-extension",

	IMPP: "impp",

	URL: "url",

	PHOTO: "photo",
};

export const E_InputMode = {
	NONE: "none",
	TEXT: "text",
	/**
	 * A telephone keypad input, including the digits 0–9, the asterisk (*), and the pound (#) key.
	 * Inputs that require a telephone number should typically use <input type="tel">instead.
	 */
	TEL: "tel",
	/**
	 * A keypad optimized for entering URLs. This may have the / key more prominent, for example. Enhanced features could include history access and so on.
	 * Inputs that require a URL should typically use <input type="url"> instead.
	 */
	URL: "url",
	/**
	 * A virtual keyboard optimized for entering email addresses. Typically includes the @ character as well as other optimizations.
	 * Inputs that require email addresses should typically use <input type="email"> instead.
	 */
	EMAIL: "email",
	/**
	 * Numeric input keyboard, but only requires the digits 0–9. Devices may or may not show a minus key.
	 */
	NUMERIC: "numeric",
	/**
	 * Fractional numeric input keyboard containing the digits and decimal separator for the user's locale (typically . or ,). Devices may or may not show a minus key (-).
	 */
	DECIMAL: "decimal",
	/**
	 * A virtual keyboard optimized for search input. For instance, the return/submit key may be labeled “Search”, along with possible other optimizations.
	 * Inputs that require a search query should typically use <input type="search"> instead.
	 */
	SEARCH: "search",
};
