import { IS } from "../IS";
import { ResponseHandlers } from "./ResponseHandlers";
import { BasicServices, BasicServicesV2 } from "./BasicServices";

/**
 * Function that modifies data either upon receival or right before the upload.
 * This should correct any inconsistencies when sending/receiving data that cannot be processed by the API or the frontend elements.
 * ```
 *  //Example
 *  Frontend uses vaadin-datepicker as a datepicker input.
 *  This input uses format YYYY-MM-DD but the server expects (and returns at that matter) full ISO date (e.g. 2021-03-17T00:00:00Z).
 *  As such the date must be converted to YYYY-MM-DD when received and converted back to ISO upon sending.
 * ```
 * @typedef {function(Response): Response} Processor
 */

/**
 * @typedef {Processor} UploadProcessor
 */

/**
 * @typedef {Processor} ReceiveProcessor
 */

/**
 * @typedef {Object} ItemData
 */

/**
 * @typedef {Object & M_APIConnector_RequestOptions} AdditionalConfiguration
 */

// noinspection JSUnusedGlobalSymbols
/**
 * @deprecated - Use GenericServicesV2
 * Generic services
 * ---
 * Collection of generic service requests that are used throughout the application.
 */
export class GenericServices extends BasicServices {
	/**
	 * @constructor
	 * @param {APIConnector} apiConnector
	 * @param {String} rootURL API methods root (or shared) URL
	 * @param {Processor} receiveProcessor
	 * @param {Processor} uploadProcessor
	 */
	constructor(apiConnector, rootURL, receiveProcessor, uploadProcessor) {
		super(apiConnector, rootURL);

		this._receiveProcessor = receiveProcessor;
		this._uploadProcessor = uploadProcessor;
	}

	get listURL() {
		return this.url + '/';
	}

	get itemURL() {
		return this.url;
	}

	get updateURL() {
		return this.url;
	}

	get createURL() {
		return this.url;
	}

	get deleteURL() {
		return this.url;
	}

	/**
	 * Get list
	 * ---
	 * Requests pageable list from the server.
	 * @param {Object} queryData
	 * @param {AbortSignal} signal
	 * @param {Processor} processor On receive data processor
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getList(queryData, signal, processor = data => data, additionalConfiguration) {
		return GenericServices.getList(this.apiConnector, this.listURL, queryData, signal, processor, additionalConfiguration);
	}

	/**
	 * Get list
	 * ---
	 * Requests pageable list from the server.
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Object} queryData
	 * @param {AbortSignal} signal
	 * @param {Processor} processor
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	static getList(ApiConnector, url, queryData, signal, processor = data => data, additionalConfiguration) {
		return ApiConnector.sendRequest({
			url,
			signal,
			query: queryData,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, processor));
	}

	/**
	 * Get item
	 * ---
	 * Requests specific item data
	 * @param {Number} itemID
	 * @param {Processor} processor
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getItem(itemID, processor = data => data, signal, additionalConfiguration) {
		return GenericServices.getItem(this.apiConnector, this.itemURL, itemID, processor, signal, additionalConfiguration);
	}

	/**
	 * Get item
	 * ---
	 * Requests specific item data
	 * @param {Number} itemID
	 * @param {Processor} processorOverride
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getItemForEdit(itemID, processorOverride, signal, additionalConfiguration) {
		return this.getItem(itemID, processorOverride || this._receiveProcessor, signal, additionalConfiguration);
	}

	/**
	 * Get item
	 * ---
	 * Requests specific item data
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Number} itemID
	 * @param {Processor} processor
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	static getItem(ApiConnector, url, itemID = undefined, processor = data => data, signal, additionalConfiguration) {
		return ApiConnector.sendRequest({
			url: url + (itemID === undefined ? '' : `/${itemID}`),
			signal,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, processor));
	}

	/**
	 * Save item
	 * ---
	 * Attempts to save an item.
	 * Based on content's **id** property the item is either created (id not present or 0|null|undefined) or updated (id is present and id > 0).
	 * @param {Object} itemData
	 * @param {Processor} uploadProcessorOverride
	 * @param {Processor} receiveProcessorOverride
	 * @param {AbortSignal} signal
	 * @param additionalUploadConfiguration Additional upload API request configuration
	 * @param additionalReceiveConfiguration Additional receive API request configuration
	 * @returns {Promise<Response>}
	 */
	saveItem(itemData, uploadProcessorOverride, receiveProcessorOverride, signal, additionalUploadConfiguration, additionalReceiveConfiguration) {
		return GenericServices.saveItem(
			this.apiConnector,
			this.updateURL,
			this.createURL,
			itemData,
			(apiConnector, url, ...props) => this.updateItem(...props),
			(apiConnector, url, ...props) => this.createItem(...props),
			uploadProcessorOverride || this._uploadProcessor,
			receiveProcessorOverride || this._receiveProcessor,
			signal,
			additionalUploadConfiguration,
			additionalReceiveConfiguration,
		);
	}

	/**
	 * Save item
	 * ---
	 * Attempts to save an item.
	 * Based on content's **id** property the item is either created (id not present or 0|null|undefined) or updated (id is present and id > 0).
	 * @param {APIConnector} ApiConnector
	 * @param {String} updateURL API update method URL
	 * @param {String} createURL API create method URL
	 * @param {Object} itemData
	 * @param {function(updateURL, ItemData, UploadProcessor, ReceiveProcessor, signal): Promise<Response>} updateService
	 * @param {function(createURL, ItemData, UploadProcessor, ReceiveProcessor, signal): Promise<Response>} createService
	 * @param {Processor} uploadProcessor
	 * @param {Processor} receiveProcessor
	 * @param {AbortSignal} signal
	 * @param additionalUploadConfiguration Additional upload API request configuration
	 * @param additionalReceiveConfiguration Additional receive API request configuration
	 * @returns {Promise<Response>}
	 */
	static saveItem(ApiConnector, updateURL, createURL = updateURL, itemData, updateService = GenericServices.updateItem, createService = GenericServices.createItem, uploadProcessor = data => data, receiveProcessor = data => data, signal, additionalUploadConfiguration, additionalReceiveConfiguration) {
		if(IS.property(itemData, "id") && itemData.id > 0) {
			return updateService(ApiConnector, updateURL, itemData, uploadProcessor, receiveProcessor, signal, additionalUploadConfiguration, additionalReceiveConfiguration);
		}
		return createService(ApiConnector, createURL, itemData, uploadProcessor, receiveProcessor, signal, additionalUploadConfiguration, additionalReceiveConfiguration);
	}

	/**
	 * Update item
	 * ---
	 * Sends request to update an item
	 * @param {Object} itemData
	 * @param {Processor} uploadProcessorOverride
	 * @param {Processor} receiveProcessorOverride
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	updateItem(itemData, uploadProcessorOverride, receiveProcessorOverride, signal, additionalConfiguration) {
		return GenericServices.updateItem(
			this.apiConnector,
			this.updateURL,
			itemData,
			uploadProcessorOverride || this._uploadProcessor,
			receiveProcessorOverride || this._receiveProcessor,
			signal,
			additionalConfiguration,
		);
	}

	/**
	 * Update item
	 * ---
	 * Sends request to update an item
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Object} itemData
	 * @param {Processor} uploadProcessor
	 * @param {Processor} receiveProcessor
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	static updateItem(ApiConnector, url, itemData, uploadProcessor = data => data, receiveProcessor = data => data, signal, additionalConfiguration) {
		const data = itemData ? uploadProcessor(JSON.parse(JSON.stringify(itemData))) : itemData;

		return ApiConnector.sendRequest({
			url: url + (data.id === undefined ? '' : `/${data.id}`),
			method: "PUT",
			body: JSON.stringify(data),
			signal,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, receiveProcessor));
	}

	/**
	 * Create item
	 * ---
	 * Sends request to creat a new item
	 * @param {Object} itemData
	 * @param {Processor} uploadProcessorOverride
	 * @param {Processor} receiveProcessorOverride
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	createItem(itemData, uploadProcessorOverride, receiveProcessorOverride, signal, additionalConfiguration) {
		return GenericServices.createItem(
			this.apiConnector,
			this.createURL,
			itemData,
			uploadProcessorOverride || this._uploadProcessor,
			receiveProcessorOverride || this._receiveProcessor,
			signal,
			additionalConfiguration,
		);
	}

	/**
	 * Create item
	 * ---
	 * Sends request to creat a new item
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Object} itemData
	 * @param {Processor} uploadProcessor
	 * @param {Processor} receiveProcessor
	 * @param {AbortSignal} signal
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	static createItem(ApiConnector, url, itemData, uploadProcessor = data => data, receiveProcessor = data => data, signal, additionalConfiguration) {
		const data = itemData ? uploadProcessor(JSON.parse(JSON.stringify(itemData))) : itemData;

		return ApiConnector.sendRequest({
			url: url,
			method: "POST",
			body: JSON.stringify(data),
			signal,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, receiveProcessor));
	}

	/**
	 * Delete item
	 * ---
	 * Sends request to delete an item.
	 * @param {Number} itemID
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	deleteItem(itemID, additionalConfiguration) {
		return GenericServices.deleteItem(this.apiConnector, this.deleteURL, itemID, additionalConfiguration);
	}

	/**
	 * Delete item
	 * ---
	 * Sends request to delete an item.
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Number} itemID
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	static deleteItem(ApiConnector, url, itemID, additionalConfiguration) {
		return ApiConnector.sendRequest({
			url: url + (itemID === undefined ? '' : `/${itemID}`),
			method: "DELETE",
			...additionalConfiguration,
		}, ResponseHandlers.plain);
	}
}

// noinspection JSUnusedGlobalSymbols
/**
 * Generic services v2
 * ---
 * Collection of a requests that are commonly used throughout the application.
 */
export class GenericServicesV2 extends BasicServicesV2 {
	/**
	 * @constructor
	 * @param {APIConnector} apiConnector
	 * @param {String} rootURL Root (shared) URL for the services instance
	 * @param {{
	 *   itemResponseProcessor?: Processor,
	 *   uploadItemProcessor?: Processor,
	 *   uploadResponseProcessor?: Processor,
	 *   uploadCloneDataResolver?: function(Object): Object,
	 * }} options
	 */
	constructor(apiConnector, rootURL, options) {
		super(apiConnector, rootURL);

		this.options = options;
	}

	set options(options) {
		this._options = {
			itemResponseProcessor: res => res,
			uploadItemProcessor: item => item,
			uploadCloneDataResolver: item => JSON.parse(JSON.stringify(item)),
			uploadResponseProcessor: res => res,
			...options,
		}
	}

	get options() {
		return this._options;
	}

	getListUrl() {
		return this.url + '/';
	}

	getItemUrl() {
		return this.url;
	}

	getUpdateUrl() {
		return this.url;
	}

	getCreateUrl() {
		return this.url;
	}

	getDeleteUrl() {
		return this.url;
	}

	/**
	 * Get list
	 * ---
	 * Requests a pageable list
	 * @param {Object} queryData
	 * @param {AdditionalConfiguration} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getList(queryData, additionalConfiguration = {}) {
		return this.sendRequest({
			url: this.getListUrl(),
			query: queryData,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, this._mergeProcessors(undefined, additionalConfiguration.processor)));
	}

	/**
	 * Get item
	 * ---
	 * Requests a specific item data
	 * @param {Number} itemID
	 * @param {AdditionalConfiguration & {optimizeForEdit: boolean}} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getItem(itemID = undefined, additionalConfiguration = {}) {
		return this.sendRequest(
			{
				url: this.getItemUrl() + (itemID === undefined ? '' : `/${itemID}`),
				...additionalConfiguration,
			},
			res => {
				return ResponseHandlers.json(
					res,
					this._mergeProcessors(
						additionalConfiguration.optimizeForEdit ? this.options.uploadResponseProcessor : undefined,
						additionalConfiguration.processor
					)
				);
			}
		);
	}

	/**
	 * Get item
	 * ---
	 * Requests a specific item data (with edit processor)
	 * @param {Number} itemID
	 * @param {AdditionalConfiguration & {optimizeForEdit: boolean}} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	getItemForEdit(itemID = undefined, additionalConfiguration = {}) {
		return this.getItem(itemID, {
			optimizeForEdit: true,
			...additionalConfiguration,
		});
	}

	/**
	 * Update item
	 * ---
	 * Sends a request to update an item
	 * @param {Object} itemData
	 * @param {AdditionalConfiguration} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	updateItem(itemData, additionalConfiguration = {}) {
		const data = itemData ? this.options.uploadItemProcessor(this.options.uploadCloneDataResolver(itemData)) : itemData;

		return this.sendRequest({
			url: this.getUpdateUrl() + (data.id === undefined ? '' : `/${data.id}`),
			method: "PUT",
			body: data,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, this._mergeProcessors(this.options.uploadResponseProcessor, additionalConfiguration.processor)));
	}

	/**
	 * Create item
	 * ---
	 * Sends request to creat a new item
	 * @param {Object} itemData
	 * @param {AdditionalConfiguration} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	createItem(itemData, additionalConfiguration = {}) {
		const data = itemData ? this.options.uploadItemProcessor(this.options.uploadCloneDataResolver(itemData)) : itemData;

		return this.sendRequest({
			url: this.getCreateUrl(),
			method: "POST",
			body: data,
			...additionalConfiguration,
		}, res => ResponseHandlers.json(res, this._mergeProcessors(this.options.uploadResponseProcessor, additionalConfiguration.processor)));
	}

	/**
	 * Save item
	 * ---
	 * Attempts to save an item.
	 * Based on content's **id** property the item is either created (id not present or <=0|null|undefined|) or updated (id is present and id > 0).
	 * @param {Object} itemData
	 * @param {AdditionalConfiguration} additionalUpdateConfiguration Additional update configuration
	 * @param {AdditionalConfiguration} additionalCreateConfiguration Additional create configuration
	 * @returns {Promise<Response>}
	 */
	saveItem(itemData, additionalUpdateConfiguration, additionalCreateConfiguration) {
		if(IS.property(itemData, "id") && itemData.id > 0) {
			return this.updateItem(itemData, additionalUpdateConfiguration);
		}
		return this.createItem(itemData, additionalCreateConfiguration);
	}

	/**
	 * Delete item
	 * ---
	 * Sends a request to delete an item.
	 * @param {Number} itemID
	 * @param {AdditionalConfiguration} additionalConfiguration Additional API request configuration
	 * @returns {Promise<Response>}
	 */
	deleteItem(itemID, additionalConfiguration) {
		return this.sendRequest({
			url: this.getDeleteUrl() + (itemID === undefined ? '' : `/${itemID}`),
			method: "DELETE",
			...additionalConfiguration,
		}, ResponseHandlers.plain);
	}

	_mergeProcessors(typeProcessor = data => data, overrideProcessor) {
		return data => {
			data = this.options.itemResponseProcessor(data);

			//Processor provided via additionalConfiguration
			if(overrideProcessor) {
				return overrideProcessor(data);
			}

			//Processor for a specific type
			// (e.g. uploadResponseProcessor that should be used only on requests that are called from the edit like update or create item)
			return typeProcessor(data);
		}
	}
}
