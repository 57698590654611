import { Component } from "preact";
import PropTypes from "prop-types";
import Loading from "./Loading";
import ErrorBlock from "./ErrorBlock";
import { IS } from "@green24/npm-javascript-utils";

class Loader extends Component {
	componentDidMount() {
		const {refreshOnMount} = this.props;
		refreshOnMount && this.refresh();
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		if(this.props.onShouldRefresh(previousProps, previousState, this.props, this.state)) {
			this.refresh();
		}
	}

	render({className, style, children, showLoading, loadingProps, showError, errorBlockProps}, {}) {
		const loading = this.props.loading || this.state.loading;
		if(showLoading && loading) {
			return <Loading {...loadingProps}/>
		}

		const error = this.props.error || this.state.error;
		if(showError && error) {
			return <ErrorBlock error={error} onRetry={() => this.refresh()} {...errorBlockProps}/>
		}

		const data = this.props.data || this.state.data;
		return children({
			data,
			loading,
			error,
			refresh: (params) => this.refresh(params),
		});
	}

	refresh(paramsOverride) {
		const {onService, params} = this.props;

		if(IS.fnc(onService)) {
			this.setState({loading: true, error: null});

			onService(paramsOverride || params).then(data => {
				this.setState({data});
			}, res => {
				this.setState({error: res});
			}).finally(() => {
				this.setState({loading: false});
			});
		}
	}

	static get sharedTypes() {
		return {
			loading: PropTypes.bool,
			error: PropTypes.object,
			data: PropTypes.any,
		}
	}

	static get propTypes() {
		return {
			...this.sharedTypes,

			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.func.isRequired,

			params: PropTypes.any,
			refreshOnMount: PropTypes.bool,

			showLoading: PropTypes.bool,
			loadingProps: PropTypes.object,
			showError: PropTypes.bool,
			errorBlockProps: PropTypes.object,

			//onService or onThunk + onSelector are required
			onService: PropTypes.func,

			onShouldRefresh: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...this.sharedTypes,
		}
	}

	static get defaultProps() {
		// noinspection JSUnusedLocalSymbols
		return {
			showLoading: true,
			showError: true,
			refreshOnMount: true,

			onShouldRefresh: (prevProps, prevState, newProps, newState) => {
				if(prevProps.params === undefined && newProps.params === undefined) return true;

				return !IS.equal(prevProps.params, newProps.params);
			},
		}
	}
}

export default Loader;
