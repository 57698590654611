import { APIConnector, BasicServices, GenericServices } from "@green24/npm-javascript-utils";
import root from "window-or-global";
import { AppUtils } from "../utils/AppUtils";

export const getApi = () => AppUtils.localStorage.api || `https://api.produkty.green24.cz`;

export const apiConnector = root.apiConnector = new APIConnector(getApi());

export class ProductConfiguratorBasicServices extends BasicServices {
	constructor(rootURL) {
		super(apiConnector, rootURL);
	}
}

export class ProductConfiguratorGenericServices extends GenericServices {
	constructor(rootURL, receiveProcessor, uploadProcessor) {
		super(apiConnector, rootURL, receiveProcessor, uploadProcessor);
	}
}
