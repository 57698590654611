import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import { AppUtils } from "../../../utils/AppUtils";
import ButtonsConstructor from "../../shared/input/Button/ButtonsConstructor";
import ActiveLanguagePicker from "../../shared/L10N/ActiveLanguagePicker";
import { Interpret } from "../../components";
import withSiteSettingsContext from "./hoc/withSiteSettingsContext";
import RadioGroup from "../../shared/input/RadioGroup";
import ToggleButton from "../../shared/input/ToggleButton";
import CurrencySelector from "../../shared/input/CurrencySelector";

class SiteSettings extends Component {
	constructor(props) {
		super();

		this.state = {
			opened: props.opened,
		}
	}

	componentDidMount() {
		if(AppUtils.hasWindow) {
			window.siteSettings = this;
		}
	}

	render({className, style, siteSettingsContext}, {opened}) {
		return (
			<section
				className={combineClasses(
					"site-settings",
					opened && "opened",
					className
				)}
				style={style}
			>
				<div className={"shadow"} onClick={() => this.close()}/>

				<div className={"settings-panel"}>
					<div className={"header"}>
						<ButtonsConstructor buttons={[
							{
								icon: "times",
								text: "settings",
								className: "close no-style",
								action: () => this.close(),
							}
						]}/>
					</div>

					<div className={"settings"}>
						<label><Interpret id={"settings.language"}/></label>
						<ActiveLanguagePicker/>

						<label><Interpret id={"settings.currency"}/></label>
						<CurrencySelector
							value={siteSettingsContext.currency}
							onModify={currency => siteSettingsContext.setCurrency(currency)}
						/>

						<label><Interpret id={"settings.theme"}/></label>
						<RadioGroup
							options={[
								{
									text: "settings.theme.light",
									value: "light",
								},
								{
									text: "settings.theme.dark",
									value: "dark",
								}
							]}
							value={siteSettingsContext.theme}
							onModify={theme => siteSettingsContext.setTheme(theme)}
						/>

						<label><Interpret id={"settings.other"}/></label>
						<ToggleButton text={"settings.saveData"} value={siteSettingsContext.saveData} onModify={saveData => siteSettingsContext.setSaveData(saveData)}/>
					</div>

					<div className={"version no-style"}>
						{process.env.PACKAGE_VERSION}
					</div>
				</div>
			</section>
		);
	}

	open() {
		this.setState({opened: true});
	}

	close() {
		this.setState({opened: false});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//withSiteSettingsContext
			siteSettingsContext: PropTypes.object,
		}
	}
}

export default withSiteSettingsContext(SiteSettings);
