import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, IS, get, FormatUtils } from "@green24/js-utils";
import { ComponentUtils } from "../../class/ComponentUtils";
import withLocalizationContext from "../L10N/hoc/withLocalizationContext";
import withCurrencyContext from "./hoc/withCurrencyContext";

class Price extends Component {
	render({className, style, wrapper, children}, {}) {
		const priceData = this._getPriceData();

		if(IS.fnc(children)) {
			children(priceData);
		}

		if(wrapper) {
			return ComponentUtils.wrapWithComponent(
				priceData.text,
				wrapper,
				{
					className: combineClasses("price", className),
					style
				}
			);
		}

		return priceData.text;
	}

	_getPriceData() {
		const {currency, localizationContext, value, locale, currencyContext, options, fractionDigits, removeFractionsIfZero} = this.props;
		const __instanceConstructor = opt => localizationContext ? localizationContext.internationalizer.getNumberFormat(opt) : locale;

		return FormatUtils.formatAsPrice(
			locale || __instanceConstructor,
			value,
			currency || get(currencyContext, "currency"),
			{
				fractionDigits,
				removeFractionDigitsIfZero: removeFractionsIfZero,
				...options,
			},
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			value: PropTypes.number.isRequired,
			currency: PropTypes.string,

			wrapper: PropTypes.element,
			locale: PropTypes.string,
			/**
			 * @type NumberFormatOptions
			 */
			options: PropTypes.object,

			//Quick format options
			/**
			 * Toggle or set the maximum amount of fraction digits
			 */
			fractionDigits: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
			/**
			 * Removes the decimals/fractions if the value is "00"
			 */
			removeFractionsIfZero: PropTypes.bool,

			//withLocalizationContext
			localizationContext: PropTypes.object,

			//withCurrencyContext
			currencyContext: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			value: 0,
			options: {},
			removeFractionsIfZero: false,
		}
	}
}

export default withCurrencyContext(withLocalizationContext(Price));
