import { cloneElement, Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, resolvePolymorphVar } from "@green24/js-utils";
import { IconsContext } from "./IconsProvider";
import { AppUtils } from "../../class/AppUtils";
import { RIS } from "../../class/ReactIS";

class Icon extends Component {
	render({className, style, icon, placeholder, softErrors, suppressErrors, icons, onFindIconFromIcons}, {}) {
		return (
			<IconsContext.Consumer>
				{(iconsContext) => {
					if(!iconsContext && !icons) {
						if(AppUtils.isDev && !suppressErrors) {
							if(softErrors) {
								console.error(`IconsContext is missing for ${icon} icon.`)
							}
							else {
								throw Error(`IconsContext is missing for ${icon}.`);
							}
						}

						return placeholder;
					}

					let svg = RIS.component(icon) ? icon : (icons ? onFindIconFromIcons(icons, icon) : iconsContext.findIcon(icon));
					if(!svg && AppUtils.isDev) {
						console.error("Unknown icon:", icon);

						return placeholder;
					}

					//svgr (webpack plugin) exports SVGs as functional constructor
					if(typeof svg === "function") {
						//Initialize the component so it can be used in the React.cloneElement()
						svg = svg();
					}

					return cloneElement(
						svg,
						{
							style,
							icon,
							className: resolvePolymorphVar(
								className,
								{
									function: f => f(),
								},
								() => combineClasses("i", className),
								true
							),
						}
					)
				}}
			</IconsContext.Consumer>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			icon: PropTypes.string.isRequired,

			placeholder: PropTypes.any,

			suppressErrors: PropTypes.bool,
			softErrors: PropTypes.bool,

			icons: PropTypes.any,
			onFindIconFromIcons: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			placeholder: (
				<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill="#FF00FF" style={{width: "1em", height: "1em"}}>
					<rect fill="none" height="24" width="24"/>
					<path d="M7.02,13c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4S9.23,13,7.02,13z M13,14v6c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1v-6 c0-0.55-0.45-1-1-1h-6C13.45,13,13,13.45,13,14z M6.13,3.57l-3.3,5.94C2.46,10.18,2.94,11,3.7,11h6.6c0.76,0,1.24-0.82,0.87-1.49 l-3.3-5.94C7.49,2.89,6.51,2.89,6.13,3.57z M19.25,2.5c-1.06,0-1.81,0.56-2.25,1.17c-0.44-0.61-1.19-1.17-2.25-1.17 C13.19,2.5,12,3.78,12,5.25c0,1.83,2.03,3.17,4.35,5.18c0.37,0.32,0.92,0.32,1.3,0C19.97,8.42,22,7.08,22,5.25 C22,3.78,20.81,2.5,19.25,2.5z"/>
				</svg>
			),
			onFindIconFromIcons: (icons, icon) => icons[icon],
		}
	}
}

export default Icon;
