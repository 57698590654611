import { cloneElement } from "preact";
import { combineClasses, resolvePolymorphVar } from "@green24/js-utils";
import { RIS } from "./ReactIS";
import { AppUtils } from "./AppUtils";

export class ComponentUtils {
	/**
	 * Resolve polymorph prop
	 * ---
	 * Handles prop data based on options.
	 *
	 * **Warning!** All options must be defined according "[type]: () => ..." formula
	 * @param {*} prop
	 * @param {Object} options
	 * @param {*} fallback
	 * @return {*}
	 */
	static resolvePolymorphProp(prop, options, fallback = () => null) {
		return resolvePolymorphVar(
			prop,
			{
				...options,
				object: (o) => {
					if(RIS.component(o)) {
						return RIS.property(options, "component") ? options.component(o) : fallback();
					}

					return RIS.property(options, "object") ? options.object(o) : fallback();
				}
			},
			fallback,
			true
		);
	}

	/**
	 * Propagate props
	 * ---
	 * @param {Component} component
	 * @param {Object} props
	 * @param {boolean} suppressWarning
	 * @return {Component}
	 */
	static propagateProps(component, props, suppressWarning = false) {
		if(!component) return component;
		if(RIS.customComponent(component)) {
			return cloneElement(component, props);
		}
		if(!suppressWarning && AppUtils.isDev) {
			console.warn("Cannot propagate props to component:", component);
		}
		let {className, style} = props;
		return cloneElement(component, {className, style});
	}

	/**
	 * Wrap with component
	 * ---
	 * @param {Component} body
	 * @param {Component} wrapper
	 * @param {Object} props
	 * @return {Comment}
	 */
	static wrapWithComponent(body, wrapper, props = {}) {
		if(wrapper) {
			return cloneElement(wrapper, {
				...props,
				className: combineClasses(props.className, wrapper.props.className),
				style: {
					...wrapper.props.style,
					...props.style
				},
			}, body);
		}
		return body;
	}
}
