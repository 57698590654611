import { Component, createContext } from "preact";
import PropTypes from "prop-types";
import { ArrayUtils, E_Modification, EO } from "@green24/npm-javascript-utils";
import { ValueUpdateListeners } from "@green24/npm-preact-utils";
import { ProductCategoriesUtils, ProductFeaturesUtils } from "../../utils/ProductUtils";

export const FeatureCustomizerContext = createContext();

class ProductFeatureCustomizer extends Component {
	constructor(props) {
		super();

		const states = this._getCategoriesStates(props.categories);
		this.state = {
			...states,
			categories: ProductCategoriesUtils.processCategories(props.categories, states.enabledFeatures),
		};

		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.add("props.categories", (categories) => {
			const {enabledFeatures} = this.state;

			this.setState({
				categories: ProductCategoriesUtils.processCategories(categories, enabledFeatures),
				...this._getCategoriesStates(categories),
			});
		});

		this._valueUpdateListeners.add("state.featuresStates", (featuresStates, oldFeaturesStates = []) => {
			const {categories} = this.props;

			const oldEnabledFeatures = ProductCategoriesUtils.getEnabledFeatures(categories, oldFeaturesStates);
			const enabledFeatures = ProductCategoriesUtils.getEnabledFeatures(categories, featuresStates);

			const oldOptions = ProductCategoriesUtils.getAllFeatures(ProductCategoriesUtils.processCategories(categories, oldEnabledFeatures));
			const newOptions = ProductCategoriesUtils.getAllFeatures(ProductCategoriesUtils.processCategories(categories, enabledFeatures));
			const {added} = ArrayUtils.diff(newOptions, oldOptions, ({item1, item2}) => item1.id == item2.id);

			if(added.length) {
				const defaultEnabledFeatures = added.filter(feature => feature.defaultState);
				if(defaultEnabledFeatures.length) {
					const featuresToEnable = defaultEnabledFeatures.filter(feature => {
						const group = ProductCategoriesUtils.findGroupFromOption(categories, feature);

						switch (group.selectionType) {
							case "RADIO":
							case "DROPDOWN":
								return featuresStates[group.id] ? !featuresStates[group.id].length : true;
							default:
								return true;
						}
					});

					if(featuresToEnable.length) {
						const newFeaturesStates = {...featuresStates};
						featuresToEnable.forEach(feature => {
							const group = ProductCategoriesUtils.findGroupFromOption(categories, feature);

							newFeaturesStates[group.id] = [feature.id];
						});

						this.setState({
							featuresStates: newFeaturesStates,
						});

						return;
					}
				}
			}

			const invalidOptions = ProductCategoriesUtils.findAllOptionsWithFailedRequirements(categories, enabledFeatures);
			const invalidIDs = invalidOptions.map(option => option.id);

			const hasInvalidOption = enabledFeatures.some(feature => invalidIDs.includes(feature.id));
			if(hasInvalidOption) {
				this.setState({
					featuresStates: ProductFeaturesUtils.removeInvalidFeatureStates(featuresStates, invalidIDs),
				});
			}
			else {
				this.setState({
					enabledFeatures,
				});
			}
		});

		this._valueUpdateListeners.add("state.enabledFeatures", enabledFeatures => {
			const {categories} = this.props;

			this.setState({
				categories: ProductCategoriesUtils.processCategories(categories, enabledFeatures),
			});
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	componentWillUnmount() {
		this._valueUpdateListeners.clear();
	}

	render({children, categories: propsCategories}, {featuresStates, defaultStates, enabledFeatures, categories}) {
		const customizerData = {
			categories,
			defaultStates,
			featuresStates,
			enabledFeatures,
			features: ProductCategoriesUtils.getAllFeatures(propsCategories),
			onModifyFeatureState: (groupID, optionID, modificationType) => this._modifyFeatureState(groupID, optionID, modificationType),
		};

		return (
			<FeatureCustomizerContext.Provider value={customizerData}>
				{children}
			</FeatureCustomizerContext.Provider>
		);
	}

	_modifyFeatureState(groupID, optionID, modificationType = E_Modification.ITEM_SET) {
		this.setState(state => {
			let newData = EO(state.featuresStates).clone();

			switch (modificationType) {
				case E_Modification.ARRAY_SPLICE:
					let itemIndex = state.featuresStates[groupID].indexOf(optionID);

					if(itemIndex > -1) {
						newData.modify(modificationType, [groupID], [itemIndex, 1]);
					}
					break;
				case E_Modification.ITEM_SET:
					newData.modify(modificationType, [groupID], [optionID]);
					break;
				case E_Modification.ARRAY_PUSH:
					newData.modify(modificationType, [groupID], optionID);
					break;
			}

			return {
				featuresStates: newData
			}
		});
	}

	_getCategoriesStates(categories) {
		const featuresStates = ProductCategoriesUtils.getFeaturesStates(categories);

		return {
			featuresStates,
			defaultStates: featuresStates,
			enabledFeatures: ProductCategoriesUtils.getEnabledFeatures(categories, featuresStates),
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			categories: PropTypes.array.isRequired,
		}
	}

	static get stateTypes() {
		return {
			featuresStates: PropTypes.object,
			defaultStates: PropTypes.object,
			enabledFeatures: PropTypes.array,
		}
	}
}

export default ProductFeatureCustomizer;
