import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses, resolvePolymorphVar } from "@green24/npm-javascript-utils";
import { ComponentUtils } from "@green24/npm-preact-utils";
import { Interpret } from "../../components";
import Fa from "../Fa";
import ButtonsConstructor from "../input/Button/ButtonsConstructor";
import withModalContext from "@green24/npm-preact-utils/src/components/Modal/hoc/withModalContext";

class MessageModal extends Component {
	_renderMessage() {
		const {title, titleTranslated, titleTranslationParams, message, messageTranslated, messageTranslationParams, actionButtons, modalContext, onClose, children} = this.props;
		const defaultActionButtons = {
			close: {
				className: "close",
				accent: "primary-insignificant",
				text: "close",
				action: () => {
					modalContext.close();
					onClose();
				},
			},
		};
		const buttonDefinitionsToArray = () => Object.values(defaultActionButtons);

		return (
			<div className={"message-content"}>
				<h1 className={"title"}>
					{ComponentUtils.resolvePolymorphProp(
						title,
						{
							component: c => c,
							string: s => titleTranslated ? s : (
								<Interpret id={s} params={titleTranslationParams}/>
							),
						},
						() => title,
					)}
				</h1>

				<p className={"message"}>
					{ComponentUtils.resolvePolymorphProp(
						message,
						{
							component: c => c,
							string: s => messageTranslated ? s : (
								<Interpret id={s} params={messageTranslationParams}/>
							),
						},
						() => message,
					)}
				</p>

				{children}

				<nav className={"action-buttons"}>
					<ButtonsConstructor buttons={
						resolvePolymorphVar(
							actionButtons(modalContext),
							{
								function: f => f(defaultActionButtons),
								array: arr => [...buttonDefinitionsToArray(), ...arr],
								boolean: b => b ? buttonDefinitionsToArray() : [],
							},
							() => buttonDefinitionsToArray(),
							true
						)
					}/>
				</nav>
			</div>
		)
	}

	_renderSideMessage() {
		const {sideMessage} = this.props;
		const {icon, title, titleTranslated, titleTranslationParams, text, textTranslated, textTranslationParams} = sideMessage;

		return (
			<aside className={"side-message-content"}>
				{
					icon &&
					<div className={"icon-wrapper"}>
						<Fa icon={icon}/>
					</div>
				}

				{
					title &&
					<h2 className={"title"}>
						{ComponentUtils.resolvePolymorphProp(
							title,
							{
								component: c => c,
								string: s => titleTranslated ? s : (
									<Interpret id={s} params={titleTranslationParams}/>
								),
							},
							() => title,
						)}
					</h2>
				}

				{
					text &&
					<p className={"text"}>
						{ComponentUtils.resolvePolymorphProp(
							text,
							{
								component: c => c,
								string: s => textTranslated ? s : (
									<Interpret id={s} params={textTranslationParams}/>
								),
							},
							() => text,
						)}
					</p>
				}
			</aside>
		)
	}

	render({className, style, sideMessage}, {}) {
		return (
			<section className={combineClasses("message-modal", className)} style={style}>
				{this._renderMessage()}

				{sideMessage && this._renderSideMessage()}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			title: PropTypes.any,
			titleTranslated: PropTypes.bool,
			titleTranslationParams: PropTypes.any,

			message: PropTypes.any,
			messageTranslated: PropTypes.bool,
			messageTranslationParams: PropTypes.any,

			sideMessage: PropTypes.shape({
				icon: PropTypes.string,

				title: PropTypes.any,
				titleTranslated: PropTypes.bool,
				titleTranslationParams: PropTypes.object,

				text: PropTypes.any,
				textTranslated: PropTypes.bool,
				textTranslationParams: PropTypes.object,
			}),

			actionButtons: PropTypes.func,
			onClose: PropTypes.func,

			//Passed from Modal component
			modalContext: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			actionButtons: () => undefined,
			onClose: () => null,
		}
	}
}

export default withModalContext(MessageModal);
