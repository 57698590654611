import { IS, get } from "@green24/js-utils";

export class InputUtils {
	static resolveValue(path, value, root) {
		if(IS.valid(value)) {
			return value;
		}

		return get(root, path);
	}

	/**
	 * Adjust step with modifiers
	 * ---
	 * Returns a step value after applying shift, alt and ctrl modifiers
	 * @param {number} step
	 * @param {boolean} shiftKey Toggles 100x
	 * @param {boolean} ctrlKey Toggles 10x
	 * @param {boolean} altKey Toggles between significant/fractions
	 * @param {boolean} allowFractions
	 * @return {number} Modified step
	 */
	static adjustStepWithModifiers(step, {shiftKey, ctrlKey, altKey}, allowFractions = true) {
		if(allowFractions && altKey) {
			step *= 0.1;

			if(ctrlKey) {
				step *= 0.1;
			}

			if(shiftKey) {
				step *= 0.01;
			}
		}
		else {
			if(ctrlKey) {
				step *= 10;
			}

			if(shiftKey) {
				step *= 100;
			}
		}

		return step;
	}

	static resolveFieldID(fieldPrefix = '', idOverride = undefined) {
		if(idOverride) return idOverride;

		const id = Math.random() * Date.now();
		return `${fieldPrefix}${id}`
	}
}
