import { Component, Fragment } from "preact";
import { createPortal } from "preact/compat";
import PropTypes from "prop-types"
import tippy from "tippy.js";
import { ComponentUtils, ValueUpdateListeners } from "@green24/npm-preact-utils";
import { combineClasses } from "@green24/npm-javascript-utils";

class Tippy extends Component {
	constructor(props) {
		super(props);

		this._tooltipDOM = null;
		this._tooltipContentDOM = document.createElement("div");
		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.add("props.options", () => {
			this.tippy && this.tippy.setProps(this._getTippyProps());
		});

		this._valueUpdateListeners.add("props.content", () => {
			this.tippy && this.tippy.state.isShown && this.tippy.show();
		});

		this._initTippy();
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	componentWillUnmount() {
		this._valueUpdateListeners.clear();
		this._destroyTippy();
	}

	render({className, style, children, content, wrapper}) {
		return (
			<Fragment>
				{
					ComponentUtils.wrapWithComponent(children, wrapper, {
						className: combineClasses("tippy-trigger", className),
						style,
						ref: ref => this._tooltipDOM = ref,
					})
				}

				{createPortal(content, this._tooltipContentDOM)}
			</Fragment>
		);
	}

	_getTippyProps() {
		const {options} = this.props;

		return {
			ignoreAttributes: true,
			arrow: true,
			...options,
			content: this._tooltipContentDOM,
		}
	}

	_initTippy() {
		this.tippy = tippy(this._tooltipDOM, this._getTippyProps());
	}

	_destroyTippy() {
		if(this.tippy) {
			this._tooltipDOM._tippy.destroy();
			this._tooltipDOM = null;
			this.tippy = null;
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			content: PropTypes.any,
			options: PropTypes.object,
			wrapper: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			wrapper: <div/>,
		}
	}
}

export default Tippy;
