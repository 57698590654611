import { IS } from "../IS";

export class GoogleCoords {
	constructor(...props) {
		if(IS.empty(props)) {return this;}
		let {lat, lng, latitude, longitude} = props[0];
		if(IS.array(props[0]) || props.length > 1) {
			[lat, lng] = props.length > 1 ? props : props[0];
		}
		else if(IS.string(props[0])) {
			[lat, lng] = props[0].split(",");
			lat = parseFloat(lat);
			lng = parseFloat(lng);
		}

		this.lat = (IS.fnc(lat) ? lat() : lat) || latitude || 0;
		this.lng = (IS.fnc(lng) ? lng() : lng) || longitude || 0;
	}

	isEqual(coords = new GoogleCoords(0, 0)) {
		return this.lat == coords.lat && this.lng == coords.lng;
	}

	toLatLng() {
		if(IS.nestedProperty(window, "google.maps.LatLng")) {
			return new window.google.maps.LatLng(this.lat, this.lng);
		}
		return this;
	}

	isWithinRadius(coords, radius) {
		const dist = Math.sqrt( Math.pow((this.lat-coords.lat), 2) + Math.pow((this.lng-coords.lng), 2) );
		return dist <= radius;
	}
}
