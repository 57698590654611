import { setLocalizationContext } from "./components/L10N/LocalizationProvider";
import { setIconsContext } from "./components/Icons/IconsProvider";
import { setModalContext } from "./components/Modal/Modal";
import { setCurrencyContext } from "./components/Price/CurrencyProvider";
import { setThemeComponentsContext } from "./components/theme/ThemeComponentsProvider";
import { setFormContext } from "./components/Form/hoc/withFormContext";
import { setStoreContext } from "./components/store/StoreProvider";

export {
	setLocalizationContext,
	setIconsContext,
	setModalContext,
	setCurrencyContext,
	setThemeComponentsContext,
	setStoreContext,
	setFormContext
};

/**
 * Reinitialize contexts
 * ---
 * Sets a new context objects for all possible contexts from the Preact-utils
 * @param {function(): React.Context} contextGetterFunction
 */
export const reinitializeContexts = (contextGetterFunction) => {
	setLocalizationContext(contextGetterFunction());
	setIconsContext(contextGetterFunction());
	setModalContext(contextGetterFunction());
	setCurrencyContext(contextGetterFunction());
	setThemeComponentsContext(contextGetterFunction());
	setStoreContext(contextGetterFunction());
	setFormContext(contextGetterFunction());
};
