import { Component, createContext } from 'preact';
import PropTypes from "prop-types";
import { Internationalizer, RegionalLanguage } from "@green24/js-utils";
import { AppUtils } from "../../class/AppUtils";

export let LocalizationContext = createContext();
export const setLocalizationContext = context => LocalizationContext = context;

class LocalizationProvider extends Component {
	constructor(props) {
		super(props);

		const defaultInternationalizer = new Internationalizer(props.initialLanguage, props.fallbackLanguage, props.internationalizerCustomization);

		const internationalizer = this._internationalizer = props.onInternationalizerConstruction(defaultInternationalizer);
		internationalizer.addLocales(props.languages);

		if(props.sharedDictionary) {
			internationalizer.addSharedLocale(props.sharedDictionary);
		}

		this.state = {
			activeLanguage: internationalizer.activeLanguage,
		};

		internationalizer.onActiveLanguageChange(activeLanguage => {
			this.setState({activeLanguage});

			this.props.onActiveLanguageChange(activeLanguage, this._internationalizer);
		});

		this.props.onActiveLanguageChange(this._internationalizer.activeLanguage, this._internationalizer);
	}

	render({children, notifyDocument}, {activeLanguage}) {
		const contextData = {
			findTerm: this._internationalizer.findTerm.bind(this._internationalizer),
			internationalizer: this._internationalizer,
			activeLanguage,
			setActiveLanguage: regionalCode => this._setActiveLanguage(regionalCode),
			getActiveLanguageISOCode: () => activeLanguage.ISO639_1,
		};

		if(notifyDocument && document) {
			document.documentElement.lang = activeLanguage.ISOCodeWithRegion;
		}

		return (
			<LocalizationContext.Provider value={contextData}>
				{children}
			</LocalizationContext.Provider>
		);
	}

	_setActiveLanguage(regionalCode) {
		const {notifyLocalStorage, localStorageKey} = this.props;

		this._internationalizer.setActiveLanguage(regionalCode);

		if(notifyLocalStorage && AppUtils.hasWindow && localStorage) {
			if(RegionalLanguage.hasSameRootLanguage(regionalCode, window.navigator.language)) {
				localStorage.removeItem(localStorageKey);
			}
			else {
				localStorage.setItem(localStorageKey, regionalCode);
			}
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			languages: PropTypes.object.isRequired,

			initialLanguage: PropTypes.string.isRequired,
			fallbackLanguage: PropTypes.string,
			internationalizerCustomization: PropTypes.object,

			sharedDictionary: PropTypes.object,

			notifyDocument: PropTypes.bool,
			notifyLocalStorage: PropTypes.bool,
			localStorageKey: PropTypes.string,

			onInternationalizerConstruction: PropTypes.func,
			onActiveLanguageChange: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			activeLanguage: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			onInternationalizerConstruction: (internationalizer) => internationalizer,
			onActiveLanguageChange: () => null,
			notifyDocument: true,
			notifyLocalStorage: true,
			localStorageKey: "lang",
		}
	}
}

export default LocalizationProvider;
