/**
 * Language enum
 * ---
 * @enum string
 * @readonly
 */
export const E_Language = {
	ABKHAZIAN: "ABKHAZIAN",
	AFAR: "AFAR",
	AFRIKAANS: "AFRIKAANS",
	AKAN: "AKAN",
	ALBANIAN: "ALBANIAN",
	AMHARIC: "AMHARIC",
	ARABIC: "ARABIC",
	ARAGONESE: "ARAGONESE",
	ARMENIAN: "ARMENIAN",
	ASSAMESE: "ASSAMESE",
	AVARIC: "AVARIC",
	AVESTAN: "AVESTAN",
	AYMARA: "AYMARA",
	AZERBAIJANI: "AZERBAIJANI",
	BAMBARA: "BAMBARA",
	BASHKIR: "BASHKIR",
	BASQUE: "BASQUE",
	BELARUSIAN: "BELARUSIAN",
	BENGALI: "BENGALI",
	BIHARI_LANGUAGES: "BIHARI_LANGUAGES",
	BISLAMA: "BISLAMA",
	BOSNIAN: "BOSNIAN",
	BRETON: "BRETON",
	BULGARIAN: "BULGARIAN",
	BURMESE: "BURMESE",
	CATALAN: "CATALAN",
	VALENCIAN: "CATALAN",
	CHAMORRO: "CHAMORRO",
	CHECHEN: "CHECHEN",
	CHICHEWA: "CHICHEWA",
	CHEWA: "CHICHEWA",
	NYANJA: "CHICHEWA",
	CHINESE: "CHINESE",
	CHUVASH: "CHUVASH",
	CORNISH: "CORNISH",
	CORSICAN: "CORSICAN",
	CREE: "CREE",
	CROATIAN: "CROATIAN",
	CZECH: "CZECH",
	DANISH: "DANISH",
	DIVEHI: "DIVEHI",
	DHIVEHI: "DIVEHI",
	MALDIVIAN: "DIVEHI",
	DUTCH: "DUTCH",
	FLEMISH: "DUTCH",
	DZONGKHA: "DZONGKHA",
	ENGLISH: "ENGLISH",
	ESPERANTO: "ESPERANTO",
	ESTONIAN: "ESTONIAN",
	EWE: "EWE",
	FAROESE: "FAROESE",
	FIJIAN: "FIJIAN",
	FINNISH: "FINNISH",
	FRENCH: "FRENCH",
	FULAH: "FULAH",
	GALICIAN: "GALICIAN",
	GEORGIAN: "GEORGIAN",
	GERMAN: "GERMAN",
	GREEK: "GREEK",
	GUARANI: "GUARANI",
	GUJARATI: "GUJARATI",
	HAITIAN: "HAITIAN",
	HAITIAN_CREOLE: "HAITIAN",
	HAUSA: "HAUSA",
	HEBREW: "HEBREW",
	HERERO: "HERERO",
	HINDI: "HINDI",
	HIRI_MOTU: "HIRI_MOTU",
	HUNGARIAN: "HUNGARIAN",
	INTERLINGUA: "INTERLINGUA",
	INDONESIAN: "INDONESIAN",
	INTERLINGUE: "INTERLINGUE",
	OCCIDENTAL: "INTERLINGUE",
	IRISH: "IRISH",
	IGBO: "IGBO",
	INUPIAQ: "INUPIAQ",
	IDO: "IDO",
	ICELANDIC: "ICELANDIC",
	ITALIAN: "ITALIAN",
	INUKTITUT: "INUKTITUT",
	JAPANESE: "JAPANESE",
	JAVANESE: "JAVANESE",
	KALAALLISUT: "KALAALLISUT",
	GREENLANDIC: "KALAALLISUT",
	KANNADA: "KANNADA",
	KANURI: "KANURI",
	KASHMIRI: "KASHMIRI",
	KAZAKH: "KAZAKH",
	CENTRAL_KHMER: "CENTRAL_KHMER",
	KIKUYU: "KIKUYU",
	GIKUYU: "KIKUYU",
	KINYARWANDA: "KINYARWANDA",
	KIRGHIZ: "KIRGHIZ",
	KYRGYZ: "KIRGHIZ",
	KOMI: "KOMI",
	KONGO: "KONGO",
	KOREAN: "KOREAN",
	KURDISH: "KURDISH",
	KUANYAMA: "KUANYAMA",
	KWANYAMA: "KUANYAMA",
	LATIN: "LATIN",
	LUXEMBOURGISH: "LUXEMBOURGISH",
	LETZEBURGESCH: "LUXEMBOURGISH",
	GANDA: "GANDA",
	LIMBURGAN: "LIMBURGAN",
	LIMBURGER: "LIMBURGAN",
	LIMBURGISH: "LIMBURGAN",
	LINGALA: "LINGALA",
	LAO: "LAO",
	LITHUANIAN: "LITHUANIAN",
	LUBA_KATANGA: "LUBA_KATANGA",
	LATVIAN: "LATVIAN",
	MANX: "MANX",
	MACEDONIAN: "MACEDONIAN",
	MALAGASY: "MALAGASY",
	MALAY: "MALAY",
	MALAYALAM: "MALAYALAM",
	MALTESE: "MALTESE",
	MAORI: "MAORI",
	MARATHI: "MARATHI",
	MARSHALLESE: "MARSHALLESE",
	MONGOLIAN: "MONGOLIAN",
	NAURU: "NAURU",
	NAVAJO: "NAVAJO",
	NAVAHO: "NAVAJO",
	NORTH_NDEBELE: "NORTH_NDEBELE",
	NEPALI: "NEPALI",
	NDONGA: "NDONGA",
	NORWEGIAN_BOKMAL: "NORWEGIAN_BOKMÅL",
	NORWEGIAN_NYNORSK: "NORWEGIAN_NYNORSK",
	NORWEGIAN: "NORWEGIAN",
	SICHUAN_YI: "SICHUAN_YI",
	SOUTH_NDEBELE: "SOUTH_NDEBELE",
	OCCITAN: "OCCITAN",
	OJIBWA: "OJIBWA",
	OROMO: "OROMO",
	ORIYA: "ORIYA",
	OSSETIAN: "OSSETIC",
	OSSETIC: "OSSETIC",
	PUNJABI: "PUNJABI",
	PANJABI: "PUNJABI",
	PALI: "PALI",
	PERSIAN: "PERSIAN",
	POLISH: "POLISH",
	PASHTO: "PASHTO",
	PUSHTO: "PASHTO",
	PORTUGUESE: "PORTUGUESE",
	QUECHUA: "QUECHUA",
	ROMANSH: "ROMANSH",
	RUNDI: "RUNDI",
	ROMANIAN: "ROMANIAN",
	MOLDAVIAN: "ROMANIAN",
	MOLDOVAN: "ROMANIAN",
	RUSSIAN: "RUSSIAN",
	SANSKRIT: "SANSKRIT",
	SARDINIAN: "SARDINIAN",
	SINDHI: "SINDHI",
	NORTHERN_SAMI: "NORTHERN_SAMI",
	SAMOAN: "SAMOAN",
	SANGO: "SANGO",
	SERBIAN: "SERBIAN",
	GAELIC: "GAELIC",
	SCOTTISH_GAELIC: "SCOTTISH_GAELIC",
	SHONA: "SHONA",
	SINHALA: "SINHALA",
	SINHALESE: "SINHALA",
	SLOVAK: "SLOVAK",
	SLOVENIAN: "SLOVENIAN",
	SOMALI: "SOMALI",
	SOUTHERN_SOTHO: "SOUTHERN_SOTHO",
	SPANISH: "SPANISH",
	CASTILIAN: "SPANISH",
	SUNDANESE: "SUNDANESE",
	SWAHILI: "SWAHILI",
	SWATI: "SWATI",
	SWEDISH: "SWEDISH",
	TAMIL: "TAMIL",
	TELUGU: "TELUGU",
	TAJIK: "TAJIK",
	THAI: "THAI",
	TIGRINYA: "TIGRINYA",
	TIBETAN: "TIBETAN",
	TURKMEN: "TURKMEN",
	TAGALOG: "TAGALOG",
	TSWANA: "TSWANA",
	TONGA: "TONGA",
	TURKISH: "TURKISH",
	TSONGA: "TSONGA",
	TATAR: "TATAR",
	TWI: "TWI",
	TAHITIAN: "TAHITIAN",
	UIGHUR: "UIGHUR",
	UYGHUR: "UIGHUR",
	UKRAINIAN: "UKRAINIAN",
	URDU: "URDU",
	UZBEK: "UZBEK",
	VENDA: "VENDA",
	VIETNAMESE: "VIETNAMESE",
	VOLAPUK: "VOLAPÜK",
	WALLOON: "WALLOON",
	WELSH: "WELSH",
	WOLOF: "WOLOF",
	WESTERN_FRISIAN: "WESTERN_FRISIAN",
	XHOSA: "XHOSA",
	YIDDISH: "YIDDISH",
	YORUBA: "YORUBA",
	ZHUANG: "ZHUANG",
	CHUANG: "ZHUANG",
	ZULU: "ZULU",
};

Object.defineProperty(E_Language, "__SHARED__", {value: "__SHARED__"});

/**
 * Language with region enum
 * ---
 * @enum string
 * @readonly
 */
export const E_LanguageWithRegion = {
	...E_Language,
	ARABIC_ALGERIA: "ARABIC_ALGERIA",
	ARABIC_BAHRAIN: "ARABIC_BAHRAIN",
	ARABIC_EGYPT: "ARABIC_EGYPT",
	ARABIC_IRAQ: "ARABIC_IRAQ",
	ARABIC_JORDAN: "ARABIC_JORDAN",
	ARABIC_KUWAIT: "ARABIC_KUWAIT",
	ARABIC_LEBANON: "ARABIC_LEBANON",
	ARABIC_LIBYA: "ARABIC_LIBYA",
	ARABIC_MOROCCO: "ARABIC_MOROCCO",
	ARABIC_OMAN: "ARABIC_OMAN",
	ARABIC_QATAR: "ARABIC_QATAR",
	ARABIC_SAUDI_ARABIA: "ARABIC_SAUDI_ARABIA",
	ARABIC_SYRIA: "ARABIC_SYRIA",
	ARABIC_TUNISIA: "ARABIC_TUNISIA",
	ARABIC_U_A_E: "ARABIC_U_A_E",
	ARABIC_YEMEN: "ARABIC_YEMEN",
	CHINESE_HONG_KONG: "CHINESE_HONG_KONG",
	CHINESE_PRC: "CHINESE_PRC",
	CHINESE_SINGAPORE: "CHINESE_SINGAPORE",
	CHINESE_TAIWAN: "CHINESE_TAIWAN",
	DUTCH_BELGIUM: "DUTCH_BELGIUM",
	DUTCH_STANDARD: "DUTCH_STANDARD",
	ENGLISH_AUSTRALIA: "ENGLISH_AUSTRALIA",
	ENGLISH_BELIZE: "ENGLISH_BELIZE",
	ENGLISH_CANADA: "ENGLISH_CANADA",
	ENGLISH_IRELAND: "ENGLISH_IRELAND",
	ENGLISH_JAMAICA: "ENGLISH_JAMAICA",
	ENGLISH_NEW_ZEALAND: "ENGLISH_NEW_ZEALAND",
	ENGLISH_SOUTH_AFRICA: "ENGLISH_SOUTH_AFRICA",
	ENGLISH_TRINIDAD: "ENGLISH_TRINIDAD",
	ENGLISH_UNITED_KINGDOM: "ENGLISH_UNITED_KINGDOM",
	ENGLISH_UNITED_STATES: "ENGLISH_UNITED_STATES",
	FRENCH_BELGIUM: "FRENCH_BELGIUM",
	FRENCH_CANADA: "FRENCH_CANADA",
	FRENCH_LUXEMBOURG: "FRENCH_LUXEMBOURG",
	FRENCH_STANDARD: "FRENCH_STANDARD",
	FRENCH_SWITZERLAND: "FRENCH_SWITZERLAND",
	GAELIC_SCOTLAND: "GAELIC_SCOTLAND",
	GERMAN_AUSTRIA: "GERMAN_AUSTRIA",
	GERMAN_LIECHTENSTEIN: "GERMAN_LIECHTENSTEIN",
	GERMAN_LUXEMBOURG: "GERMAN_LUXEMBOURG",
	GERMAN_STANDARD: "GERMAN_STANDARD",
	GERMAN_SWITZERLAND: "GERMAN_SWITZERLAND",
	ITALIAN_SWITZERLAND: "ITALIAN_SWITZERLAND",
	PORTUGUESE_BRAZIL: "PORTUGUESE_BRAZIL",
	ROMANIAN_REPUBLIC_OF_MOLDOVA: "ROMANIAN_REPUBLIC_OF_MOLDOVA",
	RUSSIAN_REPUBLIC_OF_MOLDOVA: "RUSSIAN_REPUBLIC_OF_MOLDOVA",
	SPANISH_ARGENTINA: "SPANISH_ARGENTINA",
	SPANISH_BOLIVIA: "SPANISH_BOLIVIA",
	SPANISH_CHILE: "SPANISH_CHILE",
	SPANISH_COLOMBIA: "SPANISH_COLOMBIA",
	SPANISH_COSTA_RICA: "SPANISH_COSTA_RICA",
	SPANISH_DOMINICAN_REPUBLIC: "SPANISH_DOMINICAN_REPUBLIC",
	SPANISH_ECUADOR: "SPANISH_ECUADOR",
	SPANISH_EL_SALVADOR: "SPANISH_EL_SALVADOR",
	SPANISH_GUATEMALA: "SPANISH_GUATEMALA",
	SPANISH_HONDURAS: "SPANISH_HONDURAS",
	SPANISH_MEXICO: "SPANISH_MEXICO",
	SPANISH_NICARAGUA: "SPANISH_NICARAGUA",
	SPANISH_PANAMA: "SPANISH_PANAMA",
	SPANISH_PARAGUAY: "SPANISH_PARAGUAY",
	SPANISH_PERU: "SPANISH_PERU",
	SPANISH_PUERTO_RICO: "SPANISH_PUERTO_RICO",
	SPANISH_SPAIN: "SPANISH_SPAIN",
	SPANISH_URUGUAY: "SPANISH_URUGUAY",
	SPANISH_VENEZUELA: "SPANISH_VENEZUELA",
	SWEDISH_FINLAND: "SWEDISH_FINLAND",
};

/**
 * L10N Part type enum
 * ---
 * @enum string
 * @readonly
 */
export const E_L10N_PartType = {
	TEXT: "TEXT",
	VARIABLE: "VARIABLE",
	TRANSLATE: "TRANSLATE",
};
