import { Component as PComponent } from "preact";
import { SiteSettingsContext } from "../SiteSettingsContextProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withSiteSettingsContext = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<SiteSettingsContext.Consumer>
					{context => (
						<Component
							siteSettingsContext={context}
							{...props}
						/>
					)}
				</SiteSettingsContext.Consumer>
			);
		}

		static get displayName() {
			return "withSiteSettingsContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {}
		}

		static get defaultProps() {
			return {}
		}
	}

	return HOC;
};

export default withSiteSettingsContext;
