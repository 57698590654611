import { Component as PComponent, createContext } from "preact";
import PropTypes from "prop-types";

export let FormContext = createContext();
export const setFormContext = context => FormContext = context;

/**
 * A public higher-order component to access the imperative API
 */
export const withFormContext = Component => {
	class HOC extends PComponent {
		render(props) {
			return (
				<FormContext.Consumer>
					{formContext => {
						if(formContext) {
							const {onModify, root, path} = props;

							return (
								<Component
									formContext={formContext}
									{...props}

									onModify={onModify || formContext.onModify}
									root={root || formContext.root}
									path={path || formContext.path}
								/>
							);
						}

						return <Component {...props}/>
					}}
				</FormContext.Consumer>
			);
		}

		static get displayName() {
			return "withFormContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				path: PropTypes.string,
				root: PropTypes.object,

				onModify: PropTypes.func,
			}
		}

		static get defaultProps() {
			return {

			}
		}
	}

	return HOC;
};

export default withFormContext;
