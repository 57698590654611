export class ResponseHandlers {
	static json(response, processor = data => data) {
		return new Promise((resolve, reject) => {
			response.json().then((data) => {
				resolve(processor(data));
			}, (parseError) => {
				reject(parseError);
			});
		});
	}

	static plain(response) {
		return Promise.resolve(response);
	}

	static blob(response) {
		return Promise.resolve(response.blob());
	}

	static blobWithFilename(response) {
		return new Promise(((resolve, reject) => {
			response.blob().then((data) => {
				resolve({
					file: data,
					fileName: response.headers.get("content-disposition"),
				});
			}, (parseError) => {
				reject(parseError);
			})
		}));
	}

	static text(response) {
		return Promise.resolve(response.text());
	}

	static headers(response) {
		return Promise.resolve(response.headers);
	}
}
