import { resolvePolymorphVar } from "../../functions/generic";
import { ArrayUtils } from "../ArrayUtils";
import { ObjectUtils } from "../ObjectUtils";
import { RegexUtils } from "../RegexUtils";
import { Logger } from "../Logger";

const ANY_PARAM_REGEX = /:[\w$\-_.+!*'(),]+/;
const IS_PARAM_REGEX = /^:[\w$\-_.+!*'(),]+\??$/;
const PARAM_NAME_REGEX = /:([\w$\-_.+!*'(),]+)\??/;

const logger = new Logger("route", {
	...Logger.model.defaultScopeData,
	routeTest: false,
});

export class Route {
	#path;
	#response;
	#exit;

	constructor(path, {response = undefined, exit = false} = {}) {
		this.#path = path;
		this.#response = response;
		this.#exit = exit;
	}

	get path() {
		return this.#path;
	}

	get isExitRoute() {
		return this.#exit;
	}

	getResponse(data, next) {
		return resolvePolymorphVar(
			this.#response,
			{
				function: f => f(data, next),
				object: o => {
					if(o instanceof Promise) return o;

					return Promise.reject("Unknown format");
				},
				string: s => {
					if(ANY_PARAM_REGEX.test(s)) {
						s = s.replace(ANY_PARAM_REGEX, substring => {
							return data.params[substring.match(PARAM_NAME_REGEX)[1]];
						});
					}

					return s;
				},
			},
			() => Promise.reject("Unknown format"),
			true
		)
	}

	isSamePath(path) {
		if(!path) return false;
		if(path == this.#path) return true;
		if(this.#path == '*') return true;

		let [pathname, query, hash] = path.split(/[?#]/);

		let testPath = this.#path;
		if(testPath.includes('*')) {
			testPath = `(${testPath.split('*').map(p => RegexUtils.escapeString(p)).join(").*(")})`;
		}
		testPath = testPath.replace(/(\/?:[\w$\-_.+!*'(),]+\?)/g, `\/?[\\w$\\-_.+!*'(),]*`);
		testPath = testPath.replace(/(:[\w$\-_.+!*'(),]+)/g, `[\\w$\\-_.+!*'(),]+`);

		const pathnameTestRegex = new RegExp(`^${testPath}\/?$`, 'i');
		const pathnameTestResult = pathnameTestRegex.test(pathname);

		if(logger.isTypeAllowed("routeTest")) {
			console.groupCollapsed(pathnameTestResult ? '✅' : '❌', this.#path);
			console.table({
				result: pathnameTestResult,
				testPath: this.#path,
				pathname,
				pathnameTestRegex: pathnameTestRegex.source,
			});
			console.groupEnd();
		}

		if(pathnameTestResult) {
			if(this.#path.includes('#')) {
				let testHash = this.#path.split('#')[1];
				testHash = testHash.replace(/(\/?:[\w$\-_.+!*'(),]+\?)/g, `\/?[\\w$\\-_.+!*'(),]*`);
				testHash = testHash.replace(/(:[\w$\-_.+!*'(),]+)/g, `[\\w$\\-_.+!*'(),]+`);

				return testHash.test(hash);
			}

			return true;
		}

		return false;
	}

	extractParams(path) {
		//Test if there is at least one parameter
		if(!ANY_PARAM_REGEX.test(this.#path)) return {};

		const routePathParts = this.#path.split('/');
		const [currentPathname, currentQuery, currentHash] = path.split(/[?#]/);
		const currentPathParts = currentPathname.split('/');

		const params = ArrayUtils.mapValid(routePathParts, (part, i) => {
			if(IS_PARAM_REGEX.test(part)) {
				return {
					key: part.match(PARAM_NAME_REGEX)[1],
					value: currentPathParts[i],
				}
			}
		});

		return ObjectUtils.arrayToObject(params);
	}
}
