import { Component } from "preact";
import PropTypes from "prop-types";
import { M_Icons } from "../../models/Models_Icons";
import { Icon } from "../components";
import { combineClasses } from "@green24/npm-javascript-utils";

class Fa extends Component {
	render({className, ...props}) {
		return <Icon {...props} className={combineClasses("fa", className)}/>;
	}

	static get propTypes() {
		return {
			...Icon.propTypes,

			/**
			 * @type M_Icons
			 */
			icon: PropTypes.oneOf(Object.keys(M_Icons)),
		}
	}
}

export default Fa;
