import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses, E_Autocomplete } from "@green24/npm-javascript-utils";
import TextField from "../shared/input/TextField";
import InputRootContextProvider from "../shared/input/InputRootContextProvider";

class ContactForm extends Component {
	render({className, style, contact, onModify, required}, {}) {
		return (
			<section
				className={combineClasses("contact-form", className)}
				style={style}
			>
				<InputRootContextProvider root={contact}>
					<TextField
						name={E_Autocomplete.NAME}
						autocomplete={"name"}
						label={"contact.forenameAndSurname"}
						path={"name"}
						onModify={onModify}
						required={required}
					/>

					<TextField
						name={"address"}
						label={"contact.address"}
						path={"address"}
						onModify={onModify}
					/>

					<TextField
						name={"email"}
						autocomplete={E_Autocomplete.EMAIL}
						label={"contact.email"}
						path={"email"}
						onModify={onModify}
						required={required}
					/>

					<TextField
						name={"phone"}
						autocomplete={E_Autocomplete.TEL}
						label={"contact.phone"}
						path={"phone"}
						onModify={onModify}
					/>

					<TextField
						name={"companyName"}
						autocomplete={E_Autocomplete.ORGANIZATION}
						label={"contact.companyName"}
						path={"companyName"}
						onModify={onModify}
					/>

					<TextField
						name={"taxID"}
						label={"contact.taxID"}
						path={"taxID"}
						onModify={onModify}
					/>

					<TextField
						name={"registrationID"}
						label={"contact.registrationID"}
						path={"registrationID"}
						onModify={onModify}
					/>
				</InputRootContextProvider>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			required: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			contact: PropTypes.object,
		}
	}
}

export default ContactForm;
