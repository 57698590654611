import { Component } from "preact";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/npm-javascript-utils";
import { ThemeContext } from "./Theme/ThemeProvider";

class Spinner extends Component {
	render({className, style}, {}) {
		return (
			<section className={combineClasses("spinner", className)} style={style}>
				<ThemeContext.Consumer>
					{({getLogo}) => getLogo()}
				</ThemeContext.Consumer>

				<div className={"scale-out"}/>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default Spinner;
