import { Component as PComponent } from "preact";
import { InputRootContext } from "../InputRootContextProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withRootConsumer = Component => {
	class HOC extends PComponent {
		render({...props}) {
			return (
				<InputRootContext.Consumer>
					{(inputRootContext) => (
						<Component
							root={inputRootContext && inputRootContext.root}
							{...props}
						/>
					)}
				</InputRootContext.Consumer>
			);
		}

		static get displayName() {
			return "withRootConsumer(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withRootConsumer;
