import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { Calc, combineClasses, IS } from "@green24/npm-javascript-utils";

class Progress extends React.Component {
	render() {
		const {className, style, progress, accent, displayState} = this.props;
		const isDeterminate = IS.number(progress);

		return (
			<section className={
				combineClasses(
					"progress",
					accent,
					displayState && progress >= 0 && "with-state",
					className,
				)
			} style={style}>
				<div
					className={combineClasses(
						"track",
						isDeterminate ? "determinate" : "indeterminate",
					)}
					style={{width: isDeterminate ? progress + "%" : {}}}
				>
					{
						displayState &&
						isDeterminate &&
						progress > 0 &&
						<span className={"state"}>{progress}%</span>
					}
				</div>
				{
					displayState &&
					isDeterminate &&
					progress > 0 &&
					<span className={"state dummy"}>{Calc.round(progress)}%</span>
				}
			</section>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			accent: PropTypes.string,
			progress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
			displayState: PropTypes.bool,
			precision: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			precision: 0,
			displayState: false,
		}
	}
}

export default Progress;
